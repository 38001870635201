import { createActions, createReducer } from 'reduxsauce'

import { makeActionFree } from '../stores'

// Initial state
const INITIAL_STATE = {
  database: null,
  gabriel: 'Sucesso'
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  setDatabase: ['database']
})

makeActionFree('setDatabase')

/**
 * Reducers functions
 */
const setDatabase = (state = INITIAL_STATE, { database }) => ({
  ...state,
  database
})

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_DATABASE]: setDatabase
})

export {
  Types as DatabaseTypes,
  Creators as DatabaseActions
}
