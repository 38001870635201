import { appSchema } from '@nozbe/watermelondb'

import { contactSchema } from './contactSchema'
import { conversationFileSchema } from './conversationFileSchema'
import { conversationSchema } from './conversationSchema'
import { groupMemberSchema } from './groupMemberSchema'
import { groupSchema } from './groupSchema'
import { messageSchema } from './messageSchema'
import { profilePictureSchema } from './profilePictureSchema'
import { syncFileSchema } from './syncFileSchema'
import { syncHistoricSchema } from './syncHistoricSchema'
import { syncPendingSchema } from './syncPendingSchema'
import { userSchema } from './userSchema'

export const chatSchema = appSchema({
  version: 1,
  tables: [
    userSchema,
    messageSchema,
    contactSchema,
    conversationSchema,
    syncHistoricSchema,
    syncPendingSchema,
    syncFileSchema,
    profilePictureSchema,
    groupSchema,
    groupMemberSchema,
    conversationFileSchema
  ]
})
