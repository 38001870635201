/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useRef, useCallback , useEffect } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import toNumber from 'lodash/toNumber'
import zipObject from 'lodash/zipObject'

import I18n, { useT } from '@smartcoop/i18n'
import {
  getAnimals as getAnimalsService
} from '@smartcoop/services/apis/smartcoopApi/resources/animal'
import {
  getMachinery
} from '@smartcoop/services/apis/smartcoopApi/resources/machine'
import { getDepreciationTypes } from '@smartcoop/services/apis/smartcoopApi/resources/propertiesAssets'
import { PropertyActions } from '@smartcoop/stores/property'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import InputDate from '@smartcoop/web-components/InputDate'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSearch from '@smartcoop/web-components/InputSearch'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Modal from '@smartcoop/web-components/Modal'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Content, TabItem, TabsGroup, WarningDiv } from './styles'

const SearchAssetModal = (props) => {
  const { id, open, propertyId, handleClose, onClose } = props

  const [activeTab, setActiveTab] = useState(0)
  const [selectedRows, setSelectedRows] = useState({})
  const [searchText, setSearchText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [errorList, setErrorList] = useState({})
  const [depreciationGroups, setDepreciationGroups] = useState([])
  const [rows, setRows] = useState({})
  const tableRef = useRef()
  const dispatch = useCallback(useDispatch(), [])

  const t = useT()

  const closeModal = useCallback(
    () => {
      onClose()
      handleClose()
    },
    [handleClose, onClose]
  )

  const getDepreciationGroups = useCallback(async () => {
    const { data } = await getDepreciationTypes()
    setDepreciationGroups(map(data.data, item => ({ label: item?.name, value: item?.id, slug: item?.slug, usefulLife: item?.usefulLife })))
  }, [])

  useEffect(() => {
    getDepreciationGroups()
  }, [getDepreciationGroups])

  const handleErrors = useCallback((onSuccess) => {
    const foundErrors = {}
    const currentSelected = mapValues(selectedRows, (_, index) => rows[index])
    Object.values(currentSelected).forEach(row => {
      const errors = []
      if (isEmpty(row.assetPurchaseDate)) {
        errors.push('assetPurchaseDate')
      }
      if (isEmpty(row.startDepreciationDate)) {
        errors.push('startDepreciationDate')
      }
      if (isEmpty(row.depreciationGroup)) {
        errors.push('depreciationGroup')
      }
      if (isEmpty(row.assetEstimatedLife)) {
        errors.push('assetEstimatedLife')
      }
      if (isEmpty(row.assetValue)) {
        errors.push('assetValue')
      }
      foundErrors[row.id] = errors
    })

    if (find(foundErrors, item => !isEmpty(item))) {
      setErrorList(foundErrors)
    } else {
      onSuccess()
    }
  }, [rows, selectedRows])

  const updateRows = useCallback(debounce((newValue, rowId, name) => {
    setRows((old) => {
      const currentData = { ...old }
      const newRowData = { ...currentData[rowId], [name]: newValue }

      if (name === 'depreciationGroup') {
        const selectedOption = depreciationGroups.find(option => option.value === newValue)
        newRowData.assetEstimatedLife = selectedOption?.usefulLife
      }

      currentData[rowId] = newRowData
      return currentData
    })
  }, 500), [depreciationGroups])

  const animalColumns = useMemo(
    () => [
      {
        title: t('name/earring'),
        field: 'name',
        defaultSort: 'asc',
        align: 'left',
        render: (rowData) => `${ rowData?.name } - ${ rowData?.earring?.earringCode }`
      },
      {
        title: t('category'),
        field: 'category',
        align: 'left'
      },
      {
        title: t('asset purchase date'),
        field: 'acquisitionDate',
        render: (rowData) => (
          <InputDate
            fullWidth
            detached
            name="assetPurchaseDate"
            pickerProps={ { maxDate: moment().format() } }
            error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetPurchaseDate') }
            value={  rows?.[rowData.id]?.assetPurchaseDate }
            onChange={ value => updateRows(value, rowData.id, 'assetPurchaseDate') }
          />
        )
      },
      {
        title: t('start depreciation date'),
        field: 'startDepreciationDate',
        render: (rowData) => (
          <InputDate
            detached
            name="startDepreciationDate"
            error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('startDepreciationDate') }
            disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
            pickerProps={ { minDate: moment().startOf('month').format() } }
            value={ rows?.[rowData.id]?.startDepreciationDate }
            onChange={ value => updateRows(value, rowData.id, 'startDepreciationDate') }
          />
        )
      },
      {
        title: t('depreciation group'),
        field: 'depreciationGroup',
        render: (rowData) => (
          <InputSelect
            fullWidth
            detached
            name="depreciationGroup"
            options={ depreciationGroups }
            error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('depreciationGroup') }
            value={ rows?.[rowData.id]?.depreciationGroup }
            onChange={ (value) => updateRows(value, rowData.id, 'depreciationGroup') }
          />
        )
      },
      {
        title: t('asset estimated life'),
        field: 'assetEstimatedLife',
        render: (rowData) => (
          <InputNumber
            name="assetEstimatedLife"
            fullWidth
            detached
            notSeparate
            maxLength={ 4 }
            value={ rows?.[rowData.id]?.assetEstimatedLife }
            error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetEstimatedLife') }
            onChange={ ({ target: { value } }) => updateRows(value, rowData.id, 'assetEstimatedLife') }
          />
        )
      },
      {
        title: t('asset value'),
        field: 'assetValue',
        render: (rowData) => (
          <InputUnit
            detached
            type="float"
            unit="R$"
            fullWidth
            decimalScale={ 2 }
            error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetValue') }
            disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
            value={ rows?.[rowData.id]?.assetValue }
            onChange={ ({ target: { value } }) => updateRows(value, rowData.id, 'assetValue') }
          />
        )
      }
    ], [depreciationGroups, errorList, rows, t, updateRows]
  )

  const machineColumns = useMemo(() => [
    {
      title: t('type/model'),
      field: 'machineType.description',
      render: (rowData) => `${ rowData.machineType?.description } - ${ rowData.model }`
    },
    {
      title: t('brand/year'),
      field: 'machineBrand.description',
      render: (rowData) => `${ rowData.machineBrand?.description } - ${ rowData.year }`
    },
    {
      title: t('asset purchase date'),
      field: 'acquisitionDate',
      render: (rowData) => (
        <InputDate
          detached
          name="assetPurchaseDate"
          error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetPurchaseDate') }
          disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
          pickerProps={ { maxDate: moment().format() } }
          value={  rows?.[rowData.id]?.assetPurchaseDate }
          onChange={ (value) => updateRows(value, rowData.id, 'assetPurchaseDate') }
        />
      )
    },
    {
      title: t('start depreciation date'),
      field: 'startDepreciationDate',
      render: (rowData) => (
        <InputDate
          detached
          name="startDepreciationDate"
          error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('startDepreciationDate') }
          disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
          pickerProps={ { minDate: moment().startOf('month').format() } }
          value={ rows?.[rowData.id]?.startDepreciationDate }
          onChange={ (value) => updateRows(value, rowData.id, 'startDepreciationDate') }
        />
      )
    },
    {
      title: t('depreciation group'),
      field: 'depreciationGroup',
      render: (rowData) => (
        <InputSelect
          fullWidth
          detached
          name="depreciationGroup"
          options={ depreciationGroups }
          error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('depreciationGroup') }
          value={ rows?.[rowData.id]?.depreciationGroup }
          onChange={ (value) => updateRows(value, rowData.id, 'depreciationGroup') }
        />
      )
    },
    {
      title: t('asset estimated life'),
      field: 'assetEstimatedLife',
      render: (rowData) => (
        <InputNumber
          name="assetEstimatedLife"
          detached
          fullWidth
          notSeparate
          error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetEstimatedLife') }
          disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
          maxLength={ 4 }
          value={ rows?.[rowData.id]?.assetEstimatedLife }
          onChange={ ({ target: { value } }) => updateRows(value, rowData.id, 'assetEstimatedLife') }
        />
      )
    },
    {
      title: t('asset value'),
      field: 'assetValue',
      render: (rowData) => (
        <InputUnit
          detached
          type="float"
          unit="R$"
          fullWidth
          decimalScale={ 2 }
          error={ !isEmpty(errorList[rowData.id]) && errorList[rowData.id].includes('assetValue') }
          disabled={ !isEmpty(rowData?.propertiesAssets?.assetCode) }
          value={ rows?.[rowData.id]?.assetValue || null }
          onChange={ ({ target: { value } }) => updateRows(value, rowData.id, 'assetValue') }
        />
      )
    }
  ], [depreciationGroups, errorList, rows, t, updateRows])

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 1000), []
  )

  const onChangeSearchFilter = useCallback(
    (value) => {
      setSearchText(value)
      debouncedChangeSearchFilter(value)
    },
    [debouncedChangeSearchFilter]
  )

  const handleSubmitRegisterPropertyAsset = useCallback(
    () => {
      const params = Object.values(mapValues(selectedRows, (_, index) => rows[index])).map(data => {
        const monthlyDepreciation = (data.assetValue / data.assetEstimatedLife) / 12


        return activeTab === 0 ? {
          propertyId,
          assetValue: toNumber(data.assetValue),
          assetPurchaseDate: moment(data.assetPurchaseDate).format('YYYY-MM-DD'),
          startDepreciationDate: moment(data.startDepreciationDate).format('YYYY-MM-DD'),
          assetEstimatedLife: toNumber(data.assetEstimatedLife),
          assetTypeId: data?.depreciationGroup,
          assetMonthlyDepreciation: monthlyDepreciation,
          referenceId: data.id,
          assetYear: moment(data.assetPurchaseDate).format('YYYY'),
          assetBrand: data?.machineBrand?.description,
          assetDescription: `Maquina - ${ data?.machineType?.description }(${ data?.description })`,
          assessments: null
        } : {
          propertyId,
          assetValue: toNumber(data.assetValue),
          assetPurchaseDate: moment(data.assetPurchaseDate).format('YYYY-MM-DD'),
          startDepreciationDate: moment(data.startDepreciationDate).format('YYYY-MM-DD'),
          assetEstimatedLife: toNumber(data.assetEstimatedLife),
          assetTypeId: data?.depreciationGroup,
          assetMonthlyDepreciation: monthlyDepreciation,
          referenceId: data.id,
          assetYear: moment(data.assetPurchaseDate).format('YYYY'),
          assetBrand: data?.predominantBreed?.name,
          assetDescription: `Animal - Brinco(${ data?.animalListView?.earringCode })`,
          assessments: null
        }
      })
      handleErrors(() => dispatch(PropertyActions.postPropertyAssetsList(params, closeModal))
      )
    },
    [activeTab, closeModal, dispatch, handleErrors, propertyId, rows, selectedRows]
  )

  const submitModal = useCallback(
    () => {
      handleSubmitRegisterPropertyAsset()
    },
    [handleSubmitRegisterPropertyAsset]
  )


  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth='xl'
      fullWidth
      headerProps={ {
        disableTypography: true
      } }
      fullScreen
      title="Adicionar ao ativo imobilizado"
      contentContainerStyle={ { padding: 0, minWidth: 700 } }
    >
      <>
        <Content>
          <WarningDiv>
            {t('showing not registered only')}
          </WarningDiv>
          <TabsGroup
            indicatorColor="primary"
            textColor="primary"
            value={ activeTab }
            variant="fullWidth"
          >
            <TabItem
              label={ t('machinery') }
              onClick={ () => setActiveTab(0) }
            />
            <TabItem
              label={ t('animals') }
              onClick={ () => setActiveTab(1) }
            />
          </TabsGroup>
          <InputSearch
            style={ { marginBottom: 8, marginTop: 12, width: '25vw', alignSelf: 'flex-end' } }
            adornmentStyle={ { marginRight: 15 } }
            detached
            value={ searchText }
            onChange={ ({ target: { value } }) => onChangeSearchFilter(value) }
            placeholder={ t('search') }
          />
          <DataTable
            tableRef={ tableRef }
            columns={ activeTab === 0 ? machineColumns : animalColumns }
            data={ activeTab === 0 ? getMachinery : getAnimalsService }
            options={ {
              selection: true
            } }
            urlParams={ { propertyId } }
            queryParams= { { q: debouncedFilterText, property_id: propertyId, asset: false, my_machines: true } }
            onSelectionChange={ (value) => setSelectedRows(mapValues(value, (_, index) => rows[index])) }
            id="search-asset-table"
            onDataLoad={ data => setRows(zipObject(map(data, item => item?.id), map(data, item => {
              let currentRow = {}
              if(item?.propertiesAssets) {
                currentRow = { ...item?.propertiesAssets }
              }
              if(activeTab === 0) {
                currentRow = {
                  ...currentRow,
                  ...item,
                  assetPurchaseDate: item?.propertiesAssets?.assetPurchaseDate ? moment(item?.propertiesAssets?.assetPurchaseDate).format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD'),
                  startDepreciationDate: item?.propertiesAssets?.startDepreciationDate ? moment(item.propertiesAssets.startDepreciationDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
                  depreciationGroup: item?.propertiesAssets?.depreciationGroup || find(depreciationGroups, slug => slug.slug === 'veículos-pesados')?.value,
                  assetEstimatedLife: item?.propertiesAssets?.assetEstimatedLife || find(depreciationGroups, slug => slug.slug === 'veículos-pesados')?.usefulLife,
                  assetValue: item?.propertiesAssets?.assetValue
                }
              } else {
                currentRow = {
                  ...currentRow,
                  ...item,
                  assetPurchaseDate: item?.propertiesAssets?.assetPurchaseDate ? moment(item?.propertiesAssets?.assetPurchaseDate).format('YYYY-MM-DD') : (item?.birthDate ? moment(item?.birthDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')),
                  startDepreciationDate: item?.propertiesAssets?.startDepreciationDate ? moment(item.propertiesAssets.startDepreciationDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
                  depreciationGroup: item?.propertiesAssets?.depreciationGroup || find(depreciationGroups, slug => slug.slug === 'animais')?.value,
                  assetEstimatedLife: item?.propertiesAssets?.assetEstimatedLife || find(depreciationGroups, slug => slug.slug === 'animais')?.usefulLife,
                  assetValue: item?.propertiesAssets?.assetValue
                }
              }
              return currentRow
            }))) }
          />
          <ButtonsContainer>
            <Button
              id="cancel-search-asset-modal"
              onClick={ closeModal }
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginRight: 10,
                marginTop: 10
              } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="submit-search-asset-modal"
              onClick={ submitModal }
              color="yellow"
              style={ {
                padding: '5.5px 10px',
                fontSize: '0.875rem',
                marginTop: 10
              } }
              disabled={ isEmpty(selectedRows) }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        </Content>
      </>
    </Modal>
  )
}

SearchAssetModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  propertyId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default SearchAssetModal
