import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import Modal from '@smartcoop/web-components/Modal'
import RegisterMilkControlForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterMilkControlForm'

import useStyles from './styles'

const RegisterMilkControlModal = (props) => {
  const {
    id,
    open,
    defaultValues,
    handleClose,
    reloadDataTable
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [isLoading, setIsLoading] = useState(false)
  const t = useT()

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      reloadDataTable()
      handleClose()
    }, [handleClose, reloadDataTable]
  )

  const isEditing = useMemo(
    () => (defaultValues?.id), [defaultValues]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'female',
          this: t('delivery', { howMany: 1 })
        })
      )
      closeModal()
    },
    [closeModal, isEditing, snackbar, t]
  )

  const submitForms = useCallback(
    (formData) => {
      setIsLoading(true)
      dispatch(AnimalActions.saveMilkControl({
        data: [
          {
            ...formData.data
          }
        ],
        automaticLotHandling: formData.automaticLotHandling
      },
      onSuccess,
      () => setIsLoading(false)
      ))
    }, [dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n>{defaultValues?.id ? 'edit milk control' : 'register milk control'}</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >

      <RegisterMilkControlForm
        ref={ formRef }
        defaultValues={ defaultValues }
        onSubmit={ submitForms }
        onCancel={ closeModal }
        loading={ isLoading }
      />

    </Modal>
  )}

RegisterMilkControlModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  handleClose: PropTypes.func,
  reloadDataTable: PropTypes.func
}

RegisterMilkControlModal.defaultProps = {
  handleClose: () => {},
  reloadDataTable: () => {},
  defaultValues: {}
}

export default RegisterMilkControlModal
