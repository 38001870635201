/* eslint-disable no-unused-expressions */

import React, { useCallback, forwardRef, useMemo, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import times from 'lodash/times'
import toString from 'lodash/toString'
import includes from 'lodash/includes'

import { useDialog } from '@smartcoop/dialog'
import registerMonthlyDataHistory from '@smartcoop/forms/schemas/dairyFarm/registerMonthlyDataHistory.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { trash } from '@smartcoop/icons'
import { getMonthlyDataHistory } from '@smartcoop/services/apis/smartcoopApi/resources/dairyFarm'
import { getProducts } from '@smartcoop/services/apis/smartcoopApi/resources/product'
import { getProductGroups } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentBackMonthYearFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputMonthYear from '@smartcoop/web-components/InputMonthYear'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import InputUnit from '@smartcoop/web-components/InputUnit'

import SelectMonthYearModal from '@smartcoop/web-containers/modals/SelectMonthYearModal'
import {
  Container,
  FormContainer,
  ButtonsContainer,
  Row
} from './styles'

const RegisterMonthlyDataHistoryForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    shouldDisplayCosts
  } = props

  const t = useT()
  const dispatch = useDispatch()
  const { createDialog } = useDialog()

  const currentProperty = useSelector(selectCurrentProperty)
  const lots = useSelector(selectLots)

  const [items, setItems] = useState(defaultValues?.rationData?.length || 1)
  const [productsOptions, setProductsOptions] = useState([])
  const [rationLots, setRationLots] = useState([])
  const [monthlyDataList, setMonthlyDataList] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  const lotsOptions = useMemo(() => map(lots, lot => ({ label: lot.name, value: lot.id, item: lot })), [lots])

  const dairyFarmId = useMemo(
    () => currentProperty?.dairyFarm[0]?.id,
    [currentProperty]
  )

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        propertyId: currentProperty?.id,
        dairyFarmId,
        ...data,
        rationData: filter(data?.rationData, item => !isEmpty(item?.productId)),
        date: isArray(data.date) ? data.date[0] : data.date
      })
    },
    [currentProperty, dairyFarmId, onSubmit]
  )

  const onChangeUnitaryCost = useCallback((item) => {
    const monthlyRation = formRef.current.getFieldRef(`rationData[${ item }].monthlyRation`).value
    const unitaryCost = formRef.current.getFieldRef(`rationData[${ item }].unitaryCost`).value

   const refTotalCost = formRef.current.getFieldRef(`rationData[${ item }].totalCost`)
   
   if(refTotalCost) {
    refTotalCost.setValue(monthlyRation && unitaryCost ? round(monthlyRation*unitaryCost, 2) : 0)
   }
  }, [formRef])

  const loadProducts = useCallback(async () => {
    const { data: { data: productGroups } } = await getProductGroups({ limit: 9999 })
    const func = map(filter(productGroups, group => group?.slug === 'alimentos-volumosos' || group?.slug === 'racoes-e-concentrados'), async group => {
      const { data: { data } } = await getProducts({ digitalization: true, limit: 99999, productGroupId: group?.id })
      return data
    })


    Promise.all(func).then((response) => {
      setProductsOptions(map(flatMap(response, item => item), item => ({ label: item?.description, value: item?.id, item })))
    })
  },[])

  const loadMonthlyDataList = useCallback(async () => {
    const { data: { data } } = await getMonthlyDataHistory({ propertyId: currentProperty?.id, limit: 9999 })

    setMonthlyDataList(data)
  },[currentProperty.id])

  useEffect(() => {
    loadMonthlyDataList()
    loadProducts()
  }, [loadMonthlyDataList, loadProducts])

  useEffect(() => {
    dispatch(
      LotActions.loadLots(
        { propertyId: currentProperty?.id }
      )
    )
  }, [currentProperty, dispatch, t])

  useEffect(() => {
   if(formRef?.current && !isEmpty(defaultValues?.rationData) && items > 0) {
      map(defaultValues?.rationData, (item, i) => {
        formRef?.current?.getFieldRef(`rationData[${i}].productId`)?.setValue?.(item?.productId)
        formRef?.current?.getFieldRef(`rationData[${i}].monthlyRation`)?.setValue?.(item?.monthlyRation)
        formRef?.current?.getFieldRef(`rationData[${i}].totalCost`)?.setValue?.(toString(item?.totalCost))
        formRef?.current?.getFieldRef(`rationData[${i}].unitaryCost`)?.setValue?.(toString(item?.totalCost) ? item?.totalCost / item?.monthlyRation : '' )
        formRef?.current?.getFieldRef(`rationData[${i}].lotsIds`)?.setValue?.(item?.lotsIds)
        formRef?.current?.getFieldRef(`rationData[${i}].referenceId`)?.setValue?.(item?.referenceId)
      })
   }
  }, [defaultValues, formRef.current])

  const onCopyRegistry = useCallback(() => {
    createDialog({
      id: 'copy-registry-modal',
      Component: SelectMonthYearModal,
      props: {
        monthYears: map(monthlyDataList, item => ({
          value: moment(item?.date, momentBackDateFormat).format('MM/YYYY'),
          label: moment(item?.date, momentBackDateFormat).format('MM/YYYY')
        })),
        monthlyDataList,
        setItems: (response) => setItems(response),
        onConfirm: (response) => {
          const found = find(monthlyDataList, item => moment(item?.date, momentBackDateFormat).format('MM/YYYY') === response)
          const updatedFound = isObject(found?.rationData) ? ({
              ...found,
              rationData: map(found?.rationData, (item) => ({
                ...item
              }))
            }) : (
              {
                ...found
              }
            )
          if(isEmpty(updatedFound?.rationData)) {
            formRef?.current?.getFieldRef(`rationData[0].productId`)?.setValue?.(null)
            formRef?.current?.getFieldRef(`rationData[0].monthlyRation`)?.setValue?.(null)
            formRef?.current?.getFieldRef(`rationData[0].totalCost`)?.setValue?.(null)
            formRef?.current?.getFieldRef(`rationData[0].unitaryCost`)?.setValue?.(null)
            formRef?.current?.getFieldRef(`rationData[0].lotsIds`)?.setValue?.(null)
            formRef?.current?.getFieldRef(`rationData[0].referenceId`)?.setValue?.(null)

            setSelectedProducts([])
            setRationLots([])
          }
          if(!isEmpty(updatedFound?.rationData) && isArray(updatedFound?.rationData)) {
            const newSelectedProducts = []
            const newRationLots = []

            map(updatedFound?.rationData, (item, i) => {
              formRef?.current?.getFieldRef(`rationData[${i}].productId`)?.setValue?.(item?.productId)
              formRef?.current?.getFieldRef(`rationData[${i}].monthlyRation`)?.setValue?.(item?.monthlyRation)
              formRef?.current?.getFieldRef(`rationData[${i}].totalCost`)?.setValue?.(item?.totalCost)
              formRef?.current?.getFieldRef(`rationData[${i}].unitaryCost`)?.setValue?.(item?.unitaryCost)
              formRef?.current?.getFieldRef(`rationData[${i}].lotsIds`)?.setValue?.(item?.lotsIds)
              formRef?.current?.getFieldRef(`rationData[${i}].referenceId`)?.setValue?.(null)

              const foundProduct = find(productsOptions, product => product?.value === item?.productId) || {}
              newSelectedProducts[i] = foundProduct?.item
              
              const foundLot = map(filter(lotsOptions, lot => includes(item?.lotsIds, lot?.value)), each => each?.item) || []
              newRationLots[i] = foundLot
            })

            // Opcionalmente, atualiza a contagem de itens
            setSelectedProducts(newSelectedProducts)
            setRationLots(newRationLots)
          }
          formRef.current.getFieldRef('monthlyDiscardedMilk').setValue(updatedFound?.monthlyDiscardedMilk)
          formRef.current.getFieldRef('monthlyMilkForCalves').setValue(updatedFound?.monthlyMilkForCalves)
        },
        textWarning: t('select the desired month year to copy')
      }
    })
  },[createDialog, formRef, monthlyDataList, t, productsOptions, lotsOptions])

  const handleDeleteRow = useCallback((index) => {
    const formData = formRef.current.getData()
    const currentData = formData?.rationData
    const newSelectedProducts = []
    const newRationLots = []
    // Move os dados para baixo a partir do índice atual
    for (let i = index; i < currentData.length; i++) {
      const oldProductId = formRef.current.getFieldRef(`rationData[${i + 1}].productId`)?.value
      const oldMonthlyRation = formRef.current.getFieldRef(`rationData[${i + 1}].monthlyRation`)?.value
      const oldTotalCost= formRef.current.getFieldRef(`rationData[${i + 1}].totalCost`)?.value
      const oldUnitaryCost = formRef.current.getFieldRef(`rationData[${i + 1}].unitaryCost`)?.value
      const oldLotsIds = formRef.current.getFieldRef(`rationData[${i + 1}].lotsIds`)?.value
      const oldReferenceId = formRef.current.getFieldRef(`rationData[${i + 1}].referenceId`)?.value

      formRef.current.getFieldRef(`rationData[${i}].productId`).setValue(oldProductId)
      formRef.current.getFieldRef(`rationData[${i}].monthlyRation`).setValue(oldMonthlyRation)
      formRef.current.getFieldRef(`rationData[${i}].totalCost`).setValue(oldTotalCost)
      formRef.current.getFieldRef(`rationData[${i}].unitaryCost`).setValue(oldUnitaryCost)
      formRef.current.getFieldRef(`rationData[${i}].lotsIds`).setValue(oldLotsIds)
      formRef.current.getFieldRef(`rationData[${i}].referenceId`).setValue(oldReferenceId)

      const foundProduct = find(productsOptions, product => product?.value === oldProductId) || {}
      newSelectedProducts[i] = foundProduct?.item
      
      const foundLot = map(filter(lotsOptions, lot => includes(oldLotsIds, lot?.value)), each => each?.item) || []
      newRationLots[i] = foundLot
      
    }
    // Opcionalmente, atualiza a contagem de itens
    setSelectedProducts(newSelectedProducts)
    setRationLots(newRationLots)
    setItems(items - 1)
  }, [formRef, items])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerMonthlyDataHistory }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Row>
            <InputMonthYear
              name="date"
              label={ `${ t('month', { howMany: 1 }) }/${ t('year', { howMany: 1 }) }` }
              defaultValue={ moment(defaultValues?.date, momentBackDateFormat).format(momentBackMonthYearFormat) }
              disabled={ defaultValues?.id }
              style={ { width: '95%' } }
              fullWidth
            />
            <div style={ { width: '30%' } }>
              <Button
                id="button-copy-registery"
                onClick={ onCopyRegistry }
                fullWidth
              >
                {t('copy registry')}
              </Button>
            </div>
          </Row>
          {times(items, item => (
            <fieldset style={ { marginBottom: 10, paddingTop: 10, borderRadius: 10, border: '1px solid #babaca', display: 'flex', flexDirection: 'column', width: '100%' } }>
              <Row>
                <InputSelect
                  order={ false }
                  label={ t('products') }
                  name={ `rationData[${ item }].productId` }
                  options={ orderBy(productsOptions, product => product?.item?.productGroup?.name, 'asc') }
                  groupBy={ option => option?.item?.productGroup?.name }
                  style={ { marginRight: 10 } }
                  onChange={ ({target: { value }}, selected)  => {
                    if(isEmpty(value)) { 
                      setSelectedProducts(old => {
                        const currentProducts = { ...old }
                        currentProducts[item] = null
                        return currentProducts
                      })
                      return
                    }
                    if(isEmpty(selected)) return
                    setSelectedProducts(old => {
                      const currentProducts = { ...old }
                      currentProducts[item] = selected?.item
                      return currentProducts
                    }) 
                  }}
                />
                <InputSelect
                  name={ `rationData[${ item }].lotsIds` }
                  label={ t('lot', { howMany: 2 }) }
                  options={ lotsOptions }
                  multiple
                  onChange={ ({target: { value }}, selected)  => {
                    if(isEmpty(value)) { 
                      setRationLots(old => {
                        const currentLots = { ...old }
                        currentLots[item] = []
                        return currentLots
                      })
                      return
                    }
                    if(isEmpty(selected)) return
                    setRationLots(old => {
                      const currentLots = { ...old }
                      currentLots[item] = map(selected, each => each?.item)
                      return currentLots
                    })
                  } }
                  getOptionDisabled={ option => !isEmpty(rationLots[item]) ? !(option?.item?.slug === rationLots[item]?.[0]?.slug) : {} }
                />
                <Button
                  id="delete-row-button"
                  variant="outlined"
                  style={ { minWidth: '30px', minHeight: '30px', border: `1px solid ${ colors.backgroundHtml }`, padding: 0, marginBottom: 10, marginLeft: 10 } }
                  onClick={ () => {
                    handleDeleteRow(item) } 
                  }
                  disabled={ items === 1 }
                >
                  <Icon icon={ trash } size={ 14 } color={ colors.red } />
                </Button>
              </Row>
              <Row>
                <InputUnit
                  name={ `rationData[${ item }].monthlyRation` }
                  label={ t('quantity') }
                  unit={ selectedProducts?.[item]?.unitOfMeasures ? `${ selectedProducts?.[item]?.unitOfMeasures }/mês` : '' }
                  type="integer"
                  maxLength={ 8 }
                  fullWidth
                  style={ { marginRight: 10 } }
                  onChange={ () => onChangeUnitaryCost(item) }
                />
                {shouldDisplayCosts ? (
                  <InputUnit
                    name={ `rationData[${ item }].unitaryCost` }
                    label={ t('unitary cost') }
                    unit={ selectedProducts?.[item]?.unitOfMeasures ? `R$/${ selectedProducts?.[item]?.unitOfMeasures }` : '' }
                    type="float"
                    onChange={ () => onChangeUnitaryCost(item) }
                    fullWidth
                    style={ { marginRight: 10 } }
                  />
                ) : null}
                {shouldDisplayCosts ? (
                  <InputUnit
                    name={ `rationData[${ item }].totalCost` }
                    label={ t('total cost') }
                    unit="R$"
                    type="float"
                    disabled
                    fullWidth
                    style={ { marginRight: 10 } }
                  />
                ) : null}
                {shouldDisplayCosts ? (
                  <InputText
                    name={ `rationData[${ item }].referenceId` }
                    disabled
                    style={ { width: 0, height: 0, opacity: 0 } }
                  />
                ) : null}

              </Row>
            </fieldset>
          ))}
          <Row style={ { justifyContent: 'flex-end' } }>
            <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
              <Button
                id="button-remove-item"
                onClick={ () => setItems(items - 1) }
                variant="outlined"
                disabled={ items === 1 }
                style={ { marginRight: '20px' } }
              >
        -
              </Button>

              <Button
                id="button-add-item"
                onClick={ () => setItems(items + 1) }
              >
        +
              </Button>
            </div>
          </Row>
          <Row>
            <InputUnit
              name="monthlyMilkForCalves"
              label={ t('milk destined for calves') }
              unit="litros/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.monthlyMilkForCalves) }
              maxLength={ 8 }
              fullWidth
            />
          </Row>
          <Row>
            <InputUnit
              name="monthlyDiscardedMilk"
              label={ t('milk discarded on the property or family consumption') }
              unit="litros/mês"
              type="integer"
              defaultValue={ toString(defaultValues?.monthlyDiscardedMilk) }
              maxLength={ 8 }
              fullWidth
            />
          </Row>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight: 10, marginLeft: 40 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight: 40, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

RegisterMonthlyDataHistoryForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  shouldDisplayCosts: PropTypes.bool,
  defaultValues: PropTypes.object.isRequired
}

RegisterMonthlyDataHistoryForm.defaultProps = {
  loading: false,
  shouldDisplayCosts: false,
  onSubmit: () => {},
  onCancel: () => {},
  withoutSubmitButton: false
}

export default RegisterMonthlyDataHistoryForm
