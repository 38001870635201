/* eslint-disable max-len */
export default {
  'datagrid body empty data source message': 'Nenhum registro encontrado',
  'datagrid body add tooltip': 'Adicionar',
  'datagrid body delete tooltip': 'Remover',
  'datagrid body access tooltip': 'Acessar',
  'datagrid body edit tooltip': 'Editar',
  'datagrid body edit row delete text':
    'Você tem certeza que deseja deletar esse registro?',
  'datagrid body edit row cancel tooltip': 'Cancelar',
  'datagrid body edit row save tooltip': 'Salvar',
  'datagrid body filter row filter tooltip': 'Filtro',
  'datagrid grouping grouped by': 'Agrupado por:',
  'datagrid grouping placeholder':
    'Arraste colunas para cá para agrupar o resultado',
  'add contact':'Adicionar contato',
  'family group':'Grupo Familiar',
  'financial data':'Dados Financeiros',
  'datagrid header actions': ' ', // force not render title for actions column
  'datagrid pagination label displayed rows': '{from}-{to} de {count}',
  'datagrid pagination label rows select': 'registros',
  'datagrid pagination label rows per page': 'Registros por página:',
  'datagrid pagination first aria label': 'Primeira página',
  'datagrid pagination first tooltip': 'Primeira página',
  'datagrid pagination previous aria label': 'Página anterior',
  'datagrid pagination previous tooltip': 'Página anterior',
  'datagrid pagination next aria label': 'Próxima página',
  'datagrid pagination next tooltip': 'Próxima página',
  'datagrid pagination last aria label': 'Última página',
  'datagrid pagination last tooltip': 'Última página',
  'datagrid toolbar add remove columns': 'Selecione as colunas visíveis',
  'datagrid toolbar n rows selected': '{0} registro(s) selecionado(s)',
  'datagrid toolbar show columns title': 'Colunas visíveis',
  'datagrid toolbar show columns aria label': 'Colunas visíveis',
  'datagrid toolbar export title': 'Exportar',
  'datagrid toolbar export aria label': 'Exportar',
  'datagrid toolbar export name': 'Exportar como CSV',
  'datagrid toolbar search tooltip': 'Pesquisar',
  'datagrid toolbar search placeholder': 'Pesquisar...',
  'datagrid fetch load error': 'Não foi possível carregar os dados',
  'pendencies costs': 'Pendências / Custos',
  'new cost': 'Novo registro',
  'new registry': 'Novo registro',
  'register milk control': 'Registrar Controle Leiteiro',
  'edit milk control': 'Editar Controle Leiteiro',
  'Error while save dairy control': 'Erro ao salvar Controle Leiteiro',
  'receipt/cost/expense': 'receita/custo/despesa',
  'by crop': 'Por Cultura',
  'by lot': 'Por Lote',
  'This rubric is referenced by one or more costs and cannot be deleted': 'Esta rúbrica está sendo utilizada por um ou mais registros na gestão financeira e não pode ser deletada.',
  'edit cost': 'Editar Registro',
  'other direct cost': 'Outras rubricas',
  'there is no aditional costs registered': 'Não há custos adicionais cadastrados.',
  'change lots': 'Troca de lotes',
  'there is no costs registered': 'Não há custos cadastrados.',
  'cost deleted sucessfully': 'Custo deletado com sucesso.',
  'field pendencies lists': 'Pendencias de Custos do Talhão',
  'management applied inputs': 'Insumos aplicados nos manejos',
  'hello world': 'Olá mundo!',
  'profile avatar load error': 'Houve um erro ao carregar o avatar.',
  'dashboard': 'Dashboard',
  'image not available offline': 'Imagem não disponível offline',
  'document not available offline': 'Documento não disponível offline',
  'help': 'Ajuda',
  'on going': 'Em andamento',
  'heifer age for first insemination': 'Idade novilhas p/ 1º IA',
  'ration for calves': 'Ração para terneiras',
  'ration for heifer': 'Ração pré-parto',
  'ration for lactation': 'Ração para vacas em lactação',
  'interrupted': 'Interrompido',
  'pending': 'Pendente',
  'undo': 'Desfazer',
  'cost': 'Custo',
  'monthly cost': 'Custo Mensal',
  'draw field': 'Desenho do talhão',
  'draw digital signature': 'Desenhe sua assinatura digital',
  'eletronic signature': 'Assinatura Eletrônica',
  'signatures': 'Assinaturas',
  'visit details': 'Detalhes da visita',
  'del': 'DEL',
  'DEL': 'DEL',
  'are you sure you want to apply this recommendation to fields': 'Você tem certeza que deseja aplicar essa recomendação para os talhões?',
  'associated organizations': 'Você está vinculado à:',
  'name': 'Nome',
  'email': 'E-mail',
  'password': 'Senha',
  'new password': 'Nova senha',
  'hide password': 'Esconder senha',
  'show password': 'Mostrar senha',
  'shopping platform only available in web': 'Módulo de Compras disponível somente na versão WEB da plataforma. Acesse em: smart.coop.br',
  'please confirm your password to update the eletronic signature':
    'Por favor, confirme sua senha para atualizar a assinatura eletrônica.',
  'your new password was generated and sent to your email and SMS':
    'Sua nova assinatura foi gerada e enviada para seu endereço de e-mail e por SMS.',
  'login': 'Entrar',
  'logout': 'Sair',
  'generate': 'Gerar',
  'At least one quality field must be provided.': 'Volume ou dados de qualidade devem ser informado.',
  '{this} must contain at least {size} characters':
    '{this} deve conter no mínimo {size} caracteres',
  'user': '{howMany, plural, one {Usuário} other {Usuários}}',
  'address': '{howMany, plural, one {Endereço} other {Endereços}}',
  'city': '{howMany, plural, one {Cidade} other {Cidades}}',
  'street': 'Rua',
  'edit address': 'Editar endereço',
  'dairy farm': 'Tambo',
  'dairy farming': 'Pecuária de leite',
  'neighborhood': 'Bairro',
  'save to album': 'Salvar na galeria',
  'producer':
    '{gender, select, male {{howMany, plural, one {Produtor} other {Produtores}}} female {{howMany, plural, one {Produtora} other {Produtoras}}}}',
  'invalid cep': 'CEP inválido',
  'invalid': 'Inválido',
  'empty select options': 'Nenhuma opção encontrada',
  'date of birth': 'Data de Nascimento',
  'invalid date': 'Data inválida',
  'invalid hour': 'Hora inválida',
  'invalid number': 'Número inválido',
  'date needs to be at least same or after {this}': 'Data deve ser igual ou depois de {this}',
  'signup': 'Cadastrar',
  'This Term was already accepted': 'Este termo já foi aceito',
  'add profile picture': 'Adicionar foto de perfil',
  'full name': 'Nome Completo',
  'full': 'Completo',
  'trinket': 'Brinco',
  'name of lot': 'Nome do lote',
  'current lot': 'Lote atual',
  'new lot': 'Novo lote',
  'cellphone': 'Celular',
  'phone': 'Telefone',
  'my dairy farm': 'Meu Tambo',
  'edit activities': 'Editar atividades',
  'next': 'Próximo',
  'type here the reason of the condemnation': 'Digite aqui o motivo da condenação',
  'images of the machine': 'Imagens da máquina',
  'images': 'Imagens',
  'result': 'Resultado',
  'limit': 'Limite',
  'no image available': 'Nenhuma imagem disponível',
  'This animal earring is already being used': 'Este brinco de animal já está sendo utilizado',
  'contact': 'Contato',
  'machine close to your property': 'Máquina próxima de sua propriedade.',
  'machine details': 'Detalhes da Máquina',
  'machines details': 'Detalhes das Máquinas',
  'gathering date': 'Data de Coleta',
  'contact informations': 'Informações de contato',
  'informations': 'Informações',
  'informations {this}': 'Informações {this}',
  'machinery register': 'Cadastro de máquinas',
  'go back': 'Voltar',
  'number': 'Número',
  'today': 'Hoje',
  'yesterday': 'Ontem',
  'chat': 'Conecta',
  'indicators': 'Indicadores',
  'still hasnt concluded all the crop management': 'Não é possível encerrar o cultivo,  pois você ainda tem manejos não concluídos.',
  'state': '{howMany, plural, one {Estado} other {Estados}}',
  'update user code' : 'Atualizar código de contato',
  'chat code - confirmation' : 'Código Chat - Confirmação ',
  'chat code - update' : 'Código Chat - Atualização ',
  'Due to the change of your Chat code, it is necessary to re-establish a new connection with this contact. Do you wish to continue?' : 'Devido a alteração de seu código de Chat, é necessário reestabelecer uma nova conexão com este contato. Deseja continuar?',
  'Due to the change of your contact code, you must inform the updated code of this contact.' : 'Devido a alteração do código de seu contato, você deve informar o código atualizado deste contato.',
  'code': 'Código',
  'you have reached the maximum message length of 5000 characters, your message must be split into more than one part.':'Você atingiu o tamanho máximo da mensagem de 5 mil caracteres, sua mensagem deve ser dividida em mais de uma parte.',
  'user code' : 'Código do usuário',
  'you have reached the maximum message length of 5000 characters':'Você atingiu o tamanho máximo da mensagem de 5 mil caracteres',
  'search or start a new conversation' : 'Pesquisar ou começar uma nova conversa',
  'your message must be split into more than one part.' : 'sua mensagem deve ser dividida em mais de uma parte.',
  'chat code': 'Código Chat',
  'search contact':'Pesquisar contatos',
  'your chat contact': 'Seu código de contato chat é',
  'your chat code': 'Código do chat',
  'invalid code': 'Código invalido',
  'valid until': 'Válido até',
  'access valid until': 'Acesso válido até',
  'plan': 'Plano',
  'breed': 'Raça',
  'transfer property': 'Transferir Propriedade',
  'There is another bull using this code': 'Há outro touro usando este código',
  'confirm password': 'Confirmar Senha',
  'passwords do not match': 'As senhas não correspondem',
  'fields do not match': 'Os campos não correspondem',
  'welcome to smartcoop': 'Bem-vindo(a)\na Smartcoop!',
  'welcome to smartcoop (without break)': 'Bem-vindo(a) a Smartcoop!',
  'if your are an associated producer to one of the participants cooperatives of the smartcoop project and you are not registered in the platform, register by following the steps below': 'Se você é produtor e associado a uma das cooperativas participantes do projeto SmartCoop e ainda não possui cadastro na plataforma, cadastre-se seguindo as etapas abaixo:',
  'to enter, insert your data': 'Para entrar, insira seus dados:',
  'first access?': 'Primeiro acesso?',
  'to us do your data validation, fill the fields below':
    'Para validarmos seus dados,\npreencha os campos abaixo:',
  'identification': 'Identificação',
  'to continue your registration, confirm your data':
    'Para continuar seu cadastro,\nconfirme seus dados:',
  'to recover your password, confirm your data':
    'Para recuperar sua senha,\nconfirme seus dados:',
  'all right': 'Tudo certo!',
  'cooperative': '{howMany, plural, one {Cooperativa} other {Cooperativas}}',
  'third party': 'Empresa Terceirizada',
  'enter the company name': 'Informe o nome da empresa',
  'by our data, we found the registers':
    'Pelos seus dados, encontramos\nvínculos com as seguintes cooperativas:',
  'you have no register': 'Você não tem vínculo com nenhuma cooperativa',
  'register number': 'Matrícula',
  'reset': 'Tirar novamente',
  'capture': 'Capturar',
  'take picture': 'Tirar foto',
  'record video': 'Gravar vídeo',
  'we sent a password reset code via SMS to the final cell phone':
    'Enviamos um código de redefinição de senha por SMS para o celular de final ',
  'enter it below': 'Digite-o abaixo:',
  'send code by mail': 'Enviar o código por e-mail',
  'the code was sent to your email': 'O código foi enviado para seu e-mail',
  'could not send the code to your email': 'Não foi possível enviar o código para seu email',
  'create password': 'Cadastre sua senha',
  'your password must contain': 'Sua senha deve conter:',
  'at least one letter': 'Ao menos 1 (uma) letra;',
  'at least one number': 'Ao menos 1 (um) número;',
  'at least length of five': 'Ao menos 5 (cinco) caracteres;',
  'it must not contain your date of birth, social security number, registration number, telephone number, etc.':
    'Não deve conter sua data de nascimento, CPF, matrícula, número de telefone, etc.',
  'success': 'Sucesso!',
  'code send by email': 'Código enviado por e-mail',
  'sent': 'Enviado',
  'check your mail app':
    'Cheque a caixa de entrada do seu aplicativo de e-mails',
  'we didn\'t find your data, contact your cooperative':
    'Não encontramos seus dados.\nEntre em contato com sua cooperativa.',
  'User not found':
    'Não encontramos seus dados.\nEntre em contato com sua cooperativa.',
  'we didn\'t find your data': 'Não encontramos seus dados',
  'user not active': 'Não encontramos seus dados.\nEntre em contato com sua cooperativa.',
  'Inactive user': 'Utilize a opção abaixo "Primeiro acesso?" e crie sua senha de acesso',
  'Invalid login data': 'Senha incorreta. Se desejar redefinir sua senha utilize a opção "esqueci minha senha".',
  'ops': 'Ops...',
  'close': 'Fechar',
  'full screen': 'Tela inteira',
  'exit full screen': 'Sair da tela inteira',
  'reopen growing season': 'Reabrir Cultivo',
  'your growing season was reopened': 'Seu Cultivo foi reaberto!',
  'ok': 'Ok',
  'validating your data...': 'Validando seus dados...',
  'invalid verification code': 'Código de verificação inválido',
  'confirm': 'Confirmar',
  'own': 'Próprio',
  'leased': 'Arrendado',
  'sharing by activity': 'Rateio por atividade',
  'sharing by crop': 'Rateio por cultura',
  'sharing': 'Rateio',
  'rubric group': 'Grupo/Rubrica',
  'classification': 'Classificação',
  'verify your connection and try again': 'Verifique sua conexão e tente novamente.',
  'Cannot read property \'data\' of undefined': 'Verifique sua conexão e tente novamente.',
  'undefined is not an object (evaluating \'u.data\')': 'Verifique sua conexão e tente novamente.',
  'undefined is not an object (evaluating \'o.data\')': 'Verifique sua conexão e tente novamente.',
  'own or leased': 'Próprio ou Arrendado',
  'yes': 'Sim',
  'no': 'Não',
  'are you sure you want to remove your field from': 'Tem certeza que deseja remover o Talhão do',
  'are you sure you want to save these changes': 'Tem certeza que deseja salvar estas alterações?',
  'are you sure you want to cancel these changes': 'Tem certeza que deseja cancelar estas alterações?',
  'remove from': 'Remover do',
  'This field is already registered in this project': 'Este talhão já está registrado neste projeto.',
  'project type': 'Nome do Projeto',
  'field participating in': 'Talhão participante do',
  'invalid format, must be 000.00.00': 'Formato inválido, deve ser: 000.00.00',
  'irrigation': 'Irrigação',
  'privacy term': 'Termo de Privacidade',
  'use term': 'Termo de Uso',
  'participation term': 'Termo de Participação',
  'terms': 'Termos',
  'platform terms': 'Termos da Plataforma',
  'use terms': 'Termos de Uso',
  'social network terms': 'Termos de Redes Sociais',
  'privacy terms': 'Termos de Privacidade',
  'comercialization terms': 'Termos de Comercialização',
  'supplier terms': 'Termos de Fornecedor',
  'organization terms': 'Termos de Organização',
  'field name': 'Nome Talhão',
  'area': 'Área',
  'irrigated?': 'Irrigado?',
  'property': '{howMany, plural, one {Propriedade} other {Propriedades}}',
  'user already registered': 'Usuário já foi ativado anteriormente',
  'you don\'t have access to this application.\nplease sign up on the desktop version': 'Você não tem acesso a este aplicativo.\nPor favor, cadastra-se na versão web.',
  'user doesn\'t registered': 'Você ainda não possui cadastro',
  'I forgot my password': 'Esqueci minha senha',
  'no problem': 'Sem problemas...',
  'situation': 'Situação',
  'crop situation': 'Situação cultivo',
  'you have unconfirmed field split, do you wanna split and continue?': 'Você possui uma divisão não confirmada, deseja confirmar e continuar com a criação do Talhão?',
  'ground': 'Terreno',
  'satellite': 'Satélite',
  'others': 'Outros',
  'until': 'até',
  'add another acticity': 'Adicionar outra atividade',
  'complement': 'Complemento',
  'property name': 'Nome da Propriedade',
  'total area': 'Área Total',
  'register property': 'Cadastrar propriedade',
  'register more later': 'Cadastrar mais tarde',
  'property registration': 'Cadastro de Propriedade',
  'You can not create a diagnosis': 'Você não pode criar um diagnóstico',
  'Only the last diagnosys can be deleted': 'Apenas o último diagnóstico pode ser deletado.',
  'The first touch is mandatory': 'O primeiro toque é obrigatório',
  'First touch already exists': 'O primeiro toque já existe',
  'this field does not yet have a history': 'Esse talhão ainda não possui histórico',
  'your registration was effected!': 'Seu cadastro foi efetuado!',
  'to start, let\'s register your property!':
    'Para começar, vamos\ncadastrar sua propriedade!',
  'example: line, community, attachment, etc.':
    'Exemplo: linha, comunidade, anexo, etc.',
  'property activities': 'Atividades da Propriedade',
  'This account does not have securities movements': 'Esta conta não possui movimentações.',
  'skip': 'Pular',
  'start': 'Iniciar',
  'field registration': 'Cadastro de Talhão',
  'field edit': 'Edição de Talhão',
  'register field': 'Cadastrar Talhão',
  'edit fields': 'Editar Talhões',
  'import animals': 'Importar animais',
  'import milk control': 'Importar controle leiteiro',
  'import file': 'Importar arquivo',
  'your property was transfered': 'Sua propriedade foi transferida!',
  'register {this}': 'Registrar {this}',
  'are you sure you want to transfer the property {property} to the user {this}?': 'Deseja realmente transferir a propriedade {property} para o produtor {this}?',
  'open suppliers by products': 'Fornecedores',
  'recommended products': 'Produtos recomendados',
  'field': '{howMany, plural, one {Talhão} other {Talhões}}',
  'image': '{howMany, plural, one {Imagem} other {Imagens}}',
  'mark your property on the map': 'Marque sua propriedade no mapa',
  'confirm location': 'Confirmar local',
  'go to start': 'Ir para o início',
  'register another property': 'Cadastrar outra propriedade',
  'your property was registered': 'Sua propriedade foi cadastrada.',
  'cancel': 'Cancelar',
  'lot': '{howMany, plural, one {Lote} other {Lotes}}',
  'lot name': 'Nome do lote',
  'trinkets': 'Brincos',
  'register rubric': 'Nova rubrica',
  'edit rubric': 'Editar rubrica',
  'rubrics': 'Rubricas',
  'rubric': 'Rubrica',
  'lot code': 'Cód. do Lote',
  'edit': 'Editar',
  'costs': 'Custos',
  'financial management': 'Gestão Financeira',
  'register activity costs': 'Registrar custos da atividade',
  'swipe down to close': 'Arraste para baixo para fechar.',
  'split': 'Dividir',
  'registers by activity': 'Registros por atividade',
  'reverse': 'Reverter',
  'reverse split': 'Reverter divisão',
  'confirm split': 'Confirmar divisão',
  'cancel split': 'Cancelar divisão',
  'dairy farm indicators': 'Indicadores',
  'insemination date': 'Data de inseminação',
  'nfe integrator': 'Integração SmartCoop NF-e',
  'user register data': 'Dados Cadastrais',
  'required fields not informed': '* Existem campos obrigatórios não informados',
  'create integration': 'Ativar Integração',
  'embryo\'s data': 'Dados do Embrião',
  'mother': 'Mãe',
  'earring': 'Brinco',
  'father': 'Pai',
  'register insemination': 'Registrar Inseminação',
  'male alt': 'Macho',
  'female alt': 'Fêmea',
  'inseminator': 'Inseminador',
  'cattle management': 'Gerenciamento de Rebanho',
  'edit {this}': 'Editar {this}',
  'save': 'Salvar',
  'saving your {this}':
    'Salvando { gender, select, male { { howMany, plural, one {seu} other {seus}} } female  { { howMany, plural, one {sua} other {suas} } } } {this}',
  'your {this} was registered':
    '{ gender, select, male { {howMany, plural, one {Seu} other {Seus}} } female { { howMany, plural, one {Sua} other {Suas} } } } {this} { howMany, plural, one {foi} other {foram} } { gender, select, male { { howMany, plural, one {cadastrado} other {cadastrados} } } female { { howMany, plural, one {cadastrada} other {cadastradas} } } }',
  'your {this} was deleted':
    '{gender, select, male {{howMany, plural, one {Seu} other {Seus}}} female {{howMany, plural, one {Sua} other {Suas}}}} {this} {howMany, plural, one {foi} other {foram}} {gender, select, male {{howMany, plural, one {deletado} other {deletados}}} female {{howMany, plural, one {deletada} other {deletadas}}}}',
  'your {this} was accepted':
    '{gender, select, male {{howMany, plural, one {Seu} other {Seus}}} female {{howMany, plural, one {Sua} other {Suas}}}} {this} {howMany, plural, one {foi} other {foram}} {gender, select, male {{howMany, plural, one {aceito} other {aceitos}}} female {{howMany, plural, one {aceita} other {aceitas}}}}',
  'participation on {this} was successfull':
    'Participação {gender, select, male {{howMany, plural, one {no} other {nos}}} female {{howMany, plural, one {na} other {nas}}}} {this} {howMany, plural, one {foi} other {foram}} {gender, select, male {{howMany, plural, one {bem sucedido} other {bem sucedidos}}} female {{howMany, plural, one {bem sucedida} other {bem sucedidas}}}}',
  'your {this} was removed':
    '{gender, select, male {Seu} female {Sua}} {this} foi {gender, select, male {removido} female {removida}}',
  'your {this} was rejected':
    '{gender, select, male {Seu} female {Sua}} {this} foi {gender, select, male {rejeitado} female {rejeitada}}',
  'your {this} was edited':
    '{gender, select, male {{howMany, plural, one {Seu} other {Seus}}} female {{howMany, plural, one {Sua} other {Suas}}}} {this} {howMany, plural, one {foi} other {foram}} {gender, select, male {{howMany, plural, one {editado} other {editados}}} female {{howMany, plural, one {editada} other {editadas}}}}',
  'the value is not valid': 'O valor não é válido',
  'your {this} was updated':
    '{gender, select, male {Seu} female {Sua}} {this} foi {gender, select, male {atualizado} female {atualizada}}',
  'your post was registered': 'Sua publicação foi cadastrada',
  'update current location': 'Atualizar localização atual',
  'location access required': 'Garantir acesso à minha localização',
  'this app needs to access your location':
    'Precisamos acessar a sua localização',
  'deleting {this}':
    'Deletando {gender, select, male {seu} female {sua}} {this}',
  'loading {this}': 'Carregando {this}',
  'lot registration': 'Cadastro de lote',
  'open': '{gender, select, male {Aberto} female {Aberta}}',
  'finished': '{gender, select, male {Finalizado} female {Finalizada}}',
  'your lots were updated': 'Os lotes foram atualizados.',
  'change organization': 'Alterar cooperativa',
  'selected organization': 'Cooperativa Selecionada',
  'selected property': 'Propriedade Selecionada',
  'other organizations': 'Outras cooperativas',
  'other properties': 'Outras Propriedades',
  'remove': 'Remover',
  'Remove': 'remover',
  'notified': 'Notificado',
  'not notified': 'Não Notificado',
  'expiration': 'Validade',
  'customers': 'Clientes',
  '{this} unknown':
    '{this} não {gender, select, male {informado} female {informada}}',
  '{this} must not exceed {size}': '{this} não pode ser maior que {size}',
  'shoppingPlatform': '{howMany, plural, one {Compra} other {Compras}}',
  'order': '{howMany, plural, one {Demanda} other {Demandas}}',
  'exit module': 'Sair do módulo',
  'module': 'Módulo',
  'expand': 'Expandir',
  'retract': 'Retrair',
  'advertising': 'Publicidade',
  'Advertising not found.': 'Publicidade não encontrada',
  'Advertising with requested status cannot be deleted.': 'Publicidade com status Solicitado não pode ser excluída.',
  'Only cooperative administrators or management federation can delete advertising.': 'Apenas administradores de cooperativa ou federação de gestão podem deletar publicidades.',
  'Error while sending advertinsing': 'Erro ao enviar publicidade',
  'new advertisement': 'Nova publicidade',
  'are you sure you want to delete this advertising?': 'Você tem certeza que deseja excluir essa publicidade?',
  '{this} must be greater than 0': '{this} deve ser maior que 0',
  '{this} must be greater than {number}': '{this} deve ser maior que {number}',
  '{this} must have {length} digits': '{this} deve ter {length} dígitos',
  '{this} must have a max of {length} digits': '{this} deve ter no máximo {length} dígitos',
  'purchase orders': 'Demandas de Compra',
  'register delivery': 'Registros de Entregas',
  'register quality': 'Registrar Análise',
  'milk deliveries': 'Entregas de Leite',
  'milk control': 'Controle Leiteiro',
  'new milk control': 'Novo Controle Leiteiro',
  'sheet': 'Planilha',
  'total solids': 'Sólidos Totais',
  'esd': 'ESD',
  'casein': 'Caseína',
  'tell who you deliver your milk production to': 'Informe para quem você realiza a entrega da sua produção de leite:',
  'all': '{gender, select, male {Todos} female {Todas}}',
  'my orders': 'Minhas demandas',
  'mine only': 'Meu usuário',
  'my organization': 'Minha Cooperativa',
  'recreate order': 'Copiar Demanda',
  'filter': 'Filtro',
  'filtrate': 'Filtrar',
  'bull': 'Touro',
  'bulls': 'Touros',
  'bull\'s code': 'Cód. Touro',
  'bull\'s name': 'Nome do Touro',
  'mother\'s name': 'Nome da Mãe',
  'mother\'s code': 'Código da Touro',
  'new demand': 'Nova Demanda',
  'contacts': 'Contatos',
  'demand': 'Demanda',
  'price': '{howMany, plural, one {Preço} other {Preços}}',
  'quality': 'Qualidade',
  'row: {row}, message: This animal earring is already being used': 'Linha: {row}. Este brinco já está sendo usado.',
  'row: {row}, message: TypeError: Cannot read property \'0\' of undefined': 'Linha: {row}. Este brinco já está sendo usado.',
  'register price': 'Registros de Preços',
  'register bull': 'Registrar Touro',
  'quality analysis': 'Análise de Qualidade',
  'soil analysis': 'Análise de Solo',
  'result date': 'Data do Resultado',
  'register soil analysis': 'Registrar Análise de Solo',
  'sample point': 'Amostrar Ponto',
  'resample point': 'Reamostrar Ponto',
  'resample': 'Reamostrar',
  'edit soil analysis': 'Editar Análise de Solo',
  'analysis type': 'Tipo de Análise',
  'lot type': 'Tipo de Lote',
  'basic chemistry': 'Química básica',
  'complete chemistry': 'Química completa',
  'analysis result': 'Resultado da Análise',
  'your analysis was edited': 'Sua análise foi editada!',
  'your analysis was registered': 'Sua análise foi registrada!',
  'analysis': '{howMany, plural, one {Análise} other {Análises}}',
  'opened': '{gender, select, male {Aberto} female {Aberta}}',
  'received': '{gender, select, male {{howMany, plural, one {Recebido} other {Recebidos}}} female {{howMany, plural, one {Recebida} other {Recebidas}}}}',
  'closed': '{gender, select, male {Encerrado} female {Encerrada}}',
  'closeds': 'Encerrados',
  'product': 'Produto',
  'clear': 'Limpar',
  'day': '{howMany, plural, one {Dia} other {Dias}}',
  'quantity of days': 'Quantidade de dias',
  'new purchase order': 'Nova Demanda de Compra',
  'product group': 'Grupo de Produtos',
  'You are trying register yourself to your family group': 'Você está tentando registrar seu próprio usuário no seu grupo familiar.',
  'select delivery locations': 'Selecione os locais de entrega',
  'search delivery location by cnpj': 'Buscar local de entrega por CNPJ',
  'billing cnpj': 'CNPJ Faturamento',
  'please, verify your password': 'Por favor, verifique sua senha.',
  'please read the terms below with attention': 'Por favor, leia o termo abaixo com atenção:',
  'i declare that i read and im aware of the norms that are in the term above':
    'Declaro que li e estou ciente das normas que constam no termo acima',
  'participate': 'Participar',
  'total purchase order': 'Demanda de Compra Total',
  'order code': 'Código da Demanda',
  'proposal code': 'Código da Proposta',
  'leave order': 'Sair da Demanda',
  'billing data': 'Dados de Faturamento',
  'your purchase in this order': 'Sua compra nessa demanda',
  'volume': 'Volume',
  'the term was accepted succesfully': 'O termo foi aceito com sucesso.',
  'accepted term': 'Termo aceito',
  'receipts': 'Receitas',
  'expenses': 'Despesas/Custos',
  'you need to accept the term to proceed': 'Para utilizar a plataforma, você deve aceitar os termos de Proteção de Dados.',
  'quantity': 'Quantidade',
  'attention': 'Atenção',
  'began to participate in': 'Começou a participar em',
  'receivement deadline': 'Prazo de recebimento',
  'delivery locations': 'Locais de entrega',
  'location': 'Localização',
  'aggregated volume': 'Volume agregado',
  'total volume': 'Volume total',
  'delivery in': 'Entrega em',
  'opening date': 'Data de abertura',
  'report who you performs the delivery of your milk production':
    'Informe para quem você realiza a entrega da sua produção de leite',
  'participation deadline': 'Data limite para participação',
  'receipt deadline': 'Data limite de recebimento',
  'proposal deadline': 'Data limite de proposta',
  'proposal limit': 'Limite para proposta',
  'receipt limit': 'Limite para recebimento',
  'delivery limit': 'Limite para entrega',
  'payment methods': 'Formas de pagamento',
  'participating organizations': 'Cooperativas participantes',
  'Participattion deadline is closed': 'Data limite para participação já encerrou.',
  'total quantity': 'Quantidade Total',
  'participate in the purchase': 'Participar da Compra',
  'no data found': 'Não foram encontrados resultados',
  'there are no registered accounts' : 'Não existem contas cadastradas',
  'canceled': '{gender, select, male {Cancelado} female {Cancelada}}',
  'in cash': 'à vista',
  'income statement for the year': 'Demonstrativo financeiro',
  'demonstrative': 'Demonstrativo',
  'deferred': 'à prazo',
  'add new analysis': 'Adicionar Nova Análise',
  'previous': 'Anterior',
  'select month in': 'Selecione o mês de',
  'my wallet': 'Minha carteira',
  'select year': 'Selecione o ano',
  'webinars': 'Webinars',
  'cost factors': 'Rubricas',
  'technical videos': 'Vídeos Técnicos',
  'news': 'Novidades',
  'add admin permission': 'Conceder perfil de admin',
  'remove admin permission': 'Remover perfil de admin',
  'technician':'Técnico',
  'smartcoop': 'Smartcoop',
  'informative': 'Informativos',
  'register informative': 'Cadastrar Informativo',
  'informative registered': 'Informativo cadastrado com sucesso!',
  'file type': 'Tipo de arquivo',
  'file url': 'Url do arquivo',
  'file name': 'Nome do arquivo',
  'register file': 'Cadastro de Arquivo',
  'cover url': 'Url da capa',
  'technical bulletins': 'Boletins técnicos',
  'search results': 'Resultados de pesquisa',
  'change property': 'Mudar de propriedade',
  'select one property': 'Selecione uma propriedade!',
  'family group properties': 'Propriedades do Grupo Familiar',
  'family group members': 'Membros do Grupo Familiar',
  'select one organization': 'Selecione uma cooperativa',
  'register new property': 'Cadastrar nova propriedade',
  'new evaluation': 'Nova avaliação',
  'update evaluation': 'Editar avaliação',
  'expiration date': 'Data de Expiração',
  'home': 'Início',
  'notification': '{howMany, plural, one {Notificação} other {Notificações}}',
  'There are no new notifications': ' Não existem novas notificações',
  'menu': 'Menu',
  'protocol': 'Protocolo',
  'register protocol': 'Registrar Protocolo',
  'no name': 'Sem nome',
  'account balance': 'Saldo em conta',
  'product balance': 'Saldo de produtos',
  'hide balance': 'Esconder saldo',
  'show balance': 'Mostrar saldo',
  'choose a module to continue': 'Escolha uma área da Smartcoop para acessar:',
  'digital property': 'Propriedade digital',
  'conversations': 'Conversas',
  'shopping platform': 'Plataforma de compras',
  'commercialization': 'Comercialização',
  'acess digital property module': 'Acessar módulo Propriedade Digital',
  'itens per page': 'itens por página',
  'crop': '{howMany, plural, one {Cultura} other {Culturas}}',
  'crops': '{howMany, plural, one {Cultivo} other {Cultivos}}',
  'crops register': 'Cadastro de Cultivo',
  'calendar': 'Calendário',
  'register crops': 'Cadastrar Cultivo',
  'should show fields': 'Deve mostrar talhões:',
  'more report options': 'Opções de exibição:',
  'report type': 'Tipo de formulário',
  'no crop': 'Sem cultivo',
  'search': 'Buscar',
  'goal': 'Finalidade',
  'sowing year': 'Ano de Semeadura',
  'january': 'Janeiro',
  'february': 'Fevereiro',
  'march': 'Março',
  'april': 'Abril',
  'may': 'Maio',
  'june': 'Junho',
  'july': 'Julho',
  'august': 'Agosto',
  'september': 'Setembro',
  'october': 'Outubro',
  'november': 'Novembro',
  'december': 'Dezembro',
  'sunday short': 'Dom',
  'monday short': 'Seg',
  'tuesday short': 'Ter',
  'wednesday short': 'Qua',
  'thursday short': 'Qui',
  'friday short': 'Sex',
  'saturday short': 'Sáb',
  'select one date': 'Selecione uma data',
  'predicted date': 'Data Prevista',
  'realization date': 'Data de Realização',
  'products balance': 'Saldo de Produtos',
  'products withdrawal': 'Produtos a Retirar',
  'supplies withdrawal': 'Insumos a retirar',
  'last updated': 'Última atualização em',
  'valid until': 'Válido até',
  'quotation': '{howMany, plural, one {Cotação} other {Cotações}}',
  'post': '{howMany, plural, one {Publicação} other {Publicações}}',
  'place': '{howMany, plural, one {local} other {locais}}',
  'purchase quotes': 'Cotações de Compra',
  'press enter to include': 'Pressione Enter para incluir',
  'no options': 'Sem Opções',
  'select {this}': 'Selecionar {this}',
  'shopping': 'Compras',
  'protein': 'Proteína',
  'fat': 'Gordura',
  'ccs': 'CCS',
  'ctb': 'CTB',
  'ccs (x1000)': 'CCS (x1000)',
  'ctb (x1000)': 'CTB (x1000)',
  'fat (x1000)': 'Gordura (x1000)',
  'protein (x1000)': 'Proteína (x1000)',
  'draw the field on the map': 'Desenhe o talhão no mapa',
  'last update at': 'Última atualização em',
  'show financial data' : 'Mostrar dados financeiros',
  'of': 'de',
  'user without permission': 'Usuário sem permissão',
  'contact your administrator': 'Não foi possível carregar os dados. Contate seu administrador',
  'warning': 'Atenção!',
  'are you sure you want to accept the supplier proposal {this}?': 'Tem certeza que deseja aceitar a proposta do fornecedor {this}?',
  'are you sure you want to delete the {this}?': 'Você tem certeza que deseja excluir {gender, select, male {{howMany, plural, one {o} other {os}}} female {{howMany, plural, one {a} other {as}}}} {this}?',
  'are you sure you want to register the {this}?': 'Você tem certeza que deseja registrar {gender, select, male {{howMany, plural, one {o} other {os}}} female {{howMany, plural, one {a} other {as}}}} {this}?',
  'are you sure you want to edit the {this}?': 'Você tem certeza que deseja editar {gender, select, male {{howMany, plural, one {o} other {os}}} female {{howMany, plural, one {a} other {as}}}} {this}?',
  'are you sure you want to remove the {this}?': 'Você tem certeza que deseja remover {gender, select, male {{howMany, plural, one {o} other {os}}} female {{howMany, plural, one {a} other {as}}}} {this}?',
  'are you sure you want to send the {this}?': 'Você tem certeza que deseja enviar {gender, select, male {{howMany, plural, one {o} other {os}}} female {{howMany, plural, one {a} other {as}}}} {this}?',
  'are you sure you want to finalize the {this}?': 'Você tem certeza que deseja finalizar seu {this}?',
  'are you sure you want to finalize your crop management and cultivation?': 'Você tem certeza que deseja finalizar seu manejo e seu cultivo?',
  'no field registered': 'Nenhum talhão cadastrado',
  'are you sure you want to complete your {this}?':
    'Você tem certeza que deseja concluir seu {this}?',
  'no crops registered': 'Nenhum cultivo cadastrado',
  'This property is already registered in this project': 'Esta propriedade já está cadastrada neste projeto.',
  'waiting decision': 'Aguardando Decisão',
  'waiting approval': 'Aguardando Aprovação',
  'proposal accepted': 'Proposta Aceita',
  'waiting delivery': 'Aguardando Entrega',
  'waiting cooperative': 'Aguardando Cooperativas',
  'late': 'Atrasada',
  'field/crop': 'Talhão/Cultivo',
  'late delivery': 'Entrega Atrasada',
  'register material': 'Cadastrar Material',
  'publish': 'Publicar',
  'public': 'Público',
  'search group members':'Pesquisar membros do grupo',
  'search broadcast members':'Pesquisar membros da lista de transmissão',
  'add participants': 'Adicionar participantes',
  'add participants confirm': 'Você deseja realmente adicionar os participantes selecionados deste grupo?',
  'select a profile': 'Selecione um perfil',
  'what would you like to share with the network?':
    'O que você gostaria de compartilhar\ncom a rede?',
  'profile': '{howMany, plural, one {Perfil} other {Perfis}}',
  'access profile': 'Acessar perfil',
  'billing mode': 'Modo de faturamento',
  'invalid year': 'Ano inválido',
  'network': 'Rede',
  'origin': 'Origem',
  'destination': 'Destino',
  'fiscal figure': 'Figura Fiscal',
  'fiscal register': 'Registro Fiscal',
  'integrations': 'Integrações',
  'plague report': 'Reporte de Pragas',
  'new fiscal register': 'Novo Registro Fiscal',
  'fiscal register deleted with success': 'Registro Fiscal deletado com sucesso!',
  'are you sure you want to delete this pest report?': 'Você tem certeza que deseja excluir essa ocorrência?',
  'are you sure you want to delete this image?': 'Você tem certeza que deseja excluir essa imagem?',
  'are you sure you want to delete your property from': 'Você tem certeza que deseja recusar o',
  'are you sure you want to delete this fiscal register?': 'Você tem certeza que deseja excluir este Registro Fiscal?',
  'your update will affect {this} producers, do you want to continue?': 'A atualização dessa figura fiscal pode impactar até {this} produtores, deseja continuar?',
  'read more': 'Leia mais',
  'there is a new version available to download in the app store, do you want to update now?': 'Existe uma nova versão disponível na loja de aplicativos, deseja atualizar agora?',
  'continue without update': 'Continuar sem atualizar',
  'read less': 'Leia menos',
  'update': 'Atualizar',
  'wheat': 'Trigo',
  'active': '{gender, select, male {Ativo} female {Ativa}}',
  'inactive': '{gender, select, male {Inativo} female {Inativa}}',
  'accept': 'Aceitar',
  'deny': 'Recusar',
  'received proposals': 'Propostas recebidas',
  'selected proposal': 'Proposta selecionada',
  'code proposal': 'Cod. Proposta',
  'comply with {this} of the demand': 'Atende {this}% da demanda',
  'quotation quantity': 'Quantidade que atende da cotação',
  'minimum demand': 'Demanda mínima a ser atendida',
  '{this} value': 'Valor {this}',
  'value': 'Valor',
  'expired': '{gender, select, male {Expirado} female {Expirada}}',
  'refused': '{gender, select, male {Recusado} female {Recusada}}',
  'refuse': 'Recusar',
  'Refuse': 'recusar',
  'refuse proposal': 'Recusar Proposta',
  'refuse reason': 'Motivo da recusa',
  'date must be a weekday': 'A data deve ser um dia útil',
  'rejected': '{gender, select, male {Rejeitado} female {Rejeitada}}',
  'participating': 'Participando',
  'waiting answer': 'Aguardando Resposta',
  'waiting proposal': 'Aguardando Proposta',
  'delivered': 'Entregue',
  'approved': '{gender, select, male {Aprovado} female {Aprovada}}',
  'unitary values register': 'Cadastro de valor unitário',
  'unitary cost': 'Custo Unitário',
  'register value considering all incident taxes': '(Cadastrar proposta considerando todos os impostos incidentes sobre o produto)',
  'register proposal': 'Cadastrar Proposta',
  'send proposal': 'Enviar Proposta',
  'exclude': 'Excluir',
  'proposal': '{howMany, plural, one {Proposta} other {Propostas}}',
  'observation': '{howMany, plural, one {Observação} other {Observações}}',
  'there is {items} with no cfa value registered, they will be saved as 0, are you sure to continue?': 'Existem {items} endereços de entrega com Frete CFA sem valor, os mesmos serão salvos como (R$ 0,00), tem certeza que deseja continuar?',
  'hour': 'Hora',
  'franchise value': 'Valor da Franquia',
  'coverage value': 'Valor da Cobertura',
  'sale value': 'Receita de Venda de Ativo Imobilizado',
  'Error while creating integration': 'Erro ao criar integração.',
  'all conversations': 'Todas conversas',
  'Please appoint another admin to the group before leaving':'Favor nomear um outro administrador ao grupo antes de sair',
  'Please appoint an admin to the group before leaving':'Favor nomear outro administrador ao grupo antes de sair',
  'hours': 'Horas',
  'freight type': 'Tipo de Frete',
  'visit type': 'Tipo de Visita',
  'cfa freight cost': 'Frete - CFA',
  'total cfa freight cost': 'Frete Total - CFA',
  'year': '{howMany, plural, one {Ano} other {Anos}}',
  'delivery only by CIF': 'Entrega somente CIF;',
  'must cover {this} of the demand': 'Deve atender {this} da demanda;',
  'bills will be generated only after a success delivery on modality CIF, except when the credit analysis is refused, or after all cooperatives agreement when FOB':
    'A geração do boleto será permitida após a confirmação de entrega de todas as cooperativas na modalidade CIF, exceto para aquelas que tiveram a análise de crédito recusada, ou após aceite das cooperativas quando FOB.',
  'except when the credit analysis is refused':' exceto para aquelas que tiveram a análise de crédito recusada.',
  'are you sure you want to refuse the order? You cannot join it anymore':
    'Tem certeza que deseja rejeitar esta demanda? Você não poderá cadastrar mais proposta na mesma.',
  'you have no internet connection. Entries made and not yet synchronized may be lost. Do you really want to leave now?':
    'Você está sem conexão com internet. Os cadastros realizados e ainda não sincronizados poderão ser perdidos. Deseja realmente sair agora?',
  '{this} refused':
    '{this} {gender, select, male {recusado} female {recusada}}',
  'all proposals were rejected': 'Todas as propostas foram recusadas',
  'the proposal from {this} was accepted': 'A proposta de {this} foi aceita',
  'are you sure you want to reject all proposals?':
    'Tem certeza que deseja recusar todas as propostas?',
  'are you sure you want to choose this form of payment':
    'Tem certeza que deseja escolher esta forma de pagamento?',
  'are you sure you want to reject the best proposal':
    'Tem certeza que deseja recusar a melhor proposta?',
  'this action can not be undone': 'Esta ação não poderá ser desfeita.',
  'proposal in relation to total demand': 'Proposta em relação à demanda total',
  'no results found': 'Nenhum resultado encontrado',
  'add tags': 'Adicionar tags',
  'add signature': 'Adicionar assinatura',
  'The protocol can not be deleted': 'O protocolo não pode ser deletado.',
  'no signature added': 'Nenhuma assinatura adicionada',
  'we were unable to post your message now. But don\'t worry, as we will publish it as soon as possible.':
    'Não foi possível publicar a sua mensagem agora. Mas não se preocupe, pois iremos publicá-la assim que possível.',
  'display indicators': 'Exibir Indicadores',
  'hide indicators': 'Ocultar Indicadores',
  'view more': 'Ver mais',
  'climate': 'Tempo',
  'climate event': 'Evento Climático',
  'stage': 'Estágio',
  'number of plants per meter': 'Número de plantas p/m',
  'period': 'Período',
  'start date': 'Data Inicial',
  'select the reason for the discharge': 'Selecione o motivo da baixa',
  'your discharge was registered': 'Sua baixa foi registrada',
  'end date': 'Data Final',
  'download': 'Download',
  'indicator': '{howMany, plural, one {Indicador} other {Indicadores}}',
  'humidity': 'Umidade (%)',
  'humidity percentage': 'Umidade',
  'inundation': 'Inundação',
  'flowRate': 'Vazão (m³/s)',
  'flow rate': 'Vazão',
  'irrigation final date': 'Data final da irrigação',
  'irrigationFinalDate': 'Data final da irrigação',
  'Cannot read properties of undefined (reading \'status\')': 'Não foi possível realizar o login, verifique sua conexão e tente novamente.',
  'wind': 'Vento',
  'precipitation': 'Precipitação',
  'hourly forecast': 'Previsão Hora em Hora',
  'the proposal was accepted': 'A proposta foi aceita',
  'uf': 'UF',
  'headquarter': '{howMany, plural, one {Matriz} other {Matrizes}}',
  'branch/unit': 'Filial/Unidade',
  'active register?': 'Cadastro ativo?',
  'organization': '{howMany, plural, one {Cooperativa} other {Cooperativas}}',
  'organization registration': 'Cadastro de Cooperativa',
  'state registration': 'Inscrição Estadual',
  'company name': 'Razão social',
  'reason': 'Razão',
  'reason ': 'Motivo',
  'inform the reason of the refusal': 'Informe o motivo da recusa.',
  'your access was removed': 'Seu acesso foi removido!',
  'when confirming the removal of the access you will have your access polices updated, do you want to continue?': 'Ao confirmar a remoção dos acessos você terá suas políticas de acesso atualizadas. Deseja continuar?',
  'trade name': 'Nome fantasia',
  'are you sure you want to register this integration?': 'Você tem certeza que deseja registrar essa integração?',
  'integration succeed': 'Integração realizada com sucesso.',
  'integration active': 'Integração ativa.',
  'integrate': 'Integrar',
  'integrated': 'Integrado',
  'company document': 'CNPJ',
  'cep': 'CEP',
  'inactive fiscal figure': 'Figura Fiscal Inativa',
  'user data': 'Dados de usuário',
  'state registration data': 'Dados da Inscrição Estadual',
  'organization data': 'Dados da Cooperativa',
  'User does not have organization': 'Sua conta foi desativada. Para reativá-la entre em contato com sua cooperativa.',
  'archive': '{howMany, plural, one {Arquivo} other {Arquivos}}',
  'address and complements': 'Endereço e Complementos',
  'no address registered': 'Nenhum endereço cadastrado',
  'register address': 'Cadastrar endereço',
  'complete registration': 'Concluir cadastro',
  'units': 'unidades',
  'launch date': 'Data de Lançamento',
  'report date': 'Data do Laudo',
  'laboratory': 'Laboratório',
  'due date': 'Data de Vencimento',
  'list of organizations': 'Listagem de Cooperativas',
  'register': 'Cadastrar',
  'register lots': 'Cadastrar Lotes',
  'subsidiary': 'Filial',
  'balance': 'Saldo',
  'balance in sacks': 'Saldo em sacas',
  'sack': '{howMany, plural, one {Saca} other {Sacas}}',
  'confirm receipt': 'Confirmar recebimento',
  'receipt confirmed': 'Recebimento confirmado',
  'non-compliance': 'Não conformidade',
  'are you sure you want to submit this form': 'Você tem certeza que deseja enviar este formulário?',
  'received product': 'Produto recebido',
  'it is not possible to exclude fields with registered cultivation':
    'Não é possível excluir talhões com cultivo cadastradado',
  'if everything is right with your product, select "confirm receipt"':
    'Caso esteja tudo certo com seu produto, selecione “confirmar recebimento”',
  'if you have any non-compliance, click the "non-compliance" button':
    'caso tenha alguma não conformidade, clique no botão “não conformidades”',
  'update / shipping': 'Atualização / envio',
  'select one interval': 'Selecione um intervalo',
  'field timeline': 'Linha do tempo do talhão',
  'generated by': 'Gerado por',
  'timeline': 'Linha do tempo',
  'are you sure you want to confirm the receivement of this purchase?':
    'Você tem certeza de que deseja confirmar o recebimento desta compra?',
  'your property and digital cooperative!':
    'Sua propriedade e cooperativa digital!',
  'register management': 'Cadastrar Manejo',
  'management': 'Manejo',
  'new management': 'Novo Manejo',
  'the cultivation have been completed': 'O cultivo foi encerrado!',
  'management register': 'Cadastro de Manejo',
  'concluded management': 'Manejo Concluído',
  'no management registered': 'Nenhum manejo cadastrado',
  'cropManagement': '{howMany, plural, one {Manejo} other {Manejos}}',
  'seeding': 'Semeadura',
  'seeding density': 'Densidade de semeadura',
  'seedingDensity': 'Densidade (kg/ha)',
  'seed treatment': 'Tratamento de Sementes',
  'seeding date': 'Data de Semeadura',
  'harvest date': 'Data de Colheita',
  'Error while creating crop management': 'Erro ao criar manejo',
  'cicle (days)': 'Ciclo (dias)',
  'fertilization': 'Fertilização',
  'phytosanitary application': 'Aplicação fitossanitária',
  'operation': 'Operação',
  'ration': 'Ração',
  'copy registry': 'Copiar lançamento',
  'select the desired month year to copy': 'Selecione o ano/mês desejado para copiar.',
  'operation 365': 'Operação 365',
  'operation 365 - evaluation form': 'Operação 365 - Formulário de Avaliação',
  'Only one registration with \"waiting approval\" or \"pending document\" or \"active\" status per field is allowed.': 'Apenas um registro \"Aguardando aprovação\", \"Pendente de documentos\" ou \"Ativo\" por talhão é permitido.',
  'not qualified': 'Não qualificado',
  'platinum': 'Platinum',
  'gold': 'Gold',
  'black': 'Black',
  'carbon': 'Carbon',
  'Insert a future expiration date.': 'A data de validade deve estar em um período futuro.',
  'evaluation form': 'Formulário de Avaliação',
  'form': 'Formulário',
  'cultivate': 'Cultivar',
  'you are not accredited': 'Você não está credenciado!',
  'accredited until': 'Credenciado até',
  'certifications': 'Certificados',
  'request accreditment': 'Solicitar Credenciamento',
  'accept accreditment': 'Aceitar Credenciamento',
  'edit accreditment': 'Editar Credenciamento',
  'Only animals in protocol can be deleted': 'Apenas animais em protocolo podem ser deletados.',
  'Only one registration with \"waiting approval\" status per field is allowed.': 'Apenas um registro \"Aguardando Aprovação\" por talhão é permitido.',
  'accreditment accepted': 'Credenciamento aceito',
  'accreditment edited': 'Credenciamento editado',
  'request date': 'Data de Solicitação',
  'evaluation date': 'Data de Avaliação',
  'last evaluation': 'Última Avaliação',
  'do you really want to request the accreditment': 'Você realmente quer solicitar o credenciamento?',
  'accreditment requested': 'Credenciamento solicitado',
  'approving date': 'Data de Aprovação',
  'requesting date': 'Data de Requisição',
  'accreditments': 'Credenciamentos',
  'accreditment': 'Credenciamento',
  'view accreditment': 'Ver Credenciamento',
  'questionaries': 'Questionários',
  'technical evaluation': 'Avaliação Técnica',
  'recommendations': 'Recomendações',
  'register recommendation': 'Cadastrar Recomendação',
  'edit recommendation': 'Editar Recomendação',
  'there is no observation registered for this item': 'Não há observação registrada para esse item.',
  'questionary': 'Questionário',
  'all crops must be the same for operation seeding': 'Esta operação não permite a seleção de talhões com diferentes culturas.',
  'number of plants per linear meter': 'Número de plantas por metro linear',
  'replanting?': 'Replantio?',
  'line spacing': 'Espaçamento entre linhas',
  'germination': 'Germinação (%)',
  'vigor': 'Vigor (%)',
  'dose': 'Dose',
  'application mode': 'Modo de aplicação',
  'type of application': 'Tipo de aplicação',
  'There is not asset to be calculated': 'Não há ativos para serem calculados.',
  'coverage': 'Cobertura',
  'data updated the last time at': 'Dados atualizados pela última vez em',
  'no recommendations found': 'Nenhuma recomendação encontrada.',
  'base': 'Base',
  'haul': 'Lanço',
  'line': 'Linha',
  'fill in a value between 0 and 100': 'Preencha com um valor entre 0 e 100',
  'harvest': 'Colheita',
  'productivity': 'Produtividade',
  'unit': 'Unidade',
  'whole grain': 'Grão inteiro',
  'shell and bran': 'Casca e farelo',
  'irrigation plates': 'Lâmina (milímetros)',
  'type': 'Tipo',
  'superficial': 'Superficial',
  'sprinkler': 'Aspersor',
  'center pivot': 'Pivô central',
  'central coordinates': 'Coordenadas Centrais',
  'average field point': 'Ponto Médio do Talhão',
  'print': 'Imprimir',
  'display animals already slaughtered': 'Exibir animais já baixados',
  'hay production': 'Produção de feno',
  'number of bales/rolls per ha': 'Número de fardos/rolos por ha',
  'Bale / hay roll weight': 'Peso do fardo/rolo de feno',
  'grazing': 'Pastejo',
  'Only waiting approval accreditations can be approved.': 'Apenas credenciamentos aguardando aprovação podem ser aprovados',
  'grazing time period': 'Período de tempo de pastejo',
  'number of animals': 'Número de animais',
  'register animal': 'Registro {howMany, plural, one {do} other {dos}} {howMany, plural, one {animal} other {animais}}',
  'other': '{howMany, plural, one {Outro} other {Outros}}',
  'description': 'Descrição',
  'vaccines and medications': 'Vacinas e Medicamentos',
  'silage production': 'Produção de silagem',
  'dryMatterContent': 'Teor de Matéria Seca (%)',
  'dryMatter': 'Matéria Seca (t/ha)',
  'relativeFoodValue': 'Valor Relativo do Alimento (l/t)',
  'estimatedProduction': 'Produção Estimada (l/ha)',
  'dry matter content': 'Teor de Matéria Seca',
  'dry matter': 'Matéria Seca',
  'relative food value': 'Valor Relativo do Alimento',
  'estimated production': 'Produção Estimada',
  'production': 'Produção',
  'products': 'Produtos',
  'expected date': 'Data prevista para realização',
  'kg/ha': 'kg/ha',
  'g/ha': 'g/ha',
  'bags/ha': 'sacas/ha',
  'ph': 'ph',
  'complete operation': 'Concluir operação',
  'forecast for realization date': 'Previsão para data de realização',
  'max': 'Máx',
  'min': 'Min',
  'numberOfPlants': 'N° de plantas',
  'lineSpacing': 'Entrelinha (cm)',
  'replanting': 'Replantio?',
  'not_qualified': 'Não qualificado',
  'n': 'N (%)',
  'p': 'P (%)',
  'k': 'K (%)',
  's': 'S (%)',
  'ca': 'Ca (%)',
  'mg': 'Mg (%)',
  'zn': 'Zn (%)',
  'bo': 'Bo (%)',
  'applicationMode': 'Modo de aplicação',
  'typeOfApplication': 'Tipo de aplicação',
  'productGroup': 'Grupo de produtos',
  'irrigationPlates': 'Lâmina (milímetros)',
  'typeOfIrrigation': 'Tipo',
  'dosePhyntosanitary': 'Dose',
  'unitProductivity': 'Unidade de produção',
  'volume date': 'Data Volume',
  'temperature': 'Temperatura',
  'dimension': 'Dimensão',
  'battery': 'Bateria',
  'telemetry': 'Telemetria',
  'offline equipment': 'Equipamento offline',
  'there is no producers downloaded in offline mode': 'Não há produtores na sua carteira ou baixados no modo offline',
  'there is no producers on your portfolio': 'Não há produtores na sua carteira',
  'your visit was effected': 'Sua visita técnica foi efetivada.',
  'no evaluation registered for this form': 'Não há avaliação registrada para esse questionário.',
  'precipitation data': 'Dados de precipitação',
  'exercise liquid result': 'RESULTADO LÍQUIDO DO EXERCÍCIO',
  'air temperature': 'Temperatura do ar',
  'air humidity': 'Umidade do ar',
  'wind speed': 'Velocidade do vento',
  'wind direction': 'Direção do vento',
  'direction': 'Direção',
  'average solar radiation': 'Radiação solar média',
  'gust of wind': 'Rajada de vento',
  'data': 'Dados',
  'condemned volume': 'Volume Condenado',
  'depending on approval': 'Dependendo de Aprovação',
  'definitive': 'Definitivo',
  'productivityKg': 'kg/ha',
  'productivityBags': 'sacas/ha',
  'wholeGrain': 'Grão inteiro (%)',
  'brokenGrain': 'Grãos quebrados (%)',
  'broken grain': 'Grãos quebrados',
  'impurity': 'Impureza (%)',
  'impurity percentage': 'Impureza',
  'germinationProductivity': 'Germinação (%)',
  'shellAndBran': 'Casca e farelo (%)',
  'productionSilage': 'Produção (t/ha)',
  'balesRollsPerHa': 'Número de fardos/rolos',
  'update code': 'Atualizar Código',
  'do you really want to update your contact code?': 'Você realmente deseja atualizar seu código de contato?',
  'numberOfAnimals': 'Número de animais',
  'no abcense registered': 'Nenhuma ausência cadastrada.',
  'grazingTimePeriod': 'Tempo de pastejo',
  'balance in kg': 'Saldo em kg',
  'titles to receive': 'Títulos a receber',
  'titles to pay': 'Títulos a pagar',
  'show by': 'Exibir por',
  'transaction date': 'Data da Transação',
  'rain': 'Chuva',
  'release': 'Lançar',
  'millimeters': 'Milímetros',
  'recenter': 'Recentralizar',
  'crops management ended': 'Manejos concluídos',
  'machinery': 'Máquinas',
  'machine': 'Máquina',
  'sell': 'Venda',
  'service': 'Prestação de Serviço',
  'rent': 'Locação',
  'none': 'Nenhum',
  'crops management scheduled': 'Manejos agendados',
  'register schedule': 'Cadastrar agendamento',
  'schedulement': 'Agendamento',
  'schedulement data': 'Dados do Agendamento',
  'schedule': 'Agendar',
  'new visit': 'Nova Visita',
  'attach documents': 'Anexar Documentos',
  'add documents': 'Adicionar Documentos',
  'add file': 'Adicionar Arquivo',
  'SUPPLIER': 'Fornecedor',
  'TECHNICIAN': 'Técnico',
  'PRODUCTOR': 'Produtor',
  'FAMILY_MEMBER': 'Membro Familiar',
  'BUYER': 'Comprador',
  'ADMIN': 'Administrador',
  'SMARTCOOP': 'Smartcoop',
  'digitalProperty': 'Propriedade Digital',
  'administration': 'Administrativo',
  'view documents': 'Visualizar Documentos',
  'freight observation': 'Observação de frete',
  'view participation term': 'Visualizar termo de participação',
  'view accreditment certificate': 'Visualizar termo de participação',
  'are you sure you want to delete the machine?': 'Tem certeza que deseja excluir a máquina?',
  'no file': 'Sem arquivos',
  'no files added': 'Nenhum arquivo adicionado',
  'technical dashboard': 'Dashboard Técnico',
  'doc': '{howMany, plural, one {Documento} other {Documentos}}',
  'weather forecast': 'Previsão do Tempo',
  'weather data': 'Dados Meteorológicos',
  'productGroupSeeding': 'Grupo de produtos',
  'date for realization': 'Data para realização',
  'sales orders': 'Ordens de Venda',
  'date': '{howMany, plural, one {Data} other {Datas}}',
  'total': 'Total',
  'group': 'Grupo',
  'broadcast': 'Transmissão',
  'search contacts': 'Pesquisar contatos',
  'enter the group name':'Digite o nome do grupo',
  'enter the broadcast name':'Digite o nome da lista de transmissão',
  'status': 'Status',
  'participation status': 'Status de participação',
  'profile picture': 'Foto de perfil',
  'Embryo bull and embryo mother are mandatory in TE type': 'Os dados do embrião no tipo de inseminação TE são obrigatórios',
  'You can not use an external bull in Monta type': 'Você não pode usar um touro externo no tipo de inseminação Monta',
  'You can not inseminate a bull': 'Você não pode inseminar um touro',
  'inseminate': 'Inseminar',
  'start milking': 'Iniciar ordenha',
  'swipe to see the rest': 'Deslize para ver o restante',
  'forecast for the next 15 days': 'Previsão para os próximos 15 dias',
  'decree': 'Decreto',
  'register machinery': 'Cadastrar máquina',
  'report occurrence': 'Reportar Ocorrência',
  'success on importing animals': 'Animais importados com sucesso!',
  'success on importing milk control': 'Controle leiteiro importado com sucesso!',
  'message: Insemination is not allowed for this category of animals.': 'Inseminação não é possível para essa categoria de animal!',
  'row:': 'Linha:',
  'edit occurrence': 'Editar Ocorrência',
  'click and drag the pin to the desired location': 'Clique e arraste o pin até o local desejado',
  'available': 'Disponível',
  'available for': 'Disponibilidade para',
  'unavailable': 'Indisponível',
  'Invalid state registration': 'Inscrição estadual inválida',
  'brand': 'Marca',
  'model': 'Modelo',
  'report': 'Reporte',
  'new property': 'Nova Propriedade',
  'depth': 'Profundidade',
  'ha': 'ha',
  'activity': '{howMany, plural, one {Atividade} other {Atividades}}',
  'no activity registered': 'Nenhuma atividade cadastrada',
  'property costs by activity': 'Custos da propriedade / atividade',
  'edit property': 'Editar Propriedade',
  'create group': 'Criar grupo',
  'create broadcast': 'Criar transmissão',
  'edit profile': 'Editar perfil',
  'profile/registration': 'Matrícula/Perfil',
  'delete property': 'Excluir propriedade',
  'next action': 'Próxima ação',
  'next action date': 'Data da próxima ação',
  'options': 'Opções',
  'select at least one option': '* Selecione ao menos uma das opções',
  'your property was edited': 'Sua propriedade foi editada com sucesso.',
  'property edition': 'Edição de propriedade',
  'the number of files exceeds the limit of {this}': 'O número de arquivos excede o limite de {this}',
  'the size of one or more files exceeds {this}': 'O tamanho de um ou mais arquivos excede {this}',
  'please to follow with your removel of the access on {this}, fill the form below':'Por favor, para seguir com a remoção de seus acessos na {this}, preencha o formulário abaixo:',
  'disable account': 'Inativar conta',
  'are you sure that you want to remove your access to SMARTCOOP': 'Você tem certeza que deseja desativar a conta SMARTCOOP?',
  'best proposal': 'Melhor Proposta',
  'all machines': 'Todas máquinas',
  'search machines': 'Buscar máquinas',
  'rental machines': 'Máquinas para locação',
  'rental availability': 'Disponibilidade de locação',
  'occurrence': 'Ocorrência',
  'occurrences': 'Ocorrências',
  'occurrence type': 'Tipo de Ocorrência',
  'occurrence date': 'Data de Ocorrência',
  'observations': 'Observações',
  'specification': 'Especificação',
  'insemination': 'Inseminação',
  'pregnancy': 'Prenhez',
  'pregnant': 'Prenha',
  'diagnostic pev': 'Diagnóstico de PEV',
  'drying': 'Secagem',
  'damage done': 'Dano Causado',
  'my machines': 'Minhas máquinas',
  'apply': 'Aplicar',
  'low': 'baixo',
  'high': 'alto',
  'my account': 'Minha conta',
  'select the data to display': 'Selecione os dados para exibição',
  'this operation allows you to finish the cultivation as well. Do you want to finish the management and cultivation?': 'Essa operação permite finalizar o cultivo também. Deseja finalizar o manejo e o cultivo?',
  'its crop management and cultivation have been completed': 'Seu manejo e seu cultivo foram concluídos',
  'its management has been completed': 'Seu manejo foi concluído',
  'scheduled to:': 'Previsto para:',
  'held in:': 'Realizado em:',
  'medication control': 'Controle de Medicamentos',
  'medicines': 'Medicamentos',
  'medicine': 'Medicamento',
  'treatment date': 'Período do Tratamento',
  'treatment interval': 'Intervalo do Tratamento',
  'treatment time': 'Tempo do Tratamento',
  'treatment mode': 'Modo do Tratamento',
  'treatmentMode': 'Modo do Tratamento',
  'carency time': 'Tempo de carência',
  'carency liberation date': 'Liberação de carência',
  'css before birth': 'Último resultado CCS',
  'css after birth': 'CCS Pós Parto',
  'treatment start date': 'Data inicial do Tratamento',
  'treatment end date': 'Data final do Tratamento',
  'held': 'Realizado',

  'complete': 'Concluir',
  'what was the date of realization?': 'Qual foi a data de realização?',
  'want to end cultivation?': 'Deseja encerrar o cultivo?',
  'end cultivation': 'Encerrar cultivo',
  'who is signing': 'Quem está assinando?',
  'register plan': 'Cadastrar Planejamento',
  'the cultivation has been started': 'O cultivo foi iniciado!',
  'missing current field': 'O talhão atual deve ser mantido nessa edição.',
  'are you sure you want to finalize your cultivation?': 'Você tem certeza que deseja finalizar seu cultivo?',
  'are you sure you want to start your cultivation?': 'Você tem certeza que deseja iniciar seu cultivo?',
  'new order': 'Nova Ordem',
  'This product quotation has expired': 'A cotação desse produto está expirada',
  'product value': 'Valor do Produto',
  'total value': 'Valor Total',
  'proportional value': 'Valor Proporcional',
  'value by ha': 'Valor/ha',
  'value by dose': 'Valor/dose',
  'valueDose': 'Valor do Produto',
  'total cost': 'Custo Total',
  'cost by ha': 'Custo/ha',
  'cost by': 'Custo por',
  'total value in current quotation': 'Valor Total na Cotação atual',
  '{this} of': '{this} de',
  'the {this}': '{gender, select, male {o {this}} female {a {this}}}',
  'no image added': 'Nenhuma imagem adicionada',
  'are you sure you want to remove this image': 'Você tem certeza que quer remover essa imagem?',
  'are you sure you want to remove this file': 'Você tem certeza que quer remover esse arquivo?',
  'add images': 'Adicionar imagens',
  'add files': 'Adicionar arquivos',
  'replace images': 'Substituir imagens',
  'add videos': 'Adicionar vídeos',
  'upcoming': '{gender, select, male {Futuro} female {Futura}}',
  'trigger': 'Gatilho',
  'remove access': 'Remover acesso',
  '{this} in {that}': '{this} em {that}',
  'target price': 'Preço alvo',
  'settlement date': 'Data de Liquidação',
  'list of available lots': 'Lista de lotes disponíveis',
  'list of inputs': 'Lista de Insumos',
  'cooperative quotation': 'Cotação da cooperativa',
  'too cloudy day': 'Imagem com excesso de núvens',
  'rain record': 'Registro de Chuva',
  'rain map': 'Mapa de Chuva',
  'rain records': 'Registros de chuva',
  'register rain': 'Registrar chuva',
  'rain quantity': 'Volume de chuva',
  'register for': 'Registrar para',
  'deliveries registers': 'Registros de Entregas',
  'select field': 'Selecione talhão',
  'select desired field': 'Selecione o talhão desejado',
  'rain record success': 'Registro de chuva incluído!',
  'rain detail date': 'Data',
  'rain detail record' : 'Registro de',
  'rain detail register for': 'Registrado para',
  'frost': 'Geada',
  'ocs': 'OCS',
  'milk volume': 'Volume de Leite',
  'hail': 'Granizo',
  'delete rain record': 'Excluir',
  'You can\'t change this organization type': 'Você não pode mudar o tipo da cooperativa',
  'You can\'t change org Heaquarter/Subsidiary': 'Você não pode alterar a matriz',
  'You can\'t change organization unity': 'Você não pode alterar o tipo de filial/unidade',
  'You can only change organization that you are logged in to': 'Você só pode alterar a cooperativa que está logado',
  'Subsidiary cant create new organization': 'Filiais não podem cadastrar cooperativas',
  'You cant create this organization type': 'Você não pode criar este tipo de cooperativa',
  'You can only create an affiliate for the org you are logged in to': 'Você só pode criar filiais para a cooperativa que está logado',
  'You can only delete org that you are admin': 'Você só pode deletar a cooperativa que é administrador',
  'You can only delete an affiliate for the org you are logged in to': 'Você só pode deletar filiais da cooperativa que está logado',
  'edit rain record': 'Editar',
  'rain record edit success': 'Registro de chuva editado!',
  'rain record delete success': 'Registro de chuva excluído!',
  'your report was deleted!': 'Sua ocorrência foi excluída!',
  'your report was registered!': 'Sua ocorrência foi registrada.',
  'your report was edited!': 'Sua ocorrência foi editada.',
  'register last amount paid': 'Registrar último valor pago',
  'inform price paid': 'Informar valor pago',
  'enter a price paid for the purchase of this product': 'Informe um valor pago na compra nesse produto',
  'date of purchase': 'Data da compra',
  'unit price': 'Preço unitário',
  'see paid price history': 'Ver histórico de preços pagos',
  'see history': 'Ver histórico',
  'paid price history': 'Histórico de preços pagos',
  'field history': 'Histórico do talhão',
  'fields history': 'Histórico de talhões',
  'there are no closed growing seasons': 'Não há safras encerradas',
  'download geotiff': 'Baixar imagem GeoTiff',
  'see all': 'Ver todas',
  'select all': 'Selecionar todos',
  'select conversation': 'Selecionar conversa(s)',
  'favorite conversation': 'Favoritar conversa(s)',
  'delete conversation': 'Excluir conversa(s)',
  'check all as read': 'Marcar todas como lidas',
  'credit limit': 'Limite de Crédito',
  'renovation': 'Renovação',
  'social comments': 'Comentários',
  'social cooperates': 'Cooperando',
  'social comment': 'Comentar',
  'social modal title': 'Comentar na publicação de:',
  'social insert comment': 'Insira seu comentário',
  'social comment edit': 'Editar comentário',
  'social comment delete': 'Excluir comentário',
  'social comment post success': 'Comentário incluído com sucesso!',
  'social comment edit success': 'Comentário editado com sucesso!',
  'social comment delete success': 'Comentário excluído com sucesso!',
  'social publication': 'Publicação',
  'publication date': 'Data da publicação',
  'likes': 'Curtidas',
  'views': 'Visualizações',
  'files': 'Arquivos',
  'file': 'Arquivo',
  'keywords': 'Palavras-Chave',
  'available for producer': 'Disponível para produtores',
  'list of product groups': 'Listagem de Grupos de Produtos',
  'electronic signature': 'Assinatura eletrônica',
  'by completing the transaction via electronic signature, you are agreeing to the terms of use of the sales order': 'Ao efetivar a transação através da assinatura eletrônica, você estará concordando com os termos de uso da ordem de venda',
  'terms of use': 'Termos de Uso',
  'terms of use and privacy': 'Termos de uso e privacidade',
  'insert your electronic signature here': 'Insira sua assinatura eletrônica aqui:',
  'electronic confirmation': 'Confirmação eletrônica',
  'invalid electronic signature': 'Assinatura eletrônica inválida',
  'success validate electronic signature': 'Assinatura eletrônica validada com sucesso',
  'products list': 'Listagem de produtos',
  'id': 'Id',
  'unitOfMeasure': '{howMany, plural, one {Unidade de medida} other {Unidades de medida}}',
  'productCode': 'Código do Produto',
  'pre-registration of products': 'Pré cadastro de Produtos',
  'shortDescription': 'Descrição resumida',
  'ncm': 'NCM',
  'ean': 'Código EAN',
  'dun14': 'Código DUN14',
  'attribute': 'Atributo',
  'attribute value': 'Valor do atributo',
  'erosion type': 'Tipo de Erosão',
  'Error while updating crops management': 'Erro ao atualizar o manejo',
  'error while updating crops management': 'Erro ao atualizar o manejo',
  'Error while updating crop management': 'Erro ao atualizar o manejo',
  'Error while creating crops management': 'Erro ao criar o manejo',
  'error while creating crop management': 'Erro ao criar o manejo',
  'Error while creating technical visit': 'Erro ao criar visita técnica',
  'Error while updating technical visit': 'Erro ao editar visita técnica',
  'phenological stage': 'Estágio Fenológico',
  'phenologicalStage': 'Estágio Fenológico',
  'create attribute': 'Cadastrar novo atributo',
  'insert attribute and value': 'Preencha atributo e valor!',
  'product group registration': 'Cadastro de Grupo de Produtos',
  'product group pre-registration': 'Pré cadastro de Grupo de produto',
  'add crops': 'Adicionar Culturas',
  'search and add': 'Buscar e adicionar',
  'create non compliance': 'Cadastrar não conformidade',
  'insert non compliance': 'Insira a não conformidade',
  'insert non compliance answer': 'Insira a resposta',
  'send non compliance': 'Enviar',
  'send image': 'Enviar Imagem',
  'send video': 'Enviar Vídeo',
  'send audio': 'Enviar Áudio',
  'non compliance title': 'Não conformidade',
  'non compliance answer': 'Responder',
  'non compliance answers': 'Respostas',
  'non compliance treat as resolved': 'Marcar como resolvido',
  'non compliance cancel': 'Cancelar',
  'non compliance resolved modal' : 'Tem certeza que deseja marcar como resolvido esta não conformidade?',
  'non compliance': 'Não conformidade',
  'non compliance resolved success': 'Não conformidade resolvida!',
  'non compliance create success': 'Não conformidade criada com sucesso!',
  'zero non compliances': 'Não existem não conformidades para essa demanda',
  'technical visit': 'Visita técnica',
  'register analysis': 'Registros de Análises',
  'visit mode': 'Modo de visita',
  'sample depth': 'Profundidade de amostragem',
  'sample date': 'Data de amostragem',
  'visited fields': 'Talhões visitados',
  'evaluations - visited fields': 'Avaliações - Talhões visitados',
  'evaluation - visited fields': 'Avaliação - talhões visitados',
  'quality registers': 'Registros de Qualidade',
  'register technical visit': 'Cadastrar visita técnica',
  'are you sure you want to register this technical visit': 'Você  tem certeza que deseja cadastrar essa visita técnica?',
  'are you sure you want to register this schedulement': 'Você  tem certeza que deseja cadastrar esse agendamento?',
  'are you sure you want to edit this technical visit': 'Você  tem certeza que deseja editar essa visita técnica?',
  'are you sure you want to edit this schedulement': 'Você  tem certeza que deseja editar esse agendamento?',
  'productivity estimate': 'Estimativa de produtividade',
  'estimate productivity': 'Produtividade estimada',
  'estimate in': 'Estimativa em',
  'notify the producer about the realization of this visit': 'Notificar o produtor da realização da visita',
  'notify the producer about the schedulement': 'Notificar o produtor sobre o agendamento',
  'data of field': 'Dados do talhão',
  'list of technical visits': 'Lista de visitas técnicas',
  'price registers': 'Registros de Preço',
  'price data': 'Dados de Preço',
  'monthly apportionment': 'Rateio mensal',
  'month': '{howMany, plural, one {Mês} other {Meses}}',
  'animal': '{howMany, plural, one {Animal} other {Animais}}',
  '{this} name': 'Nome {gender, select, male {do} female {da}} {this}',
  '{this} code': 'Código {this}',
  'gender': 'Gênero',
  'weight': 'Peso',
  'maternal grandfather': 'Avô Materno',
  'great grandmother': 'Bisavô',
  'expected date calving': 'Data prevista',
  'last calving': 'Último parto',
  'do you want to register an animal?': 'Você deseja registrar um novo animal?',
  'Realization date must be after insemination date': 'A data de realização deve ser depois da data de inseminação',
  'Confirmation touch date must be after first touch date': 'A data do toque de confirmação deve ser depois da data do primeiro toque',
  'Drying touch date must be after confirmation touch date': 'A data do toque de secagem deve ser depois da data do toque de confirmação',
  'register calving': 'Registrar parto',
  'insemination predicted date': 'Data prevista de Inseminação',
  'calving': 'Parto',
  'date of discharge': 'Data da baixa',
  'discharge value': 'Valor da baixa',
  'register discharge': 'Registrar baixa',
  'inform the reason of the discharge': 'Informe o motivo da baixa',
  'pev': 'PEV',
  'predominant race': 'Raça predominante',
  'race': 'Raça',
  'price registration': 'Cadastro de Preço',
  'initial price': 'Preço Inicial',
  'final price': 'Preço Final',
  'initial month/year': 'Mês/Ano Inicial',
  'final month/year': 'Mês/Ano Final',
  'delivery': '{howMany, plural, one {Entrega} other {Entregas}}',
  'third company': '{howMany, plural, one {Empresa Terceirizada} other {Empresas Terceirizadas}}',
  'go to': 'Ir para',
  'necessary to reenter technical module': 'Necessário reentrar no módulo técnico',
  'to change the commercialization company name, return to the inital panel': 'Para alterar o nome da empresa de comercialização, retorne ao painel inicial do tambo.',
  'no price data registered': 'Nenhum dados de preço cadastrado',
  'technical area': 'Área do técnico',
  'technical': 'Técnico',
  'load offline': 'Carregar Offline',
  'list not available offline': 'Listagem não disponível offline',
  'processing term sign': 'Processando a assinatura do termo de adesão...',
  'creating register': 'Realizando seu cadastro...',
  'producer portfolio': 'Carteira de produtores',
  'comeback to technical area': 'Voltar para a Área do Técnico',
  'request permission': 'Solicitar permissão',
  'not requested': 'Não solicitado',
  'requested': 'Solicitado',
  'denied': 'Negado',
  'of the period': 'do período',
  'impacted users': 'Usuários impactados',
  'view only': 'Apenas visualização',
  'viewing and editing': 'Visualização e edição',
  'view': 'Visualizar',
  'view short': 'Ver',
  'producer groups': 'Grupos de Produtores',
  'your group was created': 'Seu grupo foi criado.',
  'your group was updated': 'Seu grupo foi atualizado.',
  'your group was deleted': 'Seu grupo foi deletado.',
  'register group': 'Cadastrar grupo',
  'edit group': 'Editar grupo',
  'sexing': 'Sexado',
  'revoked': 'Revogado',
  'register family access': 'Novo Acesso',
  'your access request has been sent': 'Sua solicitação de acesso foi enviada',
  'access request': '{howMany, plural, one {Solicitação} other {Solicitações}} de {howMany, plural, one {acesso} other {acessos}}',
  'your access has been removed': 'Seu acesso foi removido',
  'user access': 'Acesso de usuários',
  'access': '{howMany, plural, one {Acesso} other {Acessos}}',
  'configure permission': 'Configurar permissão',
  'user name': 'Nome do usuário',
  'technical/family': 'Técnico/Familiar',
  'permission': 'Permissionamento',
  'assistance only': 'Somente Assistência',
  'gestation period': 'Período de Gestação',
  'no product configuration suggested by the organization': 'Nenhuma configuração de produto sugerida pela cooperativa',
  'no subsidiary configuration suggested by the organization': 'Nenhuma configuração de filial sugerida pela cooperativa',
  'permission change performed successfully': 'alteração de permissão realizada com sucesso',
  'error while creating integration': 'Erro ao criar integração',
  'primary unit balance': 'Saldo Un. Primária',
  'secondary unit balance': 'Saldo Un. Secundária',
  'does not apply': 'Não aplica',
  'does not apply - short version': 'n/a',
  'there are no quoted products available for sale': 'Não existem produtos com cotação disponíveis para venda',
  'your organization has not informed you of any settlement date, please contact your organization': 'Sua cooperativa não informou nenhuma data de Liquidação, por favor entre em contato com sua cooperativa',
  'organization does not have lots for future sale': 'Cooperativa não dispõem de lotes para venda futura',
  'sale mode not available at the moment': 'Modalidade de venda não disponível no momento',
  'liters': 'Litros',
  'liter': 'Litro',
  'buy mode': 'Modalidade de compra',
  'minimum purchase guaranteed': 'As cooperativas reservam o direito de adquirir até 80% da demanda cadastrada. Portanto a proposta cadastrada deve ser válida para pelo menos 80% do volume agregado.',
  'list of producers': 'Lista de proprietários',
  'proprietary allow': 'Acesso Produtor',
  'supplier': '{howMany, plural, one {Fornecedor} other {Fornecedores}}',
  'supplier list': 'Listagem de Fornecedores',
  'select products': 'Selecionar produtos',
  'pre-registration of suppliers': 'Pré cadastro de Fornecedores',
  'exempt state registration': 'Isento Inscrição Estadual?',
  'edit supplier': 'Editar Fornecedor',
  'purchase type': 'Tipo de Compra',
  'scheduled': 'Agendado',
  'continuous': 'Contínuo',
  'product wall': 'Mural de Produtos',
  'type your post here': 'Digite aqui sua publicação.',
  'you have empty fields, do you want to register like this?': 'Você possuí campos vazios, deseja registrar assim?',
  'change decision': 'Alterar Decisão',
  'You already {this} this demand': 'Você já {this} essa demanda',
  'accepted': 'aceitou',
  'declined': 'recusou',
  'index': '{howMany, plural, one {Índice} other {Índices}}',
  'welcome': 'Bem vindo(a),',
  'finality and culture': 'Cultura e Finalidade',
  'scheduled management': 'Manejos agendados',
  'loading': 'Carregando...',
  'this equipment is disabled': 'Esse equipamento está desabilitado',
  'missing images on period': 'O período solicitado pode ter imagens faltantes.',
  'add image': 'Anexar imagens',
  'image search requested': 'Uma busca pelas imagens faltantes foi solicitada, pesquise o período novamente em alguns minutos.',
  'no found scheduled managements': 'Não há manejos agendados',
  'managements': 'Manejos',
  'This field have a open Growing Season and cannot be deleted': 'Este talhão possui cultivo ativo e não pode ser deletado.',
  'no actions planned': 'Sem ações previstas',
  'planned': 'Planejado',
  'planneds': 'Planejados',
  'feed consumption': 'Consumo de ração',
  'lactating cows': 'Vacas em lactação',
  'number of lactations': 'Nº Lactações',
  'lactose': 'Lactose',
  'css': 'CSS',
  'cow productivity': 'Produtividade da vaca',
  'concentrated milk ratio': 'Relação leite concentrado',
  'land productivity': 'Produtividade da terra',
  'milk surface': 'Superfície leite',
  'package': '{howMany, plural, one {Pacote} other {Pacotes}}',
  'fertilizer': '{howMany, plural, one {Fertilizante} other {Fertilizantes}}',
  'herbicide': '{howMany, plural, one {Herbicida} other {Herbicidas}}',
  'fungicide': '{howMany, plural, one {Fungicida} other {Fungicidas}}',
  'insecticide': '{howMany, plural, one {Inseticida} other {Inseticidas}}',
  'seed': '{howMany, plural, one {Semente} other {Sementes}}',
  'barter': 'Barter',
  'new {this}': '{gender, select, male {{howMany, plural, one {Novo} other {Novos}}} female {{howMany, plural, one {Nova} other {Novas}}}} {this}',
  'launch': 'Lançamento',
  'current organization is required': 'Obrigatório informar a cooperativa atual',
  'add product': 'Adicionar produto',
  'category': 'Categoria',
  'found items that still need to be filled, check the following category:': 'Foram encontrados itens que ainda precisam ser preenchidos, verifique {howMany, plural, one {a} other {as}} {howMany, plural, one {seguinte} other {seguintes}} {howMany, plural, one {categoria} other {categorias}}:',
  'select at least one {this}': 'Selecione ao menos {gender, select, male {um} female {uma}} {this}',
  'are you sure you want to remove from productivity challenge?': 'Tem certeza que deseja remover o Talhão do Concurso de Produtividade?',
  'package quantity': 'Quantidade pacotes',
  '{this}: {that}': '{this}: {that}',
  'delete': 'Excluir',
  'apply to fields': 'Aplicar aos talhões',
  'recommend': 'Recomendar',
  'recommended': 'Recomendado',
  'schedule to field': 'Agendar ao talhão',
  'apply to management': 'Aplicar recomendação aos talhões',
  'fields': 'Talhões',
  'history': 'Histórico',
  'delivery location': 'Local de Entrega',
  'the satellite images will be shown soon': 'As imagens de satélite serão exibidas em breve.',
  'my package': 'Meu pacote',
  'required field': 'Campo obrigatório',
  'field weather': 'Tempo no talhão',
  'base map': 'Mapa Base',
  'manual': 'Manual',
  'productivity in this unit': 'Produtividade nessa unidade',
  'crop used in trade': 'Cultura utilizada na troca',
  'it was not possible to calculate, check if the property has the activity {this}': 'Não foi possível calcular, verifique se a propriedade possui a atividade \'{this}\'',
  'there is no suficient data to calculate the indicators for this period': 'Não há dados suficientes para calcular este indicador no período selecionado.',
  '{this} is required': '{this} é {gender, select, male {obrigatório} female {obrigatória}}',
  'reports': 'Relatórios',
  'There is already a precipitation record for this date.': 'Em algum dos talhões selecionados já existe um registro de chuva para esta data.',
  'my productivity': 'Minha produtividade',
  'my productivity vs region': 'Minha produtividade vs Região',
  'my property': 'Minha propriedade',
  'my property vs region': 'Minha propriedade vs Região',
  'crm detec': 'Crm Detec',
  'end of season report': 'Relatório de encerramento de safra',
  'follow-up of season report': 'Relatório de acompanhamento de safra',
  'follow-up of season': 'Acompanhamento de safra',
  'technical visit report': 'Relatório de Visita Técnica',
  'create report': 'Gerar Relatório',
  'crm': 'CRM',
  'administrative dashboard': 'BI Administrativo',
  'shopping dashboard': 'BI Compras',
  'milk dashboard': 'BI Leite',
  'rtc dashboard': 'BI RTC',
  'direction dashboard': 'BI Direção',
  'benckmark': 'Benckmark',
  'earrings': 'Brincos',
  'benchmark': 'Benchmark',
  'grains': 'Grãos',
  'inputs': 'Insumos',
  'technical visits': 'Visitas Técnicas',
  'visits': 'Visitas',
  'detec': 'DeTec',
  'data inclusion will be done by the organization': 'A inclusão dos dados será feita pela sua cooperativa',
  'initial date': 'Data Inicial',
  'final date': 'Data Final',
  'the start date must be less than end': 'A data inicial deve ser menor que final',
  'filtered dates must be less than or equal to current': 'As datas filtradas devem ser menores ou igual a atual',
  'edit company': 'Editar Empresa',
  'update my data': 'Atualizar meus dados',
  'select one {this}': 'Selecione {gender, select, male {um} female {uma}} {this}',
  'payment form not selected': 'Modo de faturamento não selecionado pela cooperativa',
  'weather station': '{howMany, plural, one {Estação meteorológica} other {Estações meteorológicas}}',
  'station': '{howMany, plural, one {Estação} other {Estações}}',
  'select station': 'Selecione uma estação',
  'field details': 'Detalhes do talhão',
  'register date': 'Data registro',
  'ie not found': 'Inscrição estadual não encontrada na plataforma.',
  'completed': 'Concluído',
  'prepartum': 'Pré-Parto',
  'abortion': 'Aborto',
  'action': 'Ação',
  'There are animals allocated in this lot, you can not delete': 'Existem animais neste lote, não é permitido deletar',
  'other actions': 'Outras Ações',
  'accomplished date': 'Data realizada',
  'not accomplished': 'Não {gender, select, male {realizado} female {realizada}}',
  'diagnosis': '{howMany, plural, one {Diagnóstico} other {Diagnósticos}}',
  'type of diagnosis': 'Tipo de diagnóstico',
  'Unable to delete in current status.': 'Não é possível ser removido no status atual.',
  'animal/name': 'Animal/Nome',
  'positive': 'Positivo',
  'inconclusive': 'Inconclusivo',
  'first touch': 'Primeiro toque',
  'confirmatory touch': 'Toque confirmatório',
  'drying touch': 'Toque de secagem',
  'empty': 'Vazia',
  'registered material': 'Materiais Cadastrados',
  'no materials found': 'Nenhum material encontrado',
  'materials': 'Materiais',
  'invalid animal status or category': 'Status ou Categoria do animal inválidas para a ação',
  'animal have no inseminations': 'O animal não tem nenhuma inseminação',
  'an error ocurred': 'Ocorreu um erro',
  'registry date': 'Data de registro',
  'able?': 'Apta?',
  'able': 'Apta',
  'Active': 'Ativo',
  'Inactive': 'Inativo',
  'Request failed with status code 403': 'Você não pode realizar essa ação!',
  'realize visit': 'Realizar Visita',
  'realize': 'Realizar',
  'you need to have a registered field to register rain': 'Você precisa possuir um talhão cadastrado para registrar chuva',
  'only cash': 'Crédito Recusado',
  'credit denied': 'Recusar Crédito',
  'Request failed with status code 400': 'Ocorreu um erro',
  'request failed with status code 400': 'Ocorreu um erro',
  'Duplicate unique key': 'Chave única duplicada',
  'Unauthorized': 'Por favor, refaça seu login',
  'milk pecuary': 'Pecuária de Leite',
  'group by crop/sowing year': 'Agrupar por cultura/ano safra',
  'Duplicate company_name or trade_name value': 'Nome Fantasia ou Razão Social já sendo utilizado',
  'producer has no property': 'Produtor não tem propriedades cadastradas',
  'history is not done': 'Para consultar seu histórico use a plataforma web:',
  'rain map is not done': 'Para consultar o mapa de chuva use a plataforma web:',
  'select a valid value for {this}':
    'Selecione um valor válido para {this}',
  'show accounts': 'Mostrar Contas',
  'show recommendations': 'Mostrar recomendações',
  'click on show accounts': 'Clique no botão mostrar contas',
  'Second birth date is not valid': 'Novo parto só é permitido na mesma data do parto anterior',
  'allow purchase only in cash': 'Permite a compra apenas na modalidade à vista',
  'nº {this}': 'Nº {this}',
  'North': 'Norte',
  'Northeast': 'Nordeste',
  'East': 'Leste',
  'Southeast': 'Sudeste',
  'South': 'Sul',
  'Southwest': 'Sudoeste',
  'West': 'Oeste',
  'Northwest': 'Noroeste',
  'select a date with a minimum range of 6': 'Selecione um período mínimo de consulta de 6 dias!',
  'predicted actions - dairy farm': 'Ações Previstas - Tambo',
  'late or scheduled actions for the next 7 days': 'Ações atrasadas ou previstas para os próximos 7 dias',
  'full_battery': 'Cheia',
  'trending': 'Em alta',
  'half_battery': 'Pela Metade',
  'low_battery': 'Baixa',
  'info_low_battery': 'Crítica',
  'error_battery': 'Erro',
  'parameters': 'Parâmetros',
  'parameter': '{howMany, plural, one {Parâmetro} other {Parâmetros}}',
  'dry period': 'Período Seco',
  'voluntary waiting period': 'Período de espera voluntário',
  'average del goal': 'Meta de DEL médio',
  'average del': 'DEL médio',
  'the analysis results have not been yet informed': 'Os resultados dessa análise ainda não foram informados.',
  'initial touch': 'Toque inicial',
  'reconfirmation touch': 'Toque confirmatório',
  'drying confirmation': 'Confirmação de Secagem',
  'pre birth': 'Pré-Parto',
  'last birth': 'Último parto',
  'last birth before the registry': 'Último parto (Anterior ao registro)',
  'days': 'dias',
  'Days': 'Dias',
  'protocol date': 'Data de Protocolo',
  'discard': 'Descarte',
  'product details': 'Detalhes do produto',
  'Duplicated entry for price date': 'Não é possível adicionar novo registro para uma data já cadastrada.',
  'Duplicated entry for milk qualities': 'Não é possível adicionar novo registro para uma data já cadastrada.',
  'Duplicated entry for volume date': 'Não é possível adicionar novo registro para uma data já cadastrada.',
  'select your state registrations': 'Selecione suas inscrições estaduais:',
  'state registrations': 'Inscrições estaduais',
  'reported acumulated precipitations': 'Precipitação acumulada reportada',
  'reported precipitations': 'Precipitação reportada',
  'climate events': 'Eventos climáticos',
  'other climatic events': 'Outros eventos climáticos',
  'in the dairy farm only data related to selected state registrations will be displayed': 'No tambo somente serão exibidos os dados relacionados as inscrições estaduais selecionadas',
  'You have to provide at least one state registration.': 'Você precisa informar ao menos uma inscrição estadual.',
  'there are no products with valid quotes': 'Não existem produtos com cotações válidas',
  'there are no valid batches': 'Não há lotes válidos',
  'there are no available products for sale': 'Não existem produtos disponíveis para comercialização',
  'there is no balance for sale': 'Não há saldo para comercialização',
  'Atualize seu app pra continuar utilizando esta funcionalidade': 'Atualize seu aplicativo pra continuar utilizando esta funcionalidade',
  'For more details click on:': 'Para mais detalhes clique no:',
  'inform produtivity': 'Informar produtividade',
  'Unable to verify state registration': 'Não foi possível validar a inscrição estadual',
  'user {this} created': 'Usuário {this} criado.',
  'user {this} edited': 'Usuário {this} editado.',
  'feeding': 'Alimentação',
  'You must provide the  smartcoop organization or external company name': 'Você precisa fornecer a cooperativa ou um nome da empresa',
  'uninformed': 'Não informado',
  'User already registered, use update instead': 'Usuário já cadastrado.',
  'organization indicators': 'Indicadores da Coop.',
  'satellite images disabled when no internet connection': 'Imagens de satélite desabilitadas quando não há conexão com a internet',
  'settings': '{howMany, plural, one {Configuração} other {Configurações}}',
  'reload data': 'Recarregar dados',
  'processing image': 'Processando Imagem',
  'offline data': 'Dados Offline',
  'offline': 'Sem Conexão com a Internet',
  'offline 3 months': 'Você está offline, são exibidos somente registros dos últimos 3 meses.',
  'your data is being loaded': 'Seus dados estão sendo carregados',
  '{this} unavailable when no internet connection': '{this} indisponível quando não há conexão com a internet',
  'are you sure you want to import this file?': 'Tem certeza que deseja importar este arquivo?\nEsta ação pode subscrever o contorno já existente',
  'import shape': 'Importar contorno',
  'KML file must have only one polygon': 'O arquivo deve ter apenas UM polígono.',
  'download kml': 'Baixar KML',
  'polygon name': 'Nome da Àrea',
  'postalCode must has 8 characters': 'Código Postal deve ter 8 caracteres',
  'cellPhone must be longer than or equal to 11 characters': 'Número do Telefone deve ser maior ou igual a 11 caracteres',
  'email must be an email': 'Email inválido',
  'download your stamp {this}': 'Realize o download do seu selo {this}',
  'stamp': 'Selo',
  'pdf': 'pdf',
  'png': 'png',
  'project': '{howMany, plural, one {Projeto} other {Projetos}}',
  'register new participant': 'Cadastrar participante',
  'participation successfull': 'Participação realizada com sucesso!',
  'participation denied': 'Participação recusada!',
  'participation removed': 'Participação removida com sucesso!',
  'accreditment removed': 'Credenciamento removido.',
  'operation removed': 'Operação removida.',
  'operation details': 'Detalhamento das Operações',
  'rtc_365': 'RTC 365',
  'associate name': 'Nome Associado',
  'This field is already in productivity challenge': 'Este talhão já está participando do Concurso de Produtividade',
  'remove from productivity challange': 'Remover do Concurso de Produtividade',
  'monitoring': 'Monitoramento',
  'we are looking for your images': 'Estamos buscando suas imagens',
  'technical vision': 'Visão técnica',
  'days after coverage': 'Dias após cobertura',
  'days after first touch': 'Dias após toque inicial',
  'days after birth': 'Dias após o parto',
  'days before birth': 'Dias antes do parto',
  'days in lactation': 'Dias em Lactação',
  'invalid earring': 'Brinco já em uso',
  'loading offline data': 'Carregando dados offline',
  'loading messages': 'Carregando mensagens',
  'demand parameterization': 'Parametrização da demanda',
  'demand parameterization dates': 'Datas de Parametrização da demanda',
  'organization decision deadline': 'Data limite de decisão cooperativa',
  'opening': 'Abertura',
  'closing of demand': 'Fechamento da Demanda',
  'deadline for proposal': 'Data limite proposta',
  'organization decision limit': 'Limite para decisão cooperativas',
  'demand closing date': 'Data de fechamento da demanda',
  'demand closing': 'Fechamento da demanda',
  'demand closing hour': 'Hora de fechamento da demanda',
  'delivery observations': 'Observações de entrega',
  'date must be greater than {this}': 'Data deve ser maior que {this}',
  'hour must be greater than {this}': 'Hora deve ser maior que {this}',
  'production data': 'Dados de produção',
  'nul': 'NUL',
  'price demonstrative': 'Demonstrativos de Preços - CCGL',
  'raction for lactating cows': 'Ração para vacas em lactação',
  'percent lactation animals': 'Percentual de vacas em lactação',
  'milk destined for calves': 'Leite destinado à terneiras',
  'milk discarded on the property or family consumption': 'Leite descartado na propriedade ou consumo família',
  'monthly data logging': 'Registro de dados mensais',
  'monthly': 'Mensal',
  'Monthly data duplicated for this month': 'Já existe um registro para o mês informado',
  'raction for cows (kg)': 'Ração p/ vacas (kg)',
  'calves milk (Lts)': 'Leite terneiras (lts)',
  'discarded/consumed milk (Lts)': 'Leite descartado/consumo (lts)',
  'field participating in RTC productivity contest': 'Talhão participante do concurso de produtividade RTC',
  'raction for cows': 'Ração p/ vacas',
  'calves milk': 'Leite terneiras',
  'percentage': 'Porcentagem',
  'download sheet': 'Exportar Planilha',
  'current growing seasons': 'Safra Atual',
  'when exporting the sheet, keep the column names as the original model': 'Ao exportar planilha, manter os nomes das colunas conforme o modelo original.',
  'discarded/consumed milk': 'Leite descartado/consumo',
  'you need internet connection to continue': 'Seus dados offline precisam ser atualizados. Conecte-se a internet para continuar a navegação!',
  'you have no internet access. this producer is data is not available offline. wait for connection or compensation to wallet.': 'Você está sem acesso a internet. Os dados deste produtor não estão disponíveis offline. Aguarde conexão ou retorne para carteira.',
  'return to wallet': 'Retornar para carteira',
  'total production': 'Produção Total',
  'discounted volume': 'Volume Descontado',
  'liquid volume': 'Volume Líquido',
  'competency': 'Competência',
  'general': 'Geral',
  'fat percentual': 'Percentual de Gordura (média últimas 3 análises)',
  'protein percentual': 'Percentual de Proteína (média últimas 3 análises)',
  'fat and protein sum': 'Soma de Gordura e Proteína',
  'somatic cells count': 'CCS (média últimas 3 análises)',
  'total fat and protein': 'Gordura e Proteína Total',
  'fluid price': 'Preço Fluído',
  'fat and protein kg base price': 'Preço base Gordura e Proteína',
  'fat and protein aditional scale': 'Adicional de escala Gordura e Proteína',
  'sommatic cells count reducer': 'Redutor CCS',
  'fat and protein final kg price': 'Preço final Gordura e Proteína',
  'fluid milk sheet': 'Folha Leite Fluído',
  'participation weigth': 'Peso da Participação',
  'solid milk sheet': 'Folha Leite Sólidos',
  'final milk sheet': 'Folha do Leite Final',
  'smartcoop aditional': 'Bonificação Programa de Gestão',
  'management program sheet': 'Folha Programa de Gestão',
  'final fluid price': 'Preço Final',
  'message' : 'Mensagem',
  'nickname' : 'Apelido',
  'new group' : 'Novo Grupo',
  'new broadcast' : 'Nova Transmissão',
  'my contacts' : 'Meus Contatos',
  'delete message': 'Apagar mensagem',
  'delete for me' : 'Apagar para mim',
  'delete for all' : 'Apagar para todos',
  'message deleted' : 'Mensagem apagada',
  'select messages' : 'Selecionar mensagens',
  'the message': 'A mensagem',
  'no conversation found': 'Nenhuma conversa encontrada',
  'send your first message to this contact': 'Envie sua primeira mensagem para este contato',
  'send your first message to this group': 'Envie sua primeira mensagem para este grupo',
  'send your first message to this broadcast': 'Envie sua primeira mensagem para esta lista de transmissão',
  'invalid contact code': 'Código de contato inválido',
  'user not found': 'Usuário não encontrado',
  'error on search contact': 'Ocorreu um erro ao procurar o contato',
  'error on add contact': 'Ocorreu um erro ao adicionar o contato',
  'error on remove contact': 'Ocorreu um erro ao remover o contato',
  'do you want to add {name} to your contacts?': 'Deseja adicionar {name} aos seus contatos?',
  'do you want to remove {name} from your contacts?': 'Deseja remover {name} dos seus contatos?',
  'contact successfully added': 'Contato adicionado com sucesso',
  'contact successfully removed': 'Contato removido com sucesso',
  'added contacts': 'Contatos',
  'family contacts': 'Grupo Familiar',
  'organization contacts': 'Contatos Organização',
  'producer contacts': 'Produtores',
  'wallet producers': 'Produtores da carteira',
  'out of wallet producers': 'Produtores da cooperativa',
  'cooperative group': 'Cooperativa',
  'supplierGroup': 'Fornecedor',
  'ccgl': 'CCGL',
  'smartcoop contacts': 'SmartCoop',
  'technician contacts': 'Técnicos',
  'messages': 'Mensagens',
  'enter at least 3 characters to start the search': 'Digite pelo menos 3 caracteres para iniciar a pesquisa',
  'you can only add 3 favorites': 'Você só pode adicionar 3 favoritos',
  'forward message': 'Encaminhar mensagem',
  'delete messages': 'Apagar mensagens',
  'forward': 'Encaminhar',
  'forwarded': 'Encaminhada',
  'select at least one contact to forward the message': 'Selecione ao menos um contato para encaminhar a mensagem',
  'select at least one conversation to delete': 'Selecione ao menos uma conversa para excluir',
  'error forwarding messages. contact your system administrator': 'Erro ao encaminhar mensagens. Entre em contato com o administrador do sistema.',
  'error delete conversations. contact your system administrator': 'Erro ao excluir conversa(s). Entre em contato com o administrador do sistema.',
  'error send file. contact your system administrator': 'Erro enviar arquivos. Entre em contato com o administrador do sistema.',
  'do you really want to delete the conversation(s)?': 'Deseja realmente excluir a(s) conversa(s)?',
  'delete conversation(s)': 'APAGAR CONVERSA(S)',
  'conversations deleted successfully': 'Conversas excluídas com sucesso',
  'search in conversation': 'Pesquisar na conversa',
  'no message found': 'Nenhuma mensagem encontrada',
  'audio': 'Áudio',
  'filter contacts': 'Filtrar Contatos',
  'contact name': 'Nome do contato',
  'search contacts or start new conversation': 'Pesquisar contatos ou iniciar nova conversa',
  'files {this} exceeded the maximum upload size of 16MB': 'Os arquivos {this} excederam o tamanho máximo para envio, de 16MB',
  'the file format {this} is not supported': 'O formato do arquivo {this} não é suportado',
  'one image': 'Imagem',
  'preview - {index} of {total}': 'Pré-visualização - {index} de {total}',
  'send': 'Enviar',
  'video': 'Vídeo',
  'document': 'Documento',
  'preview unavailable': 'Preview Indisponível',
  'loading your data, please wait...': 'Carregando seus dados, aguarde...',
  'you': 'Você',
  'contact code': 'Código do contato',
  'update contact code': 'Atualizar código do contato',
  'contact "{name}" changed the code. type the new contact code below to continue chatting': 'O contato "{name}" alterou o código. Insira o novo código do contato abaixo para continuar conversando',
  'an error occurred while updating the contact': 'Ocorreu um erro durante a atualização do contato',
  'an error occurred while downloading the document': 'Ocorreu um erro ao realizar download do documento',
  'download completed successfully. check your download folder': 'Download realizado com sucesso.',
  'contact updated successfully': 'Contato atualizado com sucesso',
  'inactive recipient': 'Destinatário inativo',
  'select broadcast members': 'Selecione os membros da transmissão',
  'select group members': 'Selecione os membros do grupo',
  'how do you want to add this contact to the group?': 'Como deseja adicionar esse contato ao grupo?',
  'how administrator': 'Como administrador',
  'how normal member': 'Como membro comum',
  'select a contact': 'Selecione um contato',
  'insert a name': 'Insira um nome',
  'required to enter a name for the group': 'Obrigatório informar um nome para o grupo',
  'select at least one participant for the group': 'Selecione ao menos um participante para o grupo',
  'an error occurred while creating the group': 'Ocorreu um erro ao realizar a criação do grupo',
  'user "{name}" added to the group': 'Usuário "{name}" adicionado ao grupo',
  'contact is already present in the list. select it': 'Contato já está presente na lista. Selecione-o',
  'members': 'Membros',
  'details': 'Detalhes',
  'presential': 'Presencial',
  'virtual': 'Virtual',
  'the management was scheduled to your field': 'O Manejo foi agendado no seu Talhão',
  'message forwarded successfully': 'Mensagem encaminhada com sucesso',
  'remove from group': 'Remover do grupo',
  'make admin': 'Tornar administrador',
  'dismiss as admin': 'Dispensar como administrador',
  'member successfully removed': 'Membro removido com sucesso',
  'an error occurred while remove member from group': 'Ocorreu um erro ao remover membro do grupo',
  'member made admin successfully': 'Membro tornado administrador com sucesso',
  'an error occurred while making an admin member': 'Ocorreu um erro ao tornar membro administrador',
  'add member in group': 'Adicionar membro ao grupo',
  'add member in broadcast': 'Adicionar membro à lista de transmissão',
  'an error occurred while add new member': 'Ocorreu um erro ao adicionar um novo membro',
  'an error occurred while change group name': 'Ocorreu um erro ao alterar o nome do grupo',
  'member successfully added': 'Membro(s) adicionado(s) com sucesso',
  'group name successfully changed': 'Nome do grupo alterado com sucesso',
  'group "{name}" created successfully': 'Grupo "{name}" criado com sucesso',
  'you left the group': 'Você saiu do grupo',
  'you left the broadcast': 'Você saiu da lista de transmissão',
  'there was an error leaving the group': 'Ocorreu um erro ao sair do grupo',
  'conversation deleted successfully': 'Conversa excluída com sucesso',
  'there was an error deleting conversation': 'Ocorreu um erro ao excluir conversa',
  'do you really want to leave the "{name}" group?': 'Você realmente deseja sair do grupo "{name}"?',
  'do you really want to leave the "{name}" broadcast?': 'Você realmente deseja sair da lista de transmissão "{name}"?',
  'do you want to delete the conversation?': 'Você deseja excluir a conversa?',
  'the name must contain at least 3 characters': 'O nome deve conter no mínimo 3 caracteres',
  'group name': 'Nome do grupo',
  'argila must be a number greater than 0': 'Argila deve ser um número maior que 0.',
  'areia must be a number greater than 0': 'Areia deve ser um número maior que 0.',
  'silte must be a number greater than 0': 'Silte deve ser um número maior que 0.',
  'ph must be a number, between 0 and 14': 'PH deve ser um número entre 0 e 14.',
  'indice_smp must be a number, between 0 and 14': 'Índice SMP deve ser um número entre 0 e 14.',
  'fosforo must be a number, between 0 and 1000': 'Fósforo deve ser um número entre 0 e 1000.',
  'potassio must be a number, between 0 and 1000': 'Potássio deve ser um número entre 0 e 1000.',
  'materia_organica must be a number': 'Matéria Orgânica deve ser um número.',
  'aluminio must be a number, between 0 and 10':'Alumínio deve ser um número entre 0 e 10.',
  'calcio must be a number, between 0 and 100':'Cálcio deve ser um número entre 0 e 100.',
  'magnesio must be a number, between 0 and 14': 'Magnésio deve ser um número entre 0 e 14.',
  'hidrogenio_aluminio must be a number, between 0 and 100': 'Hidrogênio Alumínio deve ser um número entre 0 e 100.',
  'ctc must be a number, between 0 and 100': 'CTC deve ser um númro entre 0 e 100.',
  'saturacao_bases must be a number, between 0 and 100': 'Saturação Bases deve ser um número entre 0 e 100.',
  'saturacao_aluminio must be a number, between 0 and 100': 'Saturação Alumínio deve ser um número entre 0 e 100.',
  'enxofre must be a number, between 0 and 1000': 'Enxofre deve ser um número entre 0 e 1000.',
  'zinco must be a number, between 0 and 1000': 'Zinco deve ser um número entre 0 e 1000.',
  'cobre must be a number, between 0 and 1000': 'Cobre deve ser um número entre 0 e 1000.',
  'boro must be a number, between 0 and 1000': 'Boro deve ser um número entre 0 e 1000.',
  'manganes must be a number, between 0 and 1000': 'Manganês deve ser um número entre 0 e 1000.',
  'ferro must be a number, between 0 and 1000': 'Ferro deve ser um número entre 0 e 1000.',
  'At least one contact must be added': 'Ao menos um contato deve ser adicionado',
  'file exceeded the maximum upload size of 16MB': 'Arquivo ultrapassou tamanho máximo de 16MB',
  'favorite': 'Favoritar',
  'unfavorite': 'Desfavoritar',
  'delete chat history': 'Apagar Histórico de Conversa',
  'exit group': 'Sair do Grupo',
  'exit broadcast': 'Sair da Lista de Transmissão',
  'delete the conversation': 'Excluir Conversa',
  'add members': 'Adicionar Membros',
  'action performed successfully': 'Ação realizada com sucesso',
  'search member': 'Buscar por membro',
  'an error occurred while updating the image': 'Ocorreu um erro ao atualizar a imagem',
  'image updated successfully': 'image updated successfully',
  'do you really want to delete the conversation history?': 'Deseja realmente apagar o histórico da conversa?',
  'there was an error deleting conversation history': 'Ocorreu um erro ao apagar histórico da conversa',
  'conversation history deleted successfully': 'Histórico da conversa apagado com sucesso',
  'broadcast "{name}" created successfully': 'Lista de transmissão "{name}" criada com sucesso',
  'an error occurred while creating the broadcast': 'Ocorreu um erro ao realizar a criação da lista de transmissão',
  'required to enter a name for the broadcast': 'Obrigatório informar um nome para a lista de transmissão',
  'select at least one participant for the broadcast': 'Selecione ao menos um participante para a lista de transmissão',
  'an error occurred while sending the message': 'Ocorreu um erro ao enviar a mensagem',
  'there was an error deleting the message': 'Ocorreu um erro ao excluir a mensagem',
  'remove from broadcast': 'Remover da lista de transmissão',
  'filter by name': 'Filtrar por nome',
  'select all conversations': 'Selecionar todas as conversas',
  'deselect all conversations': 'Desselecionar todas as conversas',
  'do you want to remove "{name}" from the group?': 'Você deseja remover "{name}" do grupo?',
  'do you want to remove "{name}" from the broadcast?': 'Você deseja remover "{name}" da lista de transmissão?',
  'do you want to make "{name}" administrator?': 'Você deseja tornar "{name}" administrador?',
  'do you want to dismiss "{name}" as an administrator?': 'Você deseja dispensar "{name}" como administrador?',
  'open in maps': 'Abrir no Mapa',
  'what app would you like to use?': 'Com qual aplicativo você gostaria de abrir?',
  'loading contact list, please await': 'Carregando lista de contatos, por favor aguarde',
  'the group must have a maximum of 200 members': 'O grupo deve possuir no máximo 200 membros',
  'the broadcast must have a maximum of 200 members': 'A lista de transmissão deve possuir no máximo 200 membros',
  'sharing by lot': 'Rateio por lote',
  'asset': 'Bem/Ativo',
  'properties and assets': 'Bens/Ativos',
  'property and assets movements': 'Movimentações',
  'view movements': 'Ver Movimentações',
  'register asset': 'Cadastrar Ativo',
  'asset description': 'Descrição do Bem',
  'asset brand': 'Marca do Bem',
  'asset year': 'Ano do Bem',
  'asset purchase date': 'Data de Aquisição',
  'depreciation group': 'Grupo de Depreciação',
  'asset value': 'Valor do Ativo',
  'asset estimated life': 'Vida Útil estimada',
  'asset monthly depreciation': 'Depreciação Mensal do Bem',
  'linked asset': 'Vincular Bem',
  'test mode': 'Modo de Teste',
  'activity config': 'Configuração de lançamento/rateio',
  'calculate': 'Calcular',
  'when the routine is executed, the depreciation transactions for the assets linked to the property in the month of {month}/{year} will be redone, do you want to continue?': 'Ao executar a rotina serão refeitos os movimentos de depreciação dos bens vinculados a propriedade no mês de {month}/{year}, deseja continuar?',
  'calculation successful': 'Cálculo realizado com sucesso',
  'search asset': 'Buscar bem',
  'showing not registered only': 'Só serão exibidos cadastros que ainda não existam no controle de bens e ativos.',
  'The depreciation start date cannot be less than the asset acquisition date and cannot be less than the current month': 'A data inicial de depreciação não pode ser anterior a data de aquisição do bem e não pode ser anterior ao mês atual.',
  'recalculate month': 'Recalcular mês',
  'no producer': 'Sem produtor',
  'writed off': 'Baixado',
  'Movemented property assets can not be deleted. Only writed-off': 'Bens movimentados não podem ser excluídos. Apenas baixados.',
  'This animal is linked as an asset, it is necessary to remove it from the inventory before deleting it': 'Este animal está vinculado como um bem, é necessário removê-lo do inventário antes de excluí-lo.',
  'This machine is linked as an asset, it is necessary to remove it from the inventory before deleting it': 'Esta máquina está vinculada como um bem, é necessário removê-la do inventário antes de excluí-la.',
  'include machines and animals': 'Incluir máquinas e animais',
  'animals': 'Animais',
  'Your current permissions on the property do not allow you to include management schedules.': 'Sua permissão atual na propriedade não permite que você inclua cronogramas de gerenciamento.',
  'Technician permission not found.':'Permissão de técnico não encontrada.',
  'User property not found.': 'Propriedade do usuário não encontrada.',
  'start depreciation date': 'Data Inicial da Depreciação',
  'total depreciation': 'Depreciação Realizada',
  'balance to depreciate': 'Saldo a depreciar',
  'asset value must be greater than total depreciation': 'O valor do bem deve ser maior que a depreciação realizada',
  'name/earring': 'Nome/Brinco',
  'type/model': 'Tipo/Modelo',
  'brand/year': 'Marca/Ano',
  'selled': 'Vendido',
  'insemminated': 'Inseminado',
  'insemination value': 'Valor da Inseminação',
  'seeds/ha': 'KG sementes por hectare',
  'valueKgSemHA': 'KG/Sementes/ha',
}
