export const selectAnimals = state => state.animal.animals

export const selectPropertyBulls = state => state.animal.propertyBulls

export const selectCurrentAnimal = state => state.animal.currentAnimal

export const selectIsDetail = state => state.animal.isDetail

export const selectActiveTab = state => state.animal.activeTab

export const selectAnimalPevList = state => state.animal.pevList

export const selectUnavailableEarrings = state => state.animal.unavailableEarrings

export const selectAnimalObservations = state => state.animal.observations

export const selectMilkControlList = state => state.animal.milkControlList
