import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { isUndefined } from 'lodash'

import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterPevForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterPevForm'

import useStyles from './styles'

const RegisterPevModal = (props) => {
  const { id, open, handleClose, pev, reloadDataTable, onSubmit } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const isEditing = useMemo(() => !!pev?.id, [pev])

  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(() => {
    setIsLoading(false)
    if (isUndefined(reloadDataTable)) {
      onSubmit()
    } else {
      reloadDataTable()
    }
    handleClose()
  }, [handleClose, onSubmit, reloadDataTable])

  const onSuccess = useCallback(() => {
    snackbar.success(
      t(`your {this} was ${ isEditing ? 'edited' : 'registered' }`, {
        howMany: 2,
        gender: 'male',
        this: 'PEV'
      })
    )
    closeModal()
  }, [closeModal, isEditing, snackbar, t])

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(AnimalActions.saveOfflineAnimalPev({ id: pev?.id, ...data }, onSuccess, () => setIsLoading(false), true))
    },
    [dispatch, onSuccess, pev]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ t('register {this}', { this: 'PEV' }) }
      disableFullScreen
      disableEsc
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {isLoading ? (
          <Loader width={ 100 } />
        ) : (
          <RegisterPevForm
            ref={ formRef }
            defaultValues={ pev }
            onSubmit={ handleSubmit }
            onCancel={ closeModal }
            isEditing={ isEditing }
          />
        )}
      </>
    </Modal>
  )
}

RegisterPevModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  pev: PropTypes.object,
  reloadDataTable: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
}

RegisterPevModal.defaultProps = {
  handleClose: () => {},
  pev: {},
  reloadDataTable: undefined,
  onSubmit: undefined
}

export default RegisterPevModal
