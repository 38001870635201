/* eslint-disable no-nested-ternary */
import React, { useCallback, useState,useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import  I18n, { useT } from '@smartcoop/i18n'
import { DairyFarmActions } from '@smartcoop/stores/dairyFarm'
import InputUnit from '@smartcoop/web-components/InputUnit'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Divisor, FormContainer, Item, EmptyDataContainer } from './styles'

const CompositionModal = (props) => {
  const {
    id,
    open,
    stateRegistration,
    anoMes
  } = props

  const t = useT()
  const classes = useStyles()
  const dispatch = useCallback(useDispatch(), [])

  const [isLoading, setIsLoading] = useState(false)
  const [priceCompositionData, setPriceCompositionData] = useState({})

  useEffect(() => {
    setIsLoading(true)
    dispatch(DairyFarmActions.loadPriceComposition(
      { anoMes: moment(anoMes).format('YYYYMM'), stateRegistration },
      (data) => {
        setPriceCompositionData(data)
        setIsLoading(false)
      },
      () => setIsLoading(false)
    ))
  }, [anoMes, dispatch, stateRegistration])

  const show = useMemo(() => toNumber(anoMes) >= 202207,[anoMes])

  const priceComposition = useMemo(() => ([
    {
      label: 'total production',
      value: priceCompositionData?.volumeTotal,
      unit: 'L',
      type: 'integer',
      show: true
    },{
      label: 'discounted volume',
      value: priceCompositionData?.volumeDesconto,
      unit: 'L',
      type: 'integer',
      show: true
    },{
      label: 'liquid volume',
      value: priceCompositionData?.volumeLiquido,
      unit: 'L',
      type: 'integer',
      show: true
    },{
      label: 'fat percentual',
      value: priceCompositionData?.gordura,
      unit: '%',
      type: 'float',
      show
    },{
      label: 'protein percentual',
      value: priceCompositionData?.proteina,
      unit: '%',
      type: 'float',
      show
    },{
      label: 'fat and protein sum',
      value: priceCompositionData?.totalGorduraProteina,
      unit: '%',
      type: 'float',
      show
    },{
      label: 'somatic cells count',
      value: priceCompositionData?.ccs,
      unit: 'CS/mL',
      type: 'integer',
      show
    },{
      label: 'total fat and protein',
      value: priceCompositionData?.totalSolidos,
      unit: 'kg',
      type: 'float',
      show
    },{
      label: 'fluid price',
      value: priceCompositionData?.precoFluido,
      unit: 'R$/L',
      type: 'float',
      show
    },{
      label: 'fat and protein kg base price',
      value: priceCompositionData?.precoBaseSolidos,
      unit: 'R$/Kg',
      type: 'float',
      show
    },{
      label: 'fat and protein aditional scale',
      value: priceCompositionData?.adicionalGP,
      unit: 'R$/Kg',
      type: 'float',
      decimalScale: 4,
      show
    },{
      label: 'sommatic cells count reducer',
      value: priceCompositionData?.redutorCcs,
      unit: 'R$/Kg',
      type: 'float',
      decimalScale: 4,
      show
    },{
      label: 'fat and protein final kg price',
      value: priceCompositionData?.precoFinalSolidos,
      unit: 'R$/Kg',
      decimalScale: 4,
      type: 'float',
      show
    },{
      label: 'fluid milk sheet',
      value: priceCompositionData?.valorBrutoFluido,
      unit: 'R$/Totais',
      type: 'float',
      show
    },{
      label: 'participation weigth',
      value: priceCompositionData?.participacaoFluido,
      unit: '%',
      type: 'float',
      show
    },{
      label: 'solid milk sheet',
      value: priceCompositionData?.valorBrutoSolido,
      unit: 'R$/Totais',
      type: 'float',
      show
    },{
      label: 'participation weigth',
      value: priceCompositionData?.participacaoSolidos,
      unit: '%',
      type: 'float',
      show
    },{
      label: 'smartcoop aditional',
      value: priceCompositionData?.adicionalSmartcoop,
      unit: 'R$/L',
      type: 'float',
      decimalScale: 2,
      show: true
    },
    {
      label: 'management program sheet',
      value: priceCompositionData?.volumeLiquido * priceCompositionData?.adicionalSmartcoop,
      unit: 'R$/Totais',
      type: 'float',
      decimalScale: 2,
      show: true
    }, {
      label: 'final milk sheet',
      value: show ? priceCompositionData?.valorLiquido : priceCompositionData?.valorBrutoFluido,
      unit: 'R$/Totais',
      type: 'float',
      show: true
    },{
      label: 'final fluid price',
      value: show ? priceCompositionData?.precoFinal : priceCompositionData?.precoFluido,
      unit: 'R$/L',
      type: 'float',
      decimalScale: 4,
      show: true
    }
  ]),[priceCompositionData, show])

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <I18n>price demonstrative</I18n>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="sm"
      fullWidth
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            !priceCompositionData ? <EmptyDataContainer>
              <p>Dados não disponíveis.</p>
              <p>Tente novamente mais tarde.</p>
            </EmptyDataContainer> :
              <FormContainer>
                <Grid container style={ { justifyContent: 'space-between' } }>
                  {map(priceComposition, (item, index) => item?.show ? (
                    <Item
                      key={ index }
                      style={
                        {
                          marginTop: item?.label === 'smartcoop aditional' || item?.label === 'fluid price' || item?.label === 'fluid milk sheet' || item?.label === 'final milk sheet' ? '50px' : 0
                        }
                      }
                    >
                      <InputUnit
                        detached
                        unit={ item?.unit }
                        label={ t(item?.label) }
                        fullWidth
                        type={ item?.type }
                        disabled
                        value={ toString(item?.value) }
                        visibleButDisabled
                        decimalScale={ item?.decimalScale }
                      />
                    </Item>
                  ) : null )}

                </Grid>
              </FormContainer>
        }
      </>
    </Modal>
  )}

CompositionModal.propTypes = {
  id: PropTypes.string.isRequired,
  stateRegistration: PropTypes.string.isRequired,
  anoMes: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

CompositionModal.defaultProps = {
}

export default CompositionModal
