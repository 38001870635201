/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import padStart from 'lodash/padStart'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'

import I18n, { useT } from '@smartcoop/i18n'
import { windmill, arrowUpBold } from '@smartcoop/icons'
import * as weatherIcons  from '@smartcoop/icons/weatherIcons'
import {
  selectCurrent,
  selectNextHour,
  selectNextDays
} from '@smartcoop/stores/weatherForecast/selectorWeatherForecast'
import {
  momentBackDateTimeFormat,
  momentFriendlyExtendedDateFormat,
  momentFriendlyTimeFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import { getCardinal } from '@smartcoop/utils/maps'
import CardAccordion from '@smartcoop/web-components/CardAccordion'
import Icon from '@smartcoop/web-components/Icon'

import {
  Container,
  Header,
  Column,
  Row,
  AverageContainer,
  AverageNumberContainer,
  AverageNumber,
  AverageType,
  Divider,
  Title,
  ContainerHour,
  ContainerCardHours,
  CardHours,
  ContainerDays,
  ScrollDays,
  DayCard,
  DayCircle
} from './styles'

const WeatherForecast = () => {
  const t = useT()
  const current = useSelector(selectCurrent)
  const nextHours = useSelector(selectNextHour)
  const nextDays = useSelector(selectNextDays)

  const currentDate = useMemo(
    () => moment(current?.date, momentBackDateTimeFormat)
      .format(momentFriendlyExtendedDateFormat),
    [current]
  )

  return (
    <Container>
      <Header>
        <Column style={ { alignItems: 'flex-start' } }>
          <b style={ { marginBottom: 3 } }>{currentDate}</b>
        </Column>
      </Header>
      <Row style={ { justifyContent: 'space-between' } }>
        <Column>
          <AverageContainer>
            <Icon
              icon={ weatherIcons[`icon${ padStart(current?.iconCode, 2, '0') }`] }
              size={ 60 }
            />
            <AverageNumberContainer>
              <AverageNumber>{ !isEmpty(nextHours) ? Math.round(nextHours[0]?.temperature) : '-' }</AverageNumber>
              <AverageType>°C</AverageType>
            </AverageNumberContainer>
          </AverageContainer>
        </Column>
        <Column>
          <Row>Max { current?.high }°C</Row>
          <Row>Min { current?.low }°C</Row>
        </Column>
        <Column>
          <Row bold>
            <I18n>humidity percentage</I18n>
          </Row>
          <Row>{current?.humidity}%</Row>
        </Column>
        <Column>
          <Row bold>
            <I18n>wind</I18n>
          </Row>
          <Row>
            <Icon
              icon={ windmill }
              size={ 20 }
              style={ { marginRight: 5 } }
            />
            {current?.wind} km/h
          </Row>
        </Column>
        <Column>
          <Row bold>
            <I18n>wind direction</I18n>
          </Row>
          <Row>
            { current?.windDirection ? (
              <Icon
                icon={ arrowUpBold }
                size={ 15 }
                style={ { marginRight: 5, transform: `rotate(${ current?.windDirection }deg)` } }
              />
            ) : null}
            {t(getCardinal(Number(current?.windDirection)))}
          </Row>
        </Column>
        <Column>
          <Row bold>
            <I18n>precipitation</I18n>
          </Row>
          <Row>{round(sumBy(filter(nextHours, item => moment(item.date).isBefore(moment().endOf('day'))), 'precipitation') + current?.precipitation, 2)} mm</Row>
        </Column>
      </Row>
      <Divider />
      <ContainerHour>
        <Title>
          <I18n>hourly forecast</I18n>
        </Title>
        <ContainerCardHours>
          { nextHours && nextHours.map(item => (
            <CardHours key={ item.date }>
              <Row>{moment(item.date, momentBackDateTimeFormat).format(momentFriendlyTimeFormat)}</Row>
              <Icon
                icon={ weatherIcons[`icon${ padStart(item.iconCode, 2, '0') }`] }
                size={ 30 }
              />
              <b style={ { marginBottom: 3 } }>{Math.round(item.temperature)}°C</b>
              <Row maxContent>{item.humidity}%</Row>
              <Row maxContent>{item?.precipitation} mm</Row>
              <Row maxContent>{item.wind} km/h</Row>
              <Row maxContent>
                { item?.windDirection ? (
                  <Icon
                    icon={ arrowUpBold }
                    size={ 15 }
                    style={ { marginRight: 5, transform: `rotate(${ item?.windDirection }deg)` } }
                  />
                ) : null}
                {item?.windDirection ? t(getCardinal(Number(item?.windDirection))) : ' - '}
              </Row>
            </CardHours>
          )) }
        </ContainerCardHours>
      </ContainerHour>
      <Divider />
      <ScrollDays>
        <ContainerDays>
          { nextDays.map(item => (
            <CardAccordion
              key={ item.date }
              noBoxShadow
              style={ {
                display: 'flex',
                flex: 1
              } }
              spacingIconLeft="100px"
              title={
                <DayCard>
                  <DayCircle>
                    <b>{moment(item.date, momentFriendlyDateFormat).format('DD')}</b>
                    <span>{moment(item.date, momentFriendlyDateFormat).format('ddd')}</span>
                  </DayCircle>
                  <Icon
                    icon={ weatherIcons[`icon${ padStart(item.iconCode, 2, '0') }`] }
                    size={ 30 }
                  />
                  <Column>
                    <Row>{ item.high }°C</Row>
                    <Row>{ item.low }°C</Row>
                  </Column>
                  <Column>
                    <Row bold>
                      <I18n>humidity percentage</I18n>
                    </Row>
                    <Row>{item.humidity}%</Row>
                  </Column>
                  <Column>
                    <Row bold>
                      <I18n>wind</I18n>
                    </Row>
                    <Row>{item.wind} km/h</Row>
                  </Column>
                  <Column>
                    <Row bold>
                      <I18n>wind direction</I18n>
                    </Row>
                    <Row>
                      {item?.windDirection ? (
                        <Icon
                          icon={ arrowUpBold }
                          size={ 15 }
                          style={ { marginRight: 5, transform: `rotate(${ item?.windDirection }deg)` } }
                        />
                      ) : null}
                      {item?.windDirection ? t(getCardinal(Number(item?.windDirection))) : ' - '}
                    </Row>
                  </Column>
                  <Column>
                    <Row bold>
                      <I18n>precipitation</I18n>
                    </Row>
                    <Row>{item?.precipitation} mm</Row>
                  </Column>
                </DayCard>
              }
            >
              <ContainerCardHours>
                { item.hours && item.hours.map(subItem => (
                  <CardHours key={ subItem.date }>
                    <Row>{moment(subItem.date, momentBackDateTimeFormat).format(momentFriendlyTimeFormat)}</Row>
                    <Icon
                      icon={ weatherIcons[`icon${ padStart(subItem.iconCode, 2, '0') }`] }
                      size={ 30 }
                    />
                    <b style={ { marginBottom: 3 } }>{Math.round(subItem.temperature)}°C</b>
                    <Row maxContent>{subItem.humidity}%</Row>
                    <Row maxContent>{subItem?.precipitation} mm</Row>
                    <Row maxContent>{subItem.wind} km/h</Row>
                    <Row maxContent>
                      { subItem?.windDirection ? (
                        <Icon
                          icon={ arrowUpBold }
                          size={ 15 }
                          style={ { marginRight: 5, transform: `rotate(${ subItem?.windDirection }deg)` } }
                        />
                      ) : null}
                      {subItem?.windDirection ? t(getCardinal(Number(subItem?.windDirection))) : ' - '}
                    </Row>
                  </CardHours>
                )) }
              </ContainerCardHours>
            </CardAccordion>
          )) }
        </ContainerDays>
      </ScrollDays>
    </Container>
  )
}

export default WeatherForecast
