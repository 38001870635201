import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { ThemeProvider } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getProductGroups as getProductGroupsService } from '@smartcoop/services/apis/smartcoopApi/resources/productGroup'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearch from '@smartcoop/web-components/InputSearch'
import FilterProductGroupModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterProductGroupModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Title,
  Row,
  RightContainer,
  inputSearchTheme
} from './styles'

const Searchbar = ({ onChange }) => {
  const [filterText, setFilterText] = useState('')

  const t = useT()

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      onChange(value)
    }, 300),
    []
  )

  const handleChange = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    }, [debouncedChangeSearchFilter]
  )
  return (
    <ThemeProvider theme={ inputSearchTheme }>
      <InputSearch
        detached
        onChange={ handleChange }
        style={ { marginRight: 12 } }
        value={ filterText }
        placeholder={ t('search') }
        fullWidth
      />
    </ThemeProvider>
  )
}

Searchbar.propTypes = {
  onChange: PropTypes.func.isRequired
}

const ListProductGroupScreen = () => {
  const history = useHistory()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()

  const [filters, setFilters] = useState({})
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const tableFilters = useMemo(
    () => debouncedFilterText ?
      { ...filters, q: debouncedFilterText } :
      { ...filters },
    [debouncedFilterText, filters]
  )

  const t = useT()
  const columns = useMemo(
    () => [
      {
        title: t('product group'),
        field: 'name',
        sorting: true,
        defaultSort: 'asc',
        cellStyle: { fontWeight: 'bold' }
      },
      {
        title: t('description'),
        field: 'description',
        cellStyle: { whiteSpace: 'nowrap' }
      },
      {
        title: t('crop', { howMany: 2 }),
        field: 'crops',
        render: (row) => map(row?.crops, (crop) => (crop?.description)).join(', ')
      }
    ], [t])

  const handleFilter = useCallback(
    (values) => setFilters(values),
    []
  )

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-product-group',
        Component: FilterProductGroupModal,
        props: {
          onSubmit: handleFilter,
          filters
        }
      })
    },
    [createDialog, filters, handleFilter]
  )

  const onChangeSearchFilter = useCallback(
    (value) => {
      setDebouncedFilterText(value)
    },
    []
  )

  const goToProductGroupRegister = useCallback(
    () => {
      history.push(routes.createProductGroup.path)
    },
    [history, routes.createProductGroup.path]
  )

  const onRowClick = useCallback(
    (_event, data) => {
      history.push(routes.editProductGroup.path, { productGroup: { ...data } })
    },
    [history, routes.editProductGroup.path]
  )

  return (
    <Container>
      <Title><I18n>list of product groups</I18n></Title>
      <Row>
        <RightContainer>
          <Searchbar
            onChange={ onChangeSearchFilter }
          />
          <Button
            id="filter"
            onClick={ filterData }
            color={ isEmpty(filters) ? 'white' : 'secondary' }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="web-create-product-group-button"
            onClick={ goToProductGroupRegister }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
          >
            <I18n>register</I18n>
          </Button>
        </RightContainer>
      </Row>
      <DataTable
        onRowClick={ onRowClick }
        data={ getProductGroupsService }
        queryParams={ tableFilters }
        urlParams={ {} }
        columns={ columns }
        id="list-product-group-table"
      />
    </Container>
  )
}

export default ListProductGroupScreen
