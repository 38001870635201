import api from '../api'

export const getAnimals = (params, { propertyId }) =>
  api.get(`/v2/herds-management/property/${ propertyId }/animals/list`, { params })

export const getAnimal = (params, { propertyId, animalId }) =>
  api.get(`/v2/herds-management/property/${ propertyId }/animals/${ animalId }/list`, { params })

export const createAnimal = (params, { propertyId }) =>
  api.post(`/v1/herds-management/property/${ propertyId }/animals`, params)

export const importAnimals = (params, { propertyId }) =>
  api.post(`/v1/herds-management/property/${ propertyId }/animals/bulk-insert`, params)

export const editAnimal = (params, { animalId, propertyId }) =>
  api.patch(`/v1/herds-management/property/${ propertyId }/animals/${ animalId }`, params)

export const deleteAnimal = ({ animalId, propertyId }) =>
  api.delete(`/v1/herds-management/property/${ propertyId }/animals/${ animalId }`)

export const getAllAnimals = (params) =>
  api.get('/v1/herds-management/animals', { params })

export const getUnavailableEarrings = (params) =>
  api.get('/v1/herds-management/unavailable-earrings', { params })

export const createBirthAndAnimal = (params, { propertyId }) =>
  api.post(`/v1/herds-management/property/${ propertyId }/birth-and-animal`)

export const createAnimalImage = (params, { animalId }) =>
  api.post(`/v1/herds-management/animal/${ animalId }/file`, params , {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })
