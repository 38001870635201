import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'
import { makeActionFree } from '../stores'

/* Initial State */
const INITIAL_STATE = {
  userOrganizations: [],
  userOrganizationsByModule: {
    module: '',
    organizations: []
  },
  currentOrganization: {},
  currentOrganizationAddresses: [],
  error: null
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadUserOrganizations: ['webinar', 'onSuccess', 'onError', 'user'],
  loadUserOrganizationsSuccess: ['userOrganizations'],

  loadUserOrganizationsByModule: ['module', 'onSuccess', 'onError'],
  loadUserOrganizationsByModuleSuccess: [
    'organizations',
    'module',
    'onSuccess'
  ],

  loadCurrentOrganizationAddresses: ['params', 'onSuccess', 'onError'],
  loadCurrentOrganizationAddressesSuccess: ['addresses'],

  setCurrentOrganization: ['organization', 'onSuccess', 'onError'],
  clearCurrentOrganization: [],

  createOrganization: ['params', 'onSuccess', 'onError'],
  updateOrganization: ['params', 'onSuccess', 'onError'],
  deleteOrganization: ['organizationId', 'onSuccess', 'onError'],

  addOrganizationFiles: ['params', 'organizationId', 'onSuccess', 'onError'],

  loadOrganization: ['params', 'organizationId', 'onSuccess', 'onError'],

  updateSignature: ['params', 'onSuccess', 'onError'],

  organizationError: ['error']
})

makeActionFree('loadUserOrganizations')
makeActionFree('loadUserOrganizationsSuccess')
makeActionFree('loadUserOrganizationsByModule')
makeActionFree('loadUserOrganizationsSuccessByModule')
makeActionFree('setCurrentOrganization')
makeActionFree('organizationError')

const loadUserOrganizations = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadUserOrganizationsSuccess = (
  state = INITIAL_STATE,
  { userOrganizations }
) => ({
  ...state,
  userOrganizations
})

const loadUserOrganizationsByModule = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadUserOrganizationsByModuleSuccess = (
  state = INITIAL_STATE,
  { organizations, module }
) => ({
  ...state,
  userOrganizationsByModule: { organizations, module }
})

const setCurrentOrganization = (state = INITIAL_STATE, { organization }) => ({
  ...state,
  currentOrganization: organization
})

const clearCurrentOrganization = (state = INITIAL_STATE) => ({
  ...state,
  currentOrganization: {},
  currentOrganizationAddresses: [],
  userOrganizationsByModule: INITIAL_STATE.userOrganizationsByModule
})

const loadCurrentOrganizationAddresses = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error
})

const loadCurrentOrganizationAddressesSuccess = (
  state = INITIAL_STATE,
  { addresses }
) => ({
  ...state,
  currentOrganizationAddresses: addresses
})

const organizationError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.ORGANIZATION_ERROR]: organizationError,

  [Types.LOAD_USER_ORGANIZATIONS]: loadUserOrganizations,
  [Types.LOAD_USER_ORGANIZATIONS_SUCCESS]: loadUserOrganizationsSuccess,

  [Types.LOAD_USER_ORGANIZATIONS_BY_MODULE]: loadUserOrganizationsByModule,
  [Types.LOAD_USER_ORGANIZATIONS_BY_MODULE_SUCCESS]: loadUserOrganizationsByModuleSuccess,

  [Types.SET_CURRENT_ORGANIZATION]: setCurrentOrganization,
  [Types.CLEAR_CURRENT_ORGANIZATION]: clearCurrentOrganization,

  [Types.LOAD_CURRENT_ORGANIZATION_ADDRESSES]: loadCurrentOrganizationAddresses,
  [Types.LOAD_CURRENT_ORGANIZATION_ADDRESSES_SUCCESS]: loadCurrentOrganizationAddressesSuccess,

  [AuthenticationTypes.LOGOUT]: logout
})

export { Types as OrganizationTypes, Creators as OrganizationActions }
