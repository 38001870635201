import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getMedicationControls } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { MedicationControlActions } from '@smartcoop/stores/medicationControl'
import { selectCurrentProperty, selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { MEDICATION_CONTROL_COLORS, MEDICATION_CONTROL_NAMES } from '@smartcoop/utils/constants'
import {
  momentBackDateFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterMedicationControlModal from '@smartcoop/web-containers/modals/dairyFarm/FilterMedicationControlModal'
import RegisterMedicationControlModal from '@smartcoop/web-containers/modals/dairyFarm/MedicationControlModal'

import { Container, Row, RightContainer, Text } from './styles'

const ListMedicationControlFragment = () => {
  const [filters, setFilters] = useState({})
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const snackbar = useSnackbar()
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)
  const propertyId = useSelector(selectCurrentPropertyId)

  const dispatch = useCallback(useDispatch(), [])

  const dairyFarm = useMemo(() => currentProperty?.dairyFarm[0], [currentProperty])
  const urlParams = useMemo(() => ({ propertyId }), [propertyId])

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const handleEditClick = useCallback(
    (_, data) => {
      createDialog({
        id: 'edit-pev',
        Component: RegisterMedicationControlModal,
        props: {
          defaultValues: data,
          reloadDataTable
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  const handleDeleteClick = useCallback(
    (_, data) =>{
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(MedicationControlActions.deleteMedicationControl(
              { ids: [data.animalId] },
              data.registerCode,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('medicine')
                  })
                )
                reloadDataTable()
              },
              reloadDataTable
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('medicine')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  const columns = useMemo(
    () => [
      {
        title: t('type'),
        field: 'type',
        render: (row) => (
          <div style={ { display: 'flex' } }>
            <Badge
              backgroundColorBadge={ MEDICATION_CONTROL_COLORS[row?.type] }
              colorBadge={ MEDICATION_CONTROL_COLORS[row?.type] }
            >
              {MEDICATION_CONTROL_NAMES[row.type]}
            </Badge>
          </div>
        )
      },
      {
        title: t('initial date'),
        field: 'medicationStartDate',
        render: (row) =>
          moment(
            row?.medicationStartDate,
            momentBackDateFormat
          ).format(momentFriendlyDateFormat),
        sorting: true,
        align: 'center',
        defaultSort: 'desc'
      },
      {
        title: t('final date'),
        field: 'medicationEndDate',
        render: (row) =>
          <Text
            style={ {
              color: moment(row?.medicationEndDate, momentBackDateFormat).isAfter(moment()) && moment(row?.medicationStartDate, momentBackDateFormat).isBefore(moment()) ? colors.secondary : '#000',
              fontWeight: moment(row?.medicationEndDate, momentBackDateFormat).isAfter(moment()) && moment(row?.medicationStartDate, momentBackDateFormat).isBefore(moment()) ? 600 : 400
            } }
          >
            {moment(
              row?.medicationEndDate,
              momentBackDateFormat
            ).isValid() ? moment(
                row?.medicationEndDate,
                momentBackDateFormat
              ).format(momentFriendlyDateFormat) : '-'}
          </Text>
        ,
        align: 'center',
        sorting: true
      },
      {
        align: 'center',
        title: t('treatment time'),
        field: 'treatmentTime',
        render: (row) => `${ row?.treatmentTime } ${ t('days') }`
      },
      {
        title: t('carency liberation date'),
        field: 'carencyLiberationDate',
        align: 'center',
        render: (row) =>
          <Text
            style={ {
              color: moment(row?.medicationEndDate, momentBackDateFormat).add(row?.carencyTime, 'days').isAfter(moment().format(momentBackDateFormat)) && moment(row?.medicationStartDate, momentBackDateFormat).isSameOrBefore(moment().format(momentBackDateFormat)) ? colors.secondary : '#000',
              fontWeight: moment(row?.medicationEndDate, momentBackDateFormat).add(row?.carencyTime, 'days').isAfter(moment().format(momentBackDateFormat)) && moment(row?.medicationStartDate, momentBackDateFormat).isSameOrBefore(moment().format(momentBackDateFormat)) ? 600 : 400
            } }
          >
            {moment(row?.medicationEndDate, momentBackDateFormat).add(row?.carencyTime, 'days').isValid() ? moment(row?.medicationEndDate, momentBackDateFormat).add(row?.carencyTime, 'days').format(momentFriendlyDateFormat) : '-'}
          </Text>
      },
      {
        align: 'center',
        title: t('reason '),
        field: 'animalDiseases',
        render: (row) => join(map(row?.animalDiseases, item => item.name), ', ')
      }
    ],
    [t]
  )

  const handleFilter = useCallback((values) => setFilters(values), [])

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-medication-control',
      Component: FilterMedicationControlModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const registerMedicationControl = useCallback(() => {
    createDialog({
      id: 'register-medication-control',
      Component: RegisterMedicationControlModal,
      props: {
        reloadDataTable,
        defaultValues: {
          animal: {
            id: currentAnimal?.id
          }
        }
      }
    })
  }, [createDialog, currentAnimal.id, reloadDataTable])

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  return (
    <Container>
      <Row>
        <RightContainer>
          <Button
            id="filter"
            color="white"
            style={ {
              flex: 'none',
              whiteSpace: 'nowrap',
              backgroundColor: isEmpty(filters)
                ? colors.white
                : colors.secondary
            } }
            onClick={ filterData }
            disabled={ isEmpty(dairyFarm) }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="new-pev"
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
            onClick={ registerMedicationControl }
          >
            <I18n>register</I18n>
          </Button>
        </RightContainer>
      </Row>

      {!isEmpty(dairyFarm) && (
        <DataTable
          tableRef={ tableRef }
          data={ getMedicationControls }
          queryParams={ {
            ...filters,
            animalId: currentAnimal?.id,
            from: filters.from && filters.from[0],
            to: filters.to && filters.to[0]
          } }
          urlParams={ urlParams }
          columns={ columns }
          onEditClick={ handleEditClick }
          onDeleteClick={ handleDeleteClick }
          disabled={ !userWrite }
          id="list-medication-control-table"
        />
      )}
    </Container>
  )
}

export default ListMedicationControlFragment
