/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import iconV from 'iconv-lite'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import { uuid } from 'short-uuid'
import XLSX from 'xlsx'


import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import replace from 'lodash/replace'
import some from 'lodash/some'
import toNumber from 'lodash/toNumber'
import uniqBy from 'lodash/uniqBy'

import I18n, { useT } from '@smartcoop/i18n'
import { trash } from '@smartcoop/icons'
import { getMilkControl } from '@smartcoop/services/apis/smartcoopApi/resources/herdsManagement'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectAnimals } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentBackDateTimeFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { convertToCSV } from '@smartcoop/utils/files'
import { formatKebabCase } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import InputFile from '@smartcoop/web-components/InputFile'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import Spreadsheet from '@smartcoop/web-components/Spreadsheet'
import useFile from '@smartcoop/web-containers/hooks/useFile'

import { ButtonsContainer, SpreadSheetContainer, AlertText } from './styles'

import './styles.css'

const ImportMilkControlModal = ({ id, open, handleClose, reloadDataTable }) => {
  const {
    selectedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    clearFiles
  } = useFile([], [])

  const snackbar = useSnackbar()
  const dispatch = useDispatch()
  const t = useT()

  const animals = useSelector(selectAnimals)
  const currentProperty = useSelector(selectCurrentProperty)

  const numericColumns = {
    'del': 'del',
    'volume': 'volume',
    'ccs': 'ccs',
    'fat': 'fat',
    'protein': 'protein',
    'lactose': 'lactose',
    'totalSolids': 'totalSolids',
    'nul': 'nul',
    'esd': 'esd',
    'casein': 'casein'
  }

  const defaultColumns = [
    {
      name:  t('date', { howMany: 1 }),
      slug: 'date'
    },
    {
      name: t('earring'),
      slug: 'animalId'
    },
    {
      name: t('DEL'),
      slug: 'del'
    },
    {
      name: `${ t('volume') } (L)`,
      slug: 'volume'
    },
    {
      name: `${ t('ccs') } (Cél/ml)`,
      slug: 'ccs'
    },
    {
      name: `${ t('fat') } (%)`,
      slug: 'fat'
    },
    {
      name: `${ t('protein') } (%)`,
      slug: 'protein'
    },
    {
      name:  `${ t('lactose') } (%)`,
      slug: 'lactose'
    },
    {
      name: `${ t('total solids') } (%)`,
      slug: 'totalSolids'
    },
    {
      name:  `${ t('nul') } (mg/dL)`,
      slug: 'nul'
    },
    {
      name:  `${ t('esd') } (%)`,
      slug: 'esd'
    },
    {
      name: `${ t('casein') } (%)`,
      slug: 'casein'
    },
    {
      name: 'Lote',
      slug: 'lotId'
    },
    {
      name: 'Último Parto',
      slug: 'lastBirth'
    },
    {
      name: 'ID',
      slug: 'id'
    },
    {
      name: '',
      slug: 'delete'
    }
  ]

  const [data, setData] = useState([])
  const [lots, setLots] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [datesOptions, setDatesOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [userDairysControl, setUserDairysControl] = useState([])
  const [automaticLotHandling, setAutomaticLotHandling] = useState(false)

  const exportCSVFile = () => {
    const dataToTransform = map(data, item => map(item, ({ name, value }) => ({ [name]: value })))

    dataToTransform.unshift(map(defaultColumns, ({ name }) => ({ [name]: name })))

    const exportedData = map(dataToTransform, item => map(item, subItem => Object.values(subItem)))

    const csv = convertToCSV(exportedData)

    const exportedFilename = 'planilha_controle_leiteiro_smartcoop.csv'

    const blob = new Blob([`\uFEFF${  csv }`], { type: 'text/csv;charset=utf-8,%EF%BB%BF' })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const loadMilkControlList = useCallback(
    async () => {
      const { data: { data: response } } = await getMilkControl({ limit: 9999 }, { propertyId: currentProperty?.id })
      setUserDairysControl(response)
      const groupedAnimalsByDate = groupBy(response, 'date')
      const dates = map(uniqBy(response, 'date'), item => ({ label: moment(item?.date, momentBackDateFormat).format(momentFriendlyDateFormat), value: item?.date, animals: groupedAnimalsByDate?.[item?.date] }))
      const lactatingAnimals = map(animals.filter(animal => animal.lot.slug === 'em-lactacao'), item => {
        const current = { ...item, animalId: item?.id }
        delete current.id

        return current
      })
      setDatesOptions([{ label: 'Em lactação', value: 'em-lactacao', animals: lactatingAnimals }, ...dates])
    },
    [animals, currentProperty]
  )

  const loadSheetSelectData = useCallback(
    () => {
      dispatch(AnimalActions.loadAnimalLots((response) => {
        setLots(keyBy(filter(response, item => item?.slug === 'em-lactacao'), (item) => formatKebabCase(item?.name)))
      }))
      dispatch(AnimalActions.loadAnimals({ limit: 9999, category: 'vaca' }))
    },
    [dispatch]
  )

  useEffect(() => {
    loadMilkControlList()
  },[animals, loadMilkControlList])

  useEffect(() => {
    loadSheetSelectData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react/prop-types
  const SelectLots = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `lots-${ uuid() }}` }
      key={ `lots-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="lot"
      options={ map(lots, item => ({ label: item?.name, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[lots])

  // eslint-disable-next-line react/prop-types
  const SelectEarring = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `earring-${ uuid() }}` }
      key={ `earring-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="lot"
      options={ map(animals, item => ({ label: item?.earring?.earringCode, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[animals])

  const renderEditor = useCallback((isView, slug) => {
    switch (slug) {
      case 'lotId':
        return ({ cell, onChange }) => SelectLots({ isView, cell, onChange })
      case 'animalId':
        return ({ cell, onChange }) => SelectEarring({ isView, cell, onChange })
      case 'delete':
        return () => <></>
      default:
        return null
    }
  }, [SelectEarring, SelectLots])

  const getItemId = useCallback((slug, value) => {
    let response = null

    if(isNil(value)) {
      return ''
    }

    switch (slug) {
      case 'lotId':
        response = lots?.[formatKebabCase(value)]
        return response?.id || ''
      case 'animalId':
        response = find(animals, item => item?.earring?.earringCode === value)
        return response?.id || ''
      default:
        return null
    }
  },[animals, lots])

  const validateValueBySlug = useCallback((slug, value, item) => {
    if(isNil(value)) {
      return ''
    }
    if(
      slug === 'date'
    ) {
      if(moment(value, 'DD-MM-YYYY').isValid()) {
        return moment(value, 'DD-MM-YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'D/M/YYYY').isValid()) {
        return moment(value, 'D/M/YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'DDMMYYYY').isValid()) {
        return moment(value, 'DDMMYYYY').format('DD/MM/YYYY')
      } if(moment(value, 'M/D/YYYY').isValid()) {
        return moment(value, 'M/D/YYYY').format('DD/MM/YYYY')
      }  if(moment(value, 'M/DD/YYYY').isValid()) {
        return moment(value, 'M/DD/YYYY').format('DD/MM/YYYY')
      }  if(moment(value, 'MM/DD/YYYY').isValid()) {
        return moment(value, 'MM/DD/YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'YYYY-MM-DD').isValid()) {
        return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    }
    if(slug === 'lotId') {
      const response = lots?.[formatKebabCase(value)]
      return response?.name || ''
    }
    if(slug === 'lastBirth') {
      if(moment(value, momentFriendlyDateFormat).isValid()) {
        return value || ''
      }
      if(moment(value, momentBackDateTimeFormat).isValid()) {
        return moment(value, momentBackDateTimeFormat).format(momentFriendlyDateFormat) || ''
      }
      if(moment(item?.animal?.lastBirth, momentBackDateTimeFormat).isValid()) {
        return moment(item?.animal?.lastBirth, momentBackDateTimeFormat).format(momentFriendlyDateFormat) || ''
      }
      const foundAnimalId = find(item, column => column?.slug === 'animalId')?.id
      const currentAnimal = find(animals, animal => animal?.id === foundAnimalId)
      if(moment(currentAnimal?.lastBirth, momentBackDateTimeFormat).isValid()) {
        return moment(currentAnimal?.lastBirth, momentBackDateTimeFormat).format(momentFriendlyDateFormat) || ''
      }
      return ''
    }

    return value
  },[animals, lots])

  const validateErrorBySlugOnSend = useCallback((slug, value, cellId, row) => {
    switch(slug) {
      case 'animalId': {
        const earringArray = map(filter(flatten(data), item => item.slug === 'animalId'), item => item.value)
        const filteredEarrings = filter(earringArray, item => item === value)
        return isEmpty(value) || filteredEarrings.length > 1 || isEmpty(cellId)
      }
      case 'date':
        return isEmpty(value) || !moment(value, 'DD/MM/YYYY').isValid() || moment(value, 'DD/MM/YYYY').isAfter(moment())
      case 'id': {
        const idArray = map(userDairysControl, 'id')
        const idsFromData = map(filter(flatten(data), item => item.slug === 'id'), item => item.value)

        const idsExists = some(idsFromData, subId => includes(idArray, subId))

        return !idsExists && !isEmpty(value)
      }
      case 'lotId':
        return isEmpty(value)
      case 'del':
        // eslint-disable-next-line no-case-declarations
        const hasVol = filter(row, item => item?.slug === 'volume' && toNumber(item?.value) > 0)
        return !isEmpty(hasVol) && isEmpty(String(value)) && toNumber(value || '0') < 0
      default:
        return false
    }
  }, [data, userDairysControl])

  const validateDataToSave = useCallback((slug, value) => {
    switch(slug) {
      case 'date':
        return !isEmpty(value) ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
      default:
        return replace(value, ',', '.')
    }
  }, [])

  const getInputWidth = (slug) => {
    switch(slug) {
      case 'lotId':
        return 150
      case 'animalId':
        return 150
      default:
        return 100
    }
  }

  const handleDeleteRow = useCallback((rowIndex) => {
    const updatedData = data.filter((_, index) => index !== rowIndex)
    setData(updatedData)
  }, [data])

  const DataViewer = ({ cell, row, columnIndex }) => {
    switch (cell?.slug) {
      case 'delete':
        return (
          <div style={ { width: '6em', textAlign: 'center' } }>
            <IconButton
              id="delete"
              variant="text"
              onClick={ () => handleDeleteRow(row) }
            >
              <Icon
                icon={ trash }
                size={ 18 }
                color={ colors.muted }
              />
            </IconButton>
          </div>
        )
      default:
        return (
          <input
            value={ cell?.value || '' }
            onChange={ (e) => {
              const newData = [...data]
              newData[row][columnIndex].value = e.target.value
              setData(newData)
            } }
            style={ {
              textAlign: numericColumns[cell?.slug] ? 'right' : 'left',
              width: getInputWidth(cell?.slug),
              height: 40,
              fontSize: 14,
              border: 'none',
              cursor: 'cell'
            } }
          />
        )
    }
  }

  const DataEditor = ({ cell, onChange, row }) => {
    switch (cell?.slug) {
      case 'delete':
        return (
          <div style={ { width: '6em', textAlign: 'center' } }>
            <IconButton
              id="delete"
              variant="text"
              onClick={ () => handleDeleteRow(row) }
            >
              <Icon
                icon={ trash }
                size={ 18 }
                color={ colors.red }
              />
            </IconButton>
          </div>
        )
      case 'lotId':
        return <SelectLots isView={ false } cell={ cell } onChange={ onChange } />
      case 'animalId':
        return <SelectEarring isView={ false } cell={ cell } onChange={ onChange } />
      default:
        return (
          <input
            value={ cell?.value || '' }
            onChange={ (e) => onChange({ ...cell, value: e.target.value }) }
            style={ {
              textAlign: numericColumns[cell?.slug] ? 'right' : 'left',
              width: getInputWidth(cell?.slug),
              height: 40,
              fontSize: 14,
              border: 'none',
              cursor: 'cell'
            } }
          />
        )
    }
  }


  useEffect(() => {
    if(!isEmptyFiles) {
      setLoading(true)
      setData([])
      const reader = new FileReader()
      reader.onload = function(e) {
        // o retorno de e.target.result é um objeto com 2 arrays de codificação
        // é criado o array pra Uint8

        let response = new Uint8Array(e.target.result)

        // é decodificado pra string pra poder transformar os caracteres especiais
        const decodedString = new TextDecoder().decode(response)

        const foundError = decodedString.search('workbook.xml')

        if(foundError === -1) {
          const reencoded = iconV.encode(`\uFEFF${ iconV.decode(decodedString, 'iso-8859-1') }`, 'utf8')

          // é encodificado novamente pro array de Uint8
          response = new TextEncoder().encode(reencoded)
        }
        // é transformador pra iso-8869-1 (padrão PT-br) e depois pra utf8 novamente
        const workbook = XLSX.read(response, { type: 'array', raw: true })
        const name = workbook.SheetNames
        const worksheet = workbook.Sheets[name[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        let arrayData = []
        map(jsonData, (item, index) => {
          arrayData[index] = map(item, (value, subIndex) => ({
            name: subIndex,
            value,
            slug: find(defaultColumns, defaultColumn => defaultColumn.name === subIndex).slug
          }))
        })

        arrayData = map(arrayData, item => map(item, subItem => ({
          ...subItem,
          id: getItemId(subItem.slug, subItem.value, item),
          value: validateValueBySlug(subItem.slug, subItem.value, item),
          className: subItem?.slug === 'delete' ? 'cell-width-replaced' : !isEmpty(subItem.value) && validateValueBySlug(subItem.slug, subItem.value, item) === '' ? 'error' : ''
        })))

        const arrayMapped = map(arrayData, (item) =>
          map(defaultColumns, (column) => {
            const itemFound = find(item, subItem => subItem.slug === column.slug)
            return { ...column, className: column?.slug === 'delete' ? 'cell-width-replaced' : !isEmpty(itemFound?.value) && validateValueBySlug(column.slug, itemFound?.value, item) === '' ? 'error' : '', readOnly: column.slug === 'lastBirth' || column.slug === 'id', value: validateValueBySlug(column.slug, itemFound?.value, item) }})
        )

        setData(arrayMapped)
        setLoading(false)
      }
      reader.readAsArrayBuffer(selectedFiles[0])
      clearFiles()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(() => {
    if(!isEmpty(lots) && !isEmpty(animals)) {
      setData([
        map(defaultColumns, ({ name, slug }) => ({ name, slug, value: '', readOnly: slug === 'lastBirth' || slug === 'id', className: slug === 'delete' ? 'cell-width-replaced' : '' }))
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lots, animals])

  const handleChange = useCallback(
    debounce((newData) => {
      const newDataUpdated = map([...newData], (item) => flatMap(defaultColumns, (column, index) => ({
        ...column,
        value: item[index]?.value || null,
        id: getItemId(column?.slug, item[index]?.value, item),
        className: column?.slug === 'delete' ? 'cell-width-replaced' : !isEmpty(item[index]?.value) && validateValueBySlug(column.slug, item[index]?.value, item) === '' ? 'error' : '',
        readOnly: column.slug === 'lastBirth' || column.slug === 'id'
      })))

      setData(newDataUpdated)
    }, 1000),
    [getItemId, renderEditor, validateValueBySlug, data]
  )

  const finishSubmit = useCallback(
    () => {
      let hasError = false
      hasError = !!find(flatten(data), item => item.className === 'error')

      if(hasError) {
        snackbar.error('Existem campos com erro.')
        setLoading(false)
        setSubmitted(false)
      } else {
        dispatch(AnimalActions.saveMilkControl({
          data: map(data, item => {
            const obj = {}
            map(item, subItem => {
              if(subItem?.slug !== 'delete') {
                // eslint-disable-next-line no-nested-ternary
                obj[subItem?.slug] = !isEmpty(subItem?.id) ? subItem?.id : subItem.value ? validateDataToSave(subItem.slug, subItem.value) : ''
              }
            })
            return obj
          }),
          automaticLotHandling
        },
        () => {
          snackbar.success(t('success on importing milk control'))
          setLoading(false)
          setSubmitted(false)
          reloadDataTable()
          handleClose()
        },
        (error) => {
          snackbar.error(t(error))
          setLoading(false)
          setSubmitted(false)
        },
        t
        ))
      }
    },
    [data, dispatch, handleClose, reloadDataTable, snackbar, t, validateDataToSave, automaticLotHandling]
  )

  const handleSubmit = useCallback(
    () => {
      setLoading(true)
      setData(
        map([...data], item => map(item, subItem => ({
          ...subItem,
          className: validateErrorBySlugOnSend(subItem.slug, subItem.value, getItemId(subItem.slug, subItem.value, item)) ? 'error' : '', item,
          id: getItemId(subItem.slug, subItem.value, item)
        })))
      )
      setTimeout(() => {
        setSubmitted(true)
      }, 2000)
    },
    [data, getItemId, validateErrorBySlugOnSend]
  )

  useEffect(() => {
    if(submitted) {
      finishSubmit()
    }
  }, [finishSubmit, submitted])

  useEffect(() => {
    if(selectedOption) {
      setLoading(true)
      const arrayMapped = map(selectedOption?.animals, (item) =>
        map(defaultColumns, (column) => {
          const value = item?.[column?.slug] || ''
          const response = { ...column, value: validateValueBySlug(column?.slug, value, item), readOnly: column.slug === 'lastBirth' || column.slug === 'id' }
          if(column?.slug === 'date') {
            response.value = moment(selectedOption?.label, momentFriendlyDateFormat).isValid() ? selectedOption?.label : ''
          }
          if(column?.slug === 'animalId') {
            response.id = item?.animal?.id || item?.id
            response.value = item?.animal?.earring?.earringCode || item?.earring?.earringCode
          }
          if(column?.slug === 'lotId') {
            response.id = item?.lot?.id
            response.value = item?.lot?.name
          }
          return response
        })
      )

      setData(arrayMapped)
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedOption])

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      disableEsc
      title={
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <I18n>import milk control</I18n>
        </div>
      }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
    >
      <div style={ { display: 'flex', flex: 1, flexDirection: 'column', flexWrap: 'wrap', padding: '30px' } }>
        <div style={ { display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' } }>
          <InputSelect
            id="selector-dates"
            key="selector-dates"
            detached
            style={ { width: 200 } }
            name="selector-dates"
            label="Trazer animais"
            options={ datesOptions  }
            disabled={ isEmpty(datesOptions) }
            value={ selectedOption?.value }
            onChange={ (_, item) => {
              setSelectedOption(item)
            } }
          />
          <InputFile
            idInput="contained-button-file-1"
            onChange={ handleAdd }
            inputProps={ {
              accept: '.xlsx,.csv'
            } }
            buttonProps={ {
              id: 'add-file',
              variant: 'outlined',
              style: {
                backgroundColor: 'white'
              },
              fullWidth: true,
              disabled: loading
            } }
            style={ { width: 200, marginLeft: 20 } }
          >
            <I18n>import file</I18n>
          </InputFile>
          <Button
            id="download-sheet"
            color="primary"
            onClick={ exportCSVFile }
            style={ { marginLeft: 20, maxWidth: 200, height: 40 } }
            disabled={ loading }
          >
            <I18n>download sheet</I18n>
          </Button>
        </div>
        <AlertText>
          *<I18n>when exporting the sheet, keep the column names as the original model</I18n>
        </AlertText>
        {loading ? <Loader /> : (
          <SpreadSheetContainer>
            <Spreadsheet
              data={ data }
              onChange={ handleChange }
              columnLabels={ map(defaultColumns, item => item.name) }
              DataViewer={ DataViewer }
              DataEditor={ DataEditor }
            />
          </SpreadSheetContainer>
        )}
        <br />
        <CheckboxButton
          label="Realiza a troca automática de lote dos animais conforme Controle Leiteiro."
          variant="row"
          checked={ automaticLotHandling }
          onChange={ () => setAutomaticLotHandling(!automaticLotHandling) }
        />
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <ButtonsContainer>
            <Button
              id="add-line"
              color="primary"
              style={ { marginRight: 10, maxWidth: 20 } }
              disabled={ loading }
              onClick={ () => setData([...data, map(defaultColumns, ({ name, slug }) => ({ name, slug, value: '',  readOnly: slug === 'lastBirth' || slug === 'id', className: slug === 'delete' ? 'cell-width-replaced' : '' }))]) }
            >
            +
            </Button>
            <Button
              id="remove-line"
              color="primary"
              onClick={ () => setData(old => old.slice(0, -1 )) }
              disabled={ data.length <= 1 || loading }
              style={ { maxWidth: 20 } }
            >
            -
            </Button>
          </ButtonsContainer>
          <ButtonsContainer style={ { justifyContent: 'flex-end' } }>
            <Button
              id="save"
              color="secondary"
              onClick={ handleSubmit }
              style={ { maxWidth: 100 } }
              disabled={ loading }
            >
            Salvar
            </Button>
          </ButtonsContainer>
        </div>
      </div>
    </Modal>
  )}

ImportMilkControlModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadDataTable: PropTypes.func.isRequired
}

ImportMilkControlModal.defaultProps = {
}

export default ImportMilkControlModal
