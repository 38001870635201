/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
import Control from 'react-leaflet-control'

import L from 'leaflet'

import { withStyles } from '@material-ui/core/styles'


import styles from './styles'

if (typeof window !== 'undefined') {
  (function() {
    // Extend Map._initControlPos to also create a topcenter-container
    L.Map.prototype._initControlPos = function( _initControlPos ) {
      return function() {
        // Original function/method
        _initControlPos.apply(this, arguments)

        // Adding new control-containers

        // topcenter is the same as the rest of control-containers
        this._controlCorners.topcenter = L.DomUtil.create('div', 'leaflet-top leaflet-center', this._controlContainer)

        // bottomcenter need an extra container to be placed at the bottom
        this._controlCorners.bottomcenter = L.DomUtil.create(
          'div',
          'leaflet-bottom leaflet-center',
          L.DomUtil.create('div', 'leaflet-control-bottomcenter', this._controlContainer)
        )
      }
    }(L.Map.prototype._initControlPos)
  })()
}

export default withStyles(styles)(Control)
