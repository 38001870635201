import React, { useCallback, forwardRef, useState, useEffect, useMemo } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import { isEmpty } from 'lodash/fp'
import map from 'lodash/map'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

import registerOrganizationSchema from '@smartcoop/forms/schemas/shoppingPlatform/organization/registerOrganization.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { searchCep } from '@smartcoop/services/apis/brasilApi'
import { searchStates, searchCities } from '@smartcoop/services/apis/ibgeApi'
import { getOrganizations as getOrganizationsService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import {
  selectUserProfileIsSmartcoop,
  selectUserProfileIsBuyer,
  selectUserProfileIsAdmin
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { OrganizationActions } from '@smartcoop/stores/organization'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputCpfCnpj from '@smartcoop/web-components/InputCpfCnpj'
import InputEmail from '@smartcoop/web-components/InputEmail'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { TitleContainer, FieldsContainer, Item, Row } from './styles'

const CreateOrganizationForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    onSubmit,
    style,
    files,
    oldFiles,
    disableFile,
    setIsLoading
  } = props
  const t = useT()
  const location = useLocation()

  const currentOrganization = useSelector(selectCurrentOrganization)
  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)
  const userProfileIsBuyer = useSelector(selectUserProfileIsBuyer)
  const userProfileIsAdmin = useSelector(selectUserProfileIsAdmin)

  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [isSubsidiary, setIsSubsidiary] = useState(location.state?.organization?.isSubsidiary ?? true)
  const [subsidiary] = useState(location.state?.organization?.isSubsidiary ?? true)
  const dispatch = useCallback(useDispatch(), [])

  const situationOptions = useMemo(
    () => (
      [
        {
          label: t('yes'),
          value: true
        },
        {
          label: t('no'),
          value: false
        }
      ]
    ),
    [t]
  )

  const typeOptions = useMemo(
    () => {
      const options = [
        {
          label: t('headquarter', { howMany: 1 }),
          value: false
        },
        {
          label: t('branch/unit'),
          value: true
        }
      ]

      return options.filter((value) => !userProfileIsSmartcoop && subsidiary ? value.value === true : true)
    },
    [subsidiary, t, userProfileIsSmartcoop]
  )

  const values = useMemo(
    () => (
      {
        active: location.state?.organization?.active ?? true,
        companyDocument: location.state?.organization?.companyDocument ?? '',
        stateRegistration: location.state?.organization?.stateRegistration ?? '',
        companyName: location.state?.organization?.companyName ?? '',
        tradeName: location.state?.organization?.tradeName ?? '',
        headquarter: location.state?.organization?.headquarter?.id ?? null,
        isSubsidiary: location.state?.organization?.isSubsidiary ?? true,
        city: location.state?.organization?.city ?? '',
        state: location.state?.organization?.state ?? '',
        postalCode: location.state?.organization?.postalCode ?? '',
        street: location.state?.organization?.street ?? '',
        neighborhood: location.state?.organization?.district ?? '',
        email: location.state?.organization?.organizationEmails[0]?.email ?? '',
        addressComplement: location.state?.organization?.addressComplement ?? '',
        nfe: location.state?.organization?.nfe ?? '',
        number: location.state?.organization?.number ?? ''
      }
    ), [location]
  )

  const currentOrganizationId = useMemo(
    () => currentOrganization.id,
    [currentOrganization]
  )

  const disabledOrganizationSelect = useMemo(
    () => !!(!userProfileIsSmartcoop && (values.headquarter ?? currentOrganizationId)),
    [currentOrganizationId, userProfileIsSmartcoop, values]
  )

  const userCanSave = useMemo(
    () => userProfileIsSmartcoop || (userProfileIsBuyer || userProfileIsAdmin),
    [userProfileIsAdmin, userProfileIsBuyer, userProfileIsSmartcoop]
  )

  const handleSuccess = useCallback(
    (organization, label) => {
      if (!isSubsidiary && !isEmpty(files)) {
        const formData = new FormData()
        forEach(files, (file) => {
          formData.append('upload', file)
        })

        dispatch(OrganizationActions.addOrganizationFiles(
          formData,
          organization.id,
          () => onSubmit(label),
          () => setIsLoading(false)
        ))
      } else {
        onSubmit(label)
      }
    },
    [dispatch, files, isSubsidiary, onSubmit, setIsLoading]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      if(!isEmpty(location.state?.organization)) {
        dispatch(OrganizationActions.updateOrganization(
          {
            ...location?.state?.organization,
            ...data,
            companyDocument: data.companyDocument.replace(/\D/g, ''),
            organizationEmails: [{ email: data.email }],
            postalCode: data.cep.replace(/\D/g, ''),
            district: data.neighborhood,
            oldFiles: isSubsidiary ? [] : oldFiles
          },
          (organization) => handleSuccess(organization, 'update'),
          () => setIsLoading(false)
        ))

      } else {
        dispatch(OrganizationActions.createOrganization(
          {
            ...data,
            companyDocument: data.companyDocument.replace(/\D/g, ''),
            organizationEmails: [{ email: data.email }],
            postalCode: data.cep.replace(/\D/g, ''),
            district: data.neighborhood
          },
          (organization) => handleSuccess(organization, 'create'),
          () => setIsLoading(false)
        ))

      }
    },
    [dispatch, handleSuccess, isSubsidiary, location, oldFiles, setIsLoading]
  )

  const handleStateChange = useCallback(
    async (value) => {
      if (value) {
        let data = await searchCities(value)
        data = map(data, ({ nome }) => ({ label: nome, value: nome }))
        setCityOptions(data)

      } else {
        setCityOptions([])
        formRef.current.clearField('city')
      }
    },
    [formRef]
  )

  const receiveAddress = useCallback(
    ({ ...address }) => {
      formRef.current.setData({
        ...address
      })
    },
    [formRef]
  )

  useEffect(
    () => {
      async function findStates() {
        let data = await searchStates()
        data = map(data, ({ sigla }) => ({ label: sigla, value: sigla }))
        setStateOptions(data)
      }
      findStates()
    },
    []
  )

  const handleChange = useCallback(
    (e) => {
      setIsSubsidiary(e.target.value)
      disableFile(e.target.value)
    },
    [disableFile]
  )

  const handleEdit = useCallback(
    async (value) => {
      await handleStateChange(value.state)
      const address = await searchCep(value.postalCode)
      formRef.current.setData({
        ...{ city: address.city }
      })
    },
    [formRef, handleStateChange]
  )

  useEffect(() => {
    if (values.state) {
      handleEdit(values)
    } else {
      setCityOptions([])
      formRef.current.clearField('city')
    }
  },
  [formRef, handleEdit, handleStateChange, values])

  useEffect(
    () => {
      if(!isSubsidiary) {
        formRef.current.clearField('organizationId')
      }
    },
    [formRef, isSubsidiary])

  return (
    <Form
      style={ { ...style } }
      ref={ formRef }
      schemaConstructor={ registerOrganizationSchema }
      onSubmit={ handleSubmit }
    >
      <Grid container>
        <Row>
          <TitleContainer>
            <I18n style={ { fontSize: 16, fontWeight: 600 } }>organization data</I18n>
          </TitleContainer>
          <Divider style={ { marginTop: 8, marginBottom: 15 } }/>
        </Row>

        <FieldsContainer>
          <Item style={ { marginBottom: 10 } }>
            <RadioGroup
              label={ t('active register?') }
              name="active"
              options={ situationOptions }
              variant="row"
              defaultValue={ values.active }
            />
          </Item>
          <Item style={ { marginBottom: 10 } }>
            <RadioGroup
              label="NFE?"
              name="nfe"
              options={ situationOptions }
              variant="row"
              defaultValue={ values.nfe }
            />
          </Item>

          <Item>
            <InputCpfCnpj
              name="companyDocument"
              label={ t('company document') }
              onlyCnpj
              fullWidth
              defaultValue={ values.companyDocument }
            />
          </Item>

          <Item>
            <InputText
              name="stateRegistration"
              label={ t('state registration') }
              maxLength={ 13 }
              fullWidth
              defaultValue={ values.stateRegistration }
            />
          </Item>

          <Item>
            <InputText
              name="companyName"
              label={ t('company name') }
              fullWidth
              defaultValue={ values.companyName }
            />
          </Item>

          <Item>
            <InputText
              name="tradeName"
              label={ t('trade name') }
              fullWidth
              defaultValue={ values.tradeName }
            />
          </Item>

          <Item>
            <RadioGroup
              name="isSubsidiary"
              options={ typeOptions }
              variant="row"
              onChange={ handleChange }
              defaultValue={ values.isSubsidiary }
            />
          </Item>

          <Item>
            <InputSelect
              label={ t('headquarter', { howMany: 1 }) }
              name="organizationId"
              options={ getOrganizationsService }
              queryParams={ { type: '1', isSubsidiary: false } }
              asyncOptionLabelField="companyName"
              defaultValue={ values.headquarter ?? currentOrganizationId }
              disabled={ disabledOrganizationSelect }
            />
          </Item>
        </FieldsContainer>

        <Row>
          <TitleContainer style={ { paddingTop: 20 } }>
            <I18n style={ { fontSize: 16, fontWeight: 600 } }>address and complements</I18n>
          </TitleContainer>
          <Divider style={ { marginTop: 8, marginBottom: 15 } }/>
        </Row>

        <FieldsContainer style={ { paddingBottom: 0 } }>
          <Item>
            <Grid container style={ { justifyContent: 'space-between' } }>
              <Grid item xs={ 2 }>
                <InputSelect
                  label={ t('uf') }
                  name="state"
                  onChange={ (e) => handleStateChange(e.target.value) }
                  options={ stateOptions }
                  fullWidth
                  defaultValue={ values.state }
                />
              </Grid>
              <Grid item xs={ 9 }>
                <InputSelect
                  label={ t('city', { howMany: 1 }) }
                  name="city"
                  options={ cityOptions }
                  fullWidth
                  defaultValue={ values.city }
                />
              </Grid>
            </Grid>
          </Item>

          <Item>
            <InputCep
              name="cep"
              label={ t('cep') }
              fullWidth
              onAddressChange={ receiveAddress }
              defaultValue={ values.postalCode }
            />
          </Item>

          <Item>
            <Grid container style={ { justifyContent: 'space-between' } }>
              <Grid item xs={ 8 }>
                <InputText
                  name="street"
                  label={ t('street') }
                  fullWidth
                  defaultValue={ values.street }
                />
              </Grid>
              <Grid item xs={ 3 }>
                <InputText
                  name="number"
                  label={ t('number') }
                  defaultValue={ values.number }
                />
              </Grid>
            </Grid>
          </Item>
          <Item>
            <InputText
              name="neighborhood"
              label={ t('neighborhood') }
              fullWidth
              defaultValue={ values.neighborhood }
            />
          </Item>

          <Item>
            <InputText
              name="addressComplement"
              label={ t('complement') }
              fullWidth
              defaultValue={ values.addressComplement }
            />
          </Item>

          <Item>
            <InputEmail
              name="email"
              label={ t('email') }
              fullWidth
              defaultValue={ values.email }
            />
          </Item>

          {!withoutSubmitButton && (
            <Button
              id="web-organization-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ !userCanSave }
            >
              <I18n>next</I18n>
            </Button>
          )}
        </FieldsContainer>

      </Grid>
    </Form>
  )
})

CreateOrganizationForm.propTypes = {
  onSubmit: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  style: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.object),
  oldFiles: PropTypes.arrayOf(PropTypes.object),
  disableFile: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
}

CreateOrganizationForm.defaultProps = {
  onSubmit: () => {},
  withoutSubmitButton: false,
  style: {},
  files: [],
  oldFiles: []
}

export default CreateOrganizationForm
