import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { ThemeProvider } from '@material-ui/core'

// import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import InputSearch from '@smartcoop/web-components/InputSearch'
import BarterListFragment from '@smartcoop/web-containers/fragments/commercialization/BarterListFragment'
import BarterPackageListFragment from '@smartcoop/web-containers/fragments/commercialization/BarterPackageListFragment'
// import CreateOrderModal from '@smartcoop/web-containers/modals/commercialization/CreateOrderModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Row,
  ButtonContainer,
  SearchContainer,
  inputSearchTheme,
  TabButton
} from './styles'

const BarterListScreen = () => {
  const history = useHistory()
  const t = useT()
  const { routes } = useRoutes()
  // const tableRef = useRef(null)

  // const { createDialog, removeDialog } = useDialog()

  const [listType, setListType] = useState('barter')
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const currentOrganization = useSelector(selectCurrentOrganization)

  const tableFilters = useMemo(
    () => debouncedFilterText ? ({ q: debouncedFilterText }) : ({}),
    [debouncedFilterText]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization]
  )

  const dataTableList = useMemo(
    () => {
      switch (listType) {
        case 'barter':
          return (
            <BarterListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
            />
          )
        case 'packages':
        default:
          return (
            <BarterPackageListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
            />
          )
      }
    },
    [listType, tableFilters, urlParams]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const onNewBarterClick = useCallback(
    () => {
      history.push(routes.createBarter.path)
    },
    [history, routes.createBarter.path]
  )

  return (
    <Container>
      <Row>
        <ButtonContainer>
          <TabButton
            id="barter"
            onClick={ () => setListType('barter') }
            color={ listType === 'barter' ? colors.black : colors.white }
          >
            <I18n>barter</I18n>
          </TabButton>
          <TabButton
            id="packages"
            onClick={ () => setListType('packages') }
            color={ listType === 'packages' ? colors.black : colors.white }
          >
            <I18n params={ { howMany: 2 } }>package</I18n>
          </TabButton>
        </ButtonContainer>
        <SearchContainer>
          <ThemeProvider theme={ inputSearchTheme }>
            <InputSearch
              detached
              onChange={ onChangeSearchFilter }
              value={ filterText }
              placeholder={ t('search') }
              fullWidth
            />
          </ThemeProvider>
          <Button
            id="filter-order"
            onClick={ onNewBarterClick }
            color={ colors.secondary }
            style={ { flex: '0 0 auto', marginBottom: 22, marginLeft: 8 } }
          >
            <I18n params={ { gender: 'male', howMany: 1, this: t('barter')  } }>{'new {this}'}</I18n>
          </Button>
        </SearchContainer>
      </Row>
      {!isEmpty(currentOrganization) && (
        dataTableList
      )}
    </Container>
  )
}

export default BarterListScreen
