import React, { useState, useRef, useCallback, useMemo, useEffect  } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import includes from 'lodash/includes'
import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { doc } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import {
  selectUserProfileIsSmartcoop,
  selectUserProfileIsBuyer,
  selectUserProfileIsAdmin,
  selectUserProfileByOrganization
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { colors } from '@smartcoop/styles'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Button from '@smartcoop/web-components/Button'
import CooperativeDocument from '@smartcoop/web-components/CooperativeDocument'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import InputFile from '@smartcoop/web-components/InputFile'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import PartialLoading from '@smartcoop/web-components/PartialLoading'
import CreateOrganizationForm from '@smartcoop/web-containers/forms/shoppingPlatform/organization/CreateOrganizationForm'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import {
  Container,
  WhiteCard,
  CardChildren,
  Header,
  IconContainer
} from './styles'

const CreateOrganizationScreen = () => {
  const createOrganizationRef = useRef(null)
  const t = useT()
  const history = useHistory()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const location = useLocation()

  const [createOrganizationScreenFiles, setCreateOrganizationScreenFiles] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isSubsidiary, setIsSubsidiary] = useState(
    location.state?.organization?.isSubsidiary ?? false
  )

  const {
    selectedFiles,
    receivedFiles,
    isEmpty,
    handleAdd,
    handleRemove
  } = useFile([], location.state?.organization?.organizationFiles ?? [])

  const userProfileIsSmartcoop = useSelector(selectUserProfileIsSmartcoop)
  const userProfileIsBuyer = useSelector(selectUserProfileIsBuyer)
  const userProfileIsAdmin = useSelector(selectUserProfileIsAdmin)
  const userProfile = useSelector(selectUserProfileByOrganization)

  const isEditing = useMemo(() => location.pathname.includes('edit'), [
    location
  ])

  const userCanSave = useMemo(
    () => userProfileIsSmartcoop || userProfileIsBuyer || userProfileIsAdmin || includes(userProfile, 'cooperative_admin'),
    [userProfile, userProfileIsAdmin, userProfileIsBuyer, userProfileIsSmartcoop]
  )

  // const userCanDelete = useMemo(
  //   () => userProfileIsSmartcoop || userProfileIsAdmin,
  //   [userProfileIsAdmin, userProfileIsSmartcoop]
  // )

  const onCancel = useCallback(() => history.goBack(), [history])
  const onSubmit = useCallback(
    (type) => {
      const isEditingString = type === 'update' ? 'edited' : 'registered'
      snackbar.success(
        t(`your {this} was ${ isEditingString }`, {
          howMany: 1,
          gender: 'female',
          this: t('organization', { howMany: 1 })
        })
      )
      history.goBack()
    },
    [history, snackbar, t]
  )

  const disableFile = useCallback((newIsSubsidiary) => {
    setIsSubsidiary(newIsSubsidiary)
  }, [])

  const handleDeleteDocument = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete-doc',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('archive', { howMany: 1 })
          })
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_ORGANIZATION_FILES_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])


  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements =
      isSubsidiary ? []
        : await Promise.all(map(receivedFiles, async(file) => {
          const fileSrc = await renderFile(file)
          return (
            <CooperativeDocument
              key={ file.id }
              documentName={ file.fileName }
              deleteDocument={ () => handleDeleteDocument('receivedFiles', file) }
              documentSrc={ fileSrc }
              style={ { justifyContent: 'space-between' } }
            />
          )}).concat(
          map(selectedFiles, (file, index) => (
            <CooperativeDocument
              key={ `${ index }-${ file.name }` }
              documentName={ file.name }
              deleteDocument={ () => handleDeleteDocument('selectedFiles', file) }
              documentSrc={  URL.createObjectURL(file) }
              style={ { justifyContent: 'space-between' } }
            />
          ))
        ))

      setCreateOrganizationScreenFiles(receivedElements)
    }
    getRenderedFiles()
  },[handleDeleteDocument, isSubsidiary, receivedFiles, renderFile, selectedFiles]  )


  return (
    <PartialLoading open={ isLoading } opacity={ 1 }>
      <Container>
        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 8 }>
          <Header>
            <IconContainer>
              <GoBackIconButton onClick={ onCancel } iconColor={ colors.primary } />
            </IconContainer>
            <Title
              style={ {
                lineHeight: 1,
                fontSize: 18
              } }
            >
              {isEditing
                ? t('edit {this}', { this: t('organization', { howMany: 1 }) })
                : t('organization registration')}
            </Title>
          </Header>
          <WhiteCard>
            <CreateOrganizationForm
              ref={ createOrganizationRef }
              onSubmit={ onSubmit }
              withoutSubmitButton
              files={ selectedFiles }
              oldFiles={ receivedFiles }
              disableFile={ disableFile }
              setIsLoading={ setIsLoading }
            />
          </WhiteCard>
          <div
            style={ {
              display: 'flex',
              justifyContent: 'flex-end'
            } }
          >
            <Button
              id="web-organization-form-button"
              style={ { flex: '0 0 auto', fontWeight: 600 } }
              color={ colors.secondary }
              onClick={ () => createOrganizationRef.current.submit() }
              disabled={ !userCanSave }
            >
              <I18n>complete registration</I18n>
            </Button>
          </div>
        </Grid>

        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 4 }>
          <div
            style={ {
              height: 42,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            } }
          >
            <InputFile
              idInput="contained-button-file"
              onChange={ handleAdd }
              inputProps={ {
                accept: 'image/png,image/jpeg,image/tiff,application/pdf',
                multiple: true,
                disabled: isSubsidiary
              } }
              maxNumberFile={ 20 - selectedFiles.length }
              maxNumberFileDisplay={ 20 }
              maxFileSize={ 26214400 }
              buttonProps={ {
                id: 'attach-documents',
                style: {
                  flex: '0 0 auto',
                  fontSize: 14,
                  fontWeight: 600,
                  boxShadow: 'none',
                  border: `1px solid ${ colors.lightGrey }`,
                  padding: '4px 8px'
                },
                color: colors.white,
                disabled: isSubsidiary
              } }
            >
              <>
                <Icon icon={ doc } size={ 16 } style={ { marginRight: 5 } } />
                <I18n>attach documents</I18n>
              </>
            </InputFile>
          </div>
          <WhiteCard title={ t('archive', { howMany: 1 }) }>
            <CardChildren>
              {isSubsidiary || isEmpty ? (
                <EmptyState text={ t('no file') } />
              ) : (
                createOrganizationScreenFiles
              )}
            </CardChildren>
          </WhiteCard>
        </Grid>
      </Container>
    </PartialLoading>
  )
}

export default CreateOrganizationScreen
