import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { AnimalPregnancyDiagnosticsActions } from '@smartcoop/stores/animalPregnancyDiagnostics'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterDiagnosisActionsForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterDiagnosisActionsForm'

import useStyles from './styles'

const RegisterDiagnosisActionsModal = (props) => {
  const { id, open, onSubmit, handleClose, data: propData } = props

  const t = useT()
  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const currentAnimal = useSelector(selectCurrentAnimal)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(() => !isEmpty(propData?.id), [propData])
  const defaultValues = useMemo(() => {
    if (isEditing) {
      return {
        earring: propData.animal?.earring?.earringCode,
        animalId: propData.animal?.id,
        ...propData
      }
    }
    return {
      earring: currentAnimal?.earring?.earringCode,
      animalId: currentAnimal?.id,
      ...propData
    }
  }, [currentAnimal.earring.earringCode, currentAnimal.id, isEditing, propData])

  const closeModal = useCallback(() => {
    setIsLoading(false)
    handleClose()
  }, [handleClose])

  const onSuccess = useCallback(() => {
    dispatch(AnimalActions.loadCurrentAnimal())
    snackbar.success(
      t(`your {this} was ${ isEditing ? 'edited' : 'registered' }`, {
        howMany: 1,
        gender: 'male',
        this: t('diagnosis', { howMany: 1 })
      })
    )
    onSubmit()
    closeModal()
  }, [closeModal, dispatch, isEditing, onSubmit, snackbar, t])

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(
        AnimalPregnancyDiagnosticsActions.saveOfflinePregnancyDiagnostic(
          {
            ...data
          },
          onSuccess,
          () => {
            closeModal()
            setIsLoading(false)
          },
          true
        )
      )
    },
    [closeModal, dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <I18n params={ { this: t('diagnosis', { howMany: 1 }) } }>
          {'register {this}'}
        </I18n>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {isLoading ? (
          <Loader width={ 100 } />
        ) : (
          <RegisterDiagnosisActionsForm
            ref={ formRef }
            defaultValues={ defaultValues }
            onSubmit={ handleSubmit }
            onCancel={ closeModal }
          />
        )}
      </>
    </Modal>
  )
}

RegisterDiagnosisActionsModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object
}

RegisterDiagnosisActionsModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  data: {}
}

export default RegisterDiagnosisActionsModal
