import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { useDialog } from '@smartcoop/dialog'
import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { MedicationControlActions } from '@smartcoop/stores/medicationControl'
import Modal from '@smartcoop/web-components/Modal'
import MedicationControlForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/MedicationControlForm'
import LoadingModal from '@smartcoop/web-containers/modals/LoadingModal'

import useStyles from './styles'

const MedicationControlModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    defaultValues,
    onClose,
    reloadDataTable
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()

  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
      onClose()
    }, [handleClose, onClose]
  )

  useEffect(() => {
    if(isLoading) {
      createDialog({
        id: 'loading',
        Component: LoadingModal
      })
    } else {
      removeDialog({ id: 'loading' })
    }
  }, [createDialog, isLoading, removeDialog])

  const onSuccess = useCallback(
    (data) => {
      if(data?.registerCode) {
        snackbar.success(
          t('your {this} was edited', {
            howMany: 1,
            gender: 'male',
            this: t('medication control')
          })
        )
      } else {
        snackbar.success(
          t('your {this} was registered', {
            howMany: 1,
            gender: 'male',
            this: t('medication control')
          })
        )
      }
      onSubmit()
      reloadDataTable()
      closeModal()

    }, [closeModal, onSubmit, reloadDataTable, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(MedicationControlActions.saveMedicationControl(
        { ...data },
        () => onSuccess(data),
        () => setIsLoading(false),
        !!data?.registerCode
      ))
    },
    [dispatch, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableEsc
      title={
        <I18n>medication control</I18n>
      }
      disableFullScreen
      escape
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
      maxWidth="sm"
      fullWidth
    >
      <>
        <MedicationControlForm
          ref={ formRef }
          defaultValues={ defaultValues }
          onSubmit={ handleSubmit }
          onCancel={ closeModal }
          loading={ isLoading }
          setIsLoading={ setIsLoading }
        />
      </>
    </Modal>
  )}

MedicationControlModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  reloadDataTable: PropTypes.func,
  onClose: PropTypes.func
}

MedicationControlModal.defaultProps = {
  handleClose: () => {},
  defaultValues: {},
  onSubmit: () => {},
  reloadDataTable: () => {},
  onClose: () => {}
}

export default MedicationControlModal
