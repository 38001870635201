/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import iconV from 'iconv-lite'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import { uuid } from 'short-uuid'
import XLSX from 'xlsx'

import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findKey from 'lodash/findKey'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import toString from 'lodash/toString'
import toUpper from 'lodash/toUpper'
import uniqBy from 'lodash/uniqBy'

import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectPropertyBulls, selectUnavailableEarrings } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { convertToCSV } from '@smartcoop/utils/files'
import { formatKebabCase } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import InputFile from '@smartcoop/web-components/InputFile'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import Spreadsheet from '@smartcoop/web-components/Spreadsheet'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import './styles.css'

import { ButtonsContainer, SpreadSheetContainer, AlertText } from './styles'

const ImportAnimalModal = ({ id, open, handleClose, reloadDataTable }) => {
  const {
    selectedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    clearFiles
  } = useFile([], [])

  const snackbar = useSnackbar()
  const dispatch = useDispatch()
  const t = useT()
  const unavailableEarrings = useSelector(selectUnavailableEarrings)
  const currentProperty = useSelector(selectCurrentProperty)
  const propertyBulls = useSelector(selectPropertyBulls)

  const updatedPropertyBulls = useMemo(() => {
    const byCode = keyBy(propertyBulls, (item) => formatKebabCase(item?.code))
    const byNameAndCode = keyBy(propertyBulls, (item) => formatKebabCase(item?.nameAndCode))
    return { ...byCode, ...byNameAndCode }
  },[propertyBulls])

  const defaultColumns = [
    {
      name: 'Brinco',
      slug: 'brinco'
    },
    {
      name: 'Nome',
      slug: 'nome'
    },
    {
      name: 'Categoria',
      slug: 'categoria'
    },
    {
      name: 'Data nascimento',
      slug: 'data-nascimento'
    },
    {
      name: 'Lote',
      slug: 'lote'
    },
    {
      name: 'Raça',
      slug: 'raca'
    }, {
      name: 'Último parto',
      slug: 'ultimo-parto'
    },
    {
      name: 'Nº lactações',
      slug: 'n-lactacoes'
    },
    {
      name:'Pai (código)',
      slug: 'pai-codigo'
    },
    {
      name: 'Mãe (brinco)',
      slug: 'mae-brinco'
    },
    {
      name: 'Avô materno (código)',
      slug: 'avo-materno-codigo'
    },
    {
      name: 'Bisavô materno (código)',
      slug: 'bisavo-materno-codigo'
    },
    {
      name: 'Data inseminação',
      slug: 'data-inseminacao'
    },
    {
      name: 'Tipo de inseminação',
      slug: 'tipo-de-inseminacao'
    },
    {
      name: 'Touro (código)',
      slug: 'touro-codigo'
    },
    {
      name: 'Inseminador (nome)',
      slug: 'inseminador-nome'
    },
    {
      name: 'Embrião PAI',
      slug: 'embriao-pai'
    },
    {
      name: 'Embrião Mãe',
      slug: 'embriao-mae'
    },
    {
      name: 'Data 1° toque',
      slug: 'data-1-toque'
    },
    {
      name: 'Resultado 1º toque',
      slug: 'resultado-1-toque'
    },
    {
      name: 'Data 2º toque',
      slug: 'data-2-toque'
    },
    {
      name: 'Resultado 2º toque',
      slug: 'resultado-2-toque'
    },
    {
      name: 'Data toque secagem',
      slug: 'data-toque-secagem'
    },
    {
      name: 'Resultado toque secagem',
      slug: 'resultado-toque-secagem'
    },
    {
      name: 'Data secagem',
      slug: 'data-secagem'
    },
    {
      name: 'Data pré-parto',
      slug: 'data-pre-parto'
    }
  ]

  const [data, setData] = useState([])

  const [lots, setLots] = useState([])
  const [breeds, setBreeds] = useState([])
  const [bulls, setBulls] = useState([])
  const [inseminationTypes, setInseminationTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const exportCSVFile = () => {
    const dataToTransform = map(data, item => map(item, ({ name, value }) => ({ [name]: value })))

    dataToTransform.unshift(map(defaultColumns, ({ name }) => ({ [name]: name })))

    const exportedData = map(dataToTransform, item => map(item, subItem => Object.values(subItem)))

    const csv = convertToCSV(exportedData)

    const exportedFilename = 'planilha_animais_smartcoop.csv'

    const blob = new Blob([`\uFEFF${  csv }`], { type: 'text/csv;charset=utf-8,%EF%BB%BF' })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const optionsCategory = {
    touro: 'Touro',
    boi: 'Boi',
    terneira: 'Terneira',
    novilha: 'Novilha',
    vaca: 'Vaca'
  }

  const resultDiagnosticOptions = {
    Positivo: t('pregnant'),
    Vazia: t('empty'),
    Inconclusivo: t('inconclusive')
  }

  const loadSheetSelectData = useCallback(
    () => {
      dispatch(AnimalActions.loadAnimalLots((response) => {
        setLots(keyBy(response, (item) => formatKebabCase(item?.name)))
      }))
      dispatch(AnimalActions.loadAnimalBreeds((response) => {
        setBreeds(keyBy(response, (item) => formatKebabCase(item?.name)))
      }))
      dispatch(AnimalActions.loadAnimalBulls((response) => {
        const byCode = keyBy(response, (item) => formatKebabCase(item?.code))
        const byNameAndCode = keyBy(response, (item) => formatKebabCase(item?.nameAndCode))
        setBulls({ ...byCode, ...byNameAndCode })
      }))
      dispatch(AnimalActions.loadAnimalInseminationTypes((response) => {
        setInseminationTypes(keyBy(response, (item) => formatKebabCase(item?.name)))
      }))
      dispatch(AnimalActions.loadPropertyBulls())
      dispatch(AnimalActions.loadUnavailableEarrings({ propertyId: currentProperty?.id }))
    },
    [currentProperty.id, dispatch]
  )


  useEffect(() => {
    loadSheetSelectData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react/prop-types
  const SelectLots = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `lots-${ uuid() }}` }
      key={ `lots-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="lot"
      options={ map(lots, item => ({ label: item?.name, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[lots])
  // eslint-disable-next-line react/prop-types
  const SelectBreeds = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `race-${ uuid() }}` }
      key={ `race-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="race"
      options={ map(breeds, item => ({ label: item?.name, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[breeds])

  // eslint-disable-next-line react/prop-types
  const SelectBulls = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `bull-${ uuid() }}` }
      key={ `bull-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 200, marginBottom: 0 } }
      name="bull"
      options={ uniqBy(map(bulls, item => ({ label: item?.nameAndCode, value: item?.id })), 'value')  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[bulls])

  // eslint-disable-next-line react/prop-types
  const SelectPropertyBulls = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `property-bull-${ uuid() }}` }
      key={ `property-bull-${ uuid() }}` }
      disabled={ isView }
      detached
      name="bull"
      style={ { width: 200, marginBottom: 0 } }
      options={ map(propertyBulls, item => ({ label: item?.nameAndCode, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ),[propertyBulls])

  // eslint-disable-next-line react/prop-types
  const SelectInseminationTypes = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `insemination-${ uuid() }}` }
      key={ `insemination-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="inseminationType"
      options={ map(inseminationTypes, item => ({ label: item?.name, value: item?.id }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: toUpper(item?.label), id: e })
      } }
    />
  ),[inseminationTypes])

  // eslint-disable-next-line react/prop-types
  const SelectCategory = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `category-${ uuid() }}` }
      key={ `category-${ uuid() }}` }
      disabled={ isView }
      detached
      style={ { width: 150, marginBottom: 0 } }
      name="category"
      options={ map(optionsCategory, (item, key) => ({ label: item, value: key }))  }
      value={ cell?.id }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ), [optionsCategory])

  // eslint-disable-next-line react/prop-types
  const SelectResultDiagnostic = useCallback(({ isView, cell, onChange }) => (
    <InputSelect
      id={ `resultDiagnostic-${ uuid() }}` }
      key={ `resultDiagnostic-${ uuid() }}` }
      disabled={ isView }
      detached
      name="diagnosticResult"
      style={ { width: 150, marginBottom: 0 } }
      options={ map(resultDiagnosticOptions, (item, key) => ({ label: item, value: key }))  }
      value={ cell?.id }
      inputProps={ { style: { border: 0 } } }
      onChange={ (e, item) => {
        onChange({ ...cell, value: item?.label, id: e })
      } }
    />
  ), [resultDiagnosticOptions])


  const renderEditor = useCallback((isView, slug, row) => {
    const isMonta = !!find(row, item => item?.slug === 'tipo-de-inseminacao' && item?.id === 5)

    if(slug === 'touro-codigo' && isMonta) {
      return ({ cell, onChange }) => SelectPropertyBulls({ isView, cell, onChange })
    } if (slug === 'touro-codigo' && !isMonta) {
      return ({ cell, onChange }) => SelectBulls({ isView, cell, onChange })
    }

    switch (slug) {
      case 'categoria':
        return ({ cell, onChange }) => SelectCategory({ isView, cell, onChange })
      case 'lote':
        return ({ cell, onChange }) => SelectLots({ isView, cell, onChange })
      case 'raca':
        return ({ cell, onChange }) => SelectBreeds({ isView, cell, onChange })
      case 'pai-codigo':
      case 'avo-materno-codigo':
      case 'bisavo-materno-codigo':
        return ({ cell, onChange }) => SelectBulls({ isView, cell, onChange })
      case 'resultado-1-toque':
      case 'resultado-2-toque':
      case 'resultado-toque-secagem':
        return ({ cell, onChange }) => SelectResultDiagnostic({ isView, cell, onChange })
      case 'tipo-de-inseminacao':
        return ({ cell, onChange }) => SelectInseminationTypes({ isView, cell, onChange })
      default:
        return null
    }


  }, [SelectBreeds, SelectBulls, SelectCategory, SelectInseminationTypes, SelectLots, SelectPropertyBulls, SelectResultDiagnostic])

  const getItemId = useCallback((slug, value, row) => {
    const isMonta = !!find(row, item => item?.slug === 'tipo-de-inseminacao' && item?.id === 5)
    let response = null

    if(isNil(value)) {
      return ''
    }

    if(slug === 'touro-codigo' && isMonta) {
      response = updatedPropertyBulls?.[formatKebabCase(value)]
      return response?.id
    } if (slug === 'touro-codigo' && !isMonta) {
      response = bulls?.[formatKebabCase(value)]
      return response?.id
    }

    switch (slug) {
      case 'categoria':
        response = optionsCategory?.[formatKebabCase(value)]
        return response?.value || ''
      case 'lote':
        response = lots?.[formatKebabCase(value)]
        return response?.id || ''
      case 'raca':
        response = breeds?.[formatKebabCase(value)]
        return response?.id || ''
      case 'pai-codigo':
      case 'avo-materno-codigo':
      case 'bisavo-materno-codigo':
        response = bulls?.[formatKebabCase(value)]
        return response?.id || ''
      case 'tipo-de-inseminacao':
        response = bulls?.[formatKebabCase(value)]
        return response?.id || ''
      case 'resultado-1-toque':
      case 'resultado-2-toque':
      case 'resultado-toque-secagem':
        response = findKey(resultDiagnosticOptions, (item) => formatKebabCase(item) === formatKebabCase(value))
        return response || ''
      case 'sexado':
        return !!(value === 'SIM' || value === 'sim' || value === 'Sim')
      default:
        return null
    }
  },[breeds, bulls, lots, optionsCategory, resultDiagnosticOptions, updatedPropertyBulls])

  const validateValueBySlug = useCallback((slug, value, row) => {
    const isMonta = !!find(row, item => item?.slug === 'tipo-de-inseminacao' && item?.id === 5)

    if(isNil(value)) {
      return ''
    }
    if(
      slug === 'data-nascimento' ||
      slug === 'data-inseminacao' ||
      slug === 'ultimo-parto' ||
      slug === 'data-1-toque' ||
      slug === 'data-2-toque' ||
      slug === 'data-toque-secagem' ||
      slug === 'data-secagem' ||
      slug === 'data-pre-parto'
    ) {
      if(moment(value, 'DD-MM-YYYY').isValid()) {
        return moment(value, 'DD-MM-YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'D/M/YYYY').isValid()) {
        return moment(value, 'D/M/YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'DDMMYYYY').isValid()) {
        return moment(value, 'DDMMYYYY').format('DD/MM/YYYY')
      } if(moment(value, 'M/D/YYYY').isValid()) {
        return moment(value, 'M/D/YYYY').format('DD/MM/YYYY')
      }  if(moment(value, 'M/DD/YYYY').isValid()) {
        return moment(value, 'M/DD/YYYY').format('DD/MM/YYYY')
      }  if(moment(value, 'MM/DD/YYYY').isValid()) {
        return moment(value, 'MM/DD/YYYY').format('DD/MM/YYYY')
      } if(moment(value, 'YYYY-MM-DD').isValid()) {
        return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }
    }
    if(slug === 'categoria') {
      const response = optionsCategory?.[formatKebabCase(value)]
      return response || ''
    }
    if(slug === 'lote') {
      const response = lots?.[formatKebabCase(value)]
      return response?.name || ''
    }

    if(slug === 'raca') {
      const response = breeds?.[formatKebabCase(value)]
      return response?.name || ''
    }

    if(slug === 'touro-codigo' && isMonta) {
      const response = find(propertyBulls, item => formatKebabCase(toString(item.code)) === formatKebabCase(value) || formatKebabCase(item.nameAndCode) === formatKebabCase(value))
      return response?.nameAndCode || ''
    }

    if (slug === 'touro-codigo' && !isMonta) {
      const response = bulls?.[formatKebabCase(value)]
      return response?.nameAndCode || ''
    }
    if (slug === 'pai-codigo' || slug === 'avo-materno-codigo' || slug === 'bisavo-materno-codigo' ) {
      const response = bulls?.[formatKebabCase(value)]
      return response?.nameAndCode || ''
    }
    if(slug === 'tipo-de-inseminacao') {
      return toUpper(value)
    }
    if(slug === 'resultado-1-toque' || slug === 'resultado-2-toque' || slug === 'resultado-toque-secagem'){
      return capitalize(value)
    }

    return value
  },[breeds, bulls, lots, optionsCategory, propertyBulls])

  const validateErrorBySlugOnSend = useCallback((slug, value, row) => {
    switch(slug) {
      case 'brinco': {
        const earringArray = map(filter(flatten(data), item => item.slug === 'brinco'), item => item.value)
        const filteredEarrings = filter(earringArray, item => item === value)
        return isEmpty(value) || !!find(unavailableEarrings, item => item === value) || filteredEarrings.length > 1
      }
      case 'data-nascimento':
        return isEmpty(value) || !moment(value, 'DD/MM/YYYY').isValid()
      case 'nome':
      case 'categoria':
      case 'lote':
      case 'raca':
        return isEmpty(value)
      case 'n-lactacoes':
        return isNaN(Number(value))
      case 'data-inseminacao':
        return (value && !moment(value, 'DD/MM/YYYY').isValid()) || (isEmpty(value) && find(row, item => item?.slug === 'tipo-de-inseminacao' && !isEmpty(item?.value)))
      case 'data-1-toque':
        return (value && !moment(value, 'DD/MM/YYYY').isValid()) || (isEmpty(value) && find(row, item => item?.slug === 'resultado-1-toque' && !isEmpty(item?.value)))
      case 'data-2-toque':
        return (value && !moment(value, 'DD/MM/YYYY').isValid()) || (isEmpty(value) && find(row, item => item?.slug === 'resultado-2-toque' && !isEmpty(item?.value)))
      case 'data-toque-secagem':
        return (value && !moment(value, 'DD/MM/YYYY').isValid()) || (isEmpty(value) && find(row, item => item?.slug === 'resultado-toque-secagem' && !isEmpty(item?.value)))
      case 'ultimo-parto':
      case 'data-secagem':
      case 'data-pre-parto':
        return value && !moment(value, 'DD/MM/YYYY').isValid()
      case 'tipo-de-inseminacao':
        return isEmpty(value) && !!find(row, item => item?.slug === 'data-inseminacao' && !isEmpty(item?.value))
      case 'resultado-1-toque':
        return isEmpty(value) && !!find(row, item => item?.slug === 'data-1-toque' && !isEmpty(item?.value))
      case 'resultado-2-toque':
        return isEmpty(value) && !!find(row, item => item?.slug === 'data-2-toque' && !isEmpty(item?.value))
      case 'resultado-toque-secagem':
        return isEmpty(value) && !!find(row, item => item?.slug === 'data-toque-secagem' && !isEmpty(item?.value))
      case 'embriao-pai':
      case 'embriao-mae':
        return isEmpty(value) && !!find(row, item => item?.slug === 'tipo-de-inseminacao' && !isEmpty(item?.value) && formatKebabCase(item?.value) === 'te')
      case 'touro-codigo':
        return isEmpty(value) && !!find(row, item => item?.slug === 'tipo-de-inseminacao' && !isEmpty(item?.value) && formatKebabCase(item?.value) !== 'te')
      default:
        return false
    }
  }, [data, unavailableEarrings])

  const validateDataToSave = useCallback((slug, value) => {
    switch(slug) {
      case 'data-nascimento':
      case 'data-inseminacao':
      case 'ultimo-parto':
      case 'data-1-toque':
      case 'data-2-toque':
      case 'data-toque-secagem':
      case 'data-secagem':
      case 'data-pre-parto':
        return !isEmpty(value) ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
      default:
        return value
    }
  }, [])

  const getInputWidth = (slug) => {
    switch(slug) {
      case 'categoria':
      case 'lote':
      case 'raca':
      case 'tipo-de-inseminacao':
      case 'resultado-1-toque':
      case 'resultado-2-toque':
      case 'resultado-toque-secagem':
        return 150
      case 'touro-codigo':
      case 'pai-codigo':
      case 'avo-materno-codigo':
      case 'bisavo-materno-codigo':
        return 200
      default:
        return 100
    }
  }

  useEffect(() => {
    if(!isEmptyFiles) {
      setLoading(true)
      setData([])
      const reader = new FileReader()
      reader.onload = function(e) {
        // o retorno de e.target.result é um objeto com 2 arrays de codificação
        // é criado o array pra Uint8

        let response = new Uint8Array(e.target.result)

        // é decodificado pra string pra poder transformar os caracteres especiais
        const decodedString = new TextDecoder().decode(response)

        const foundError = decodedString.search('workbook.xml')

        if(foundError === -1) {
          const reencoded = iconV.encode(`\uFEFF${ iconV.decode(decodedString, 'iso-8859-1') }`, 'utf8')

          // é encodificado novamente pro array de Uint8
          response = new TextEncoder().encode(reencoded)
        }
        // é transformador pra iso-8869-1 (padrão PT-br) e depois pra utf8 novamente
        const workbook = XLSX.read(response, { type: 'array', raw: true })
        const name = workbook.SheetNames
        const worksheet = workbook.Sheets[name[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false })
        let arrayData = []
        map(jsonData, (item, index) => {
          arrayData[index] = map(item, (value, subIndex) => ({
            name: subIndex,
            value,
            slug: formatKebabCase(subIndex)
          }))
        })

        arrayData = map(arrayData, item => map(item, subItem => ({
          ...subItem,
          DataEditor: renderEditor(false, subItem.slug, item),
          DataViewer: ({ cell, onChange }) =>
            <input
              value={ cell?.value || '' }
              onChange={ onChange }
              style={
                {
                  width: getInputWidth(subItem.slug),
                  height: 40,
                  fontSize: 14,
                  border: 'none',
                  cursor: 'cell'
                }
              }
            />,
          id: getItemId(subItem.slug, subItem.value, item),
          value: !isEmpty(subItem.value) ? validateValueBySlug(subItem.slug, subItem.value, item) : subItem.value,
          className: !isEmpty(subItem.value) && validateValueBySlug(subItem.slug, subItem.value, item) === '' ? 'error' : ''
        })))

        const arrayMapped = map(arrayData, (item) =>
          map(defaultColumns, (column) => {
            const itemFound = find(item, subItem => subItem.slug === column.slug)
            if(itemFound) return itemFound
            return { ...column, value: '', DataEditor: renderEditor(false, column.slug, item), DataViewer: ({ cell, onChange }) =>
              <input
                value={ cell?.value || '' }
                onChange={ onChange }
                style={
                  {
                    width: getInputWidth(column.slug),
                    height: 40,
                    fontSize: 14,
                    border: 'none',
                    cursor: 'cell'
                  }
                }
              />
            }
          })
        )

        setData(arrayMapped)
        setLoading(false)
      }
      reader.readAsArrayBuffer(selectedFiles[0])
      clearFiles()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(() => {
    if(!isEmpty(bulls) && !isEmpty(breeds) && !isEmpty(lots) && !isEmpty(inseminationTypes) ) {
      setData([
        map(defaultColumns, ({ name, slug }) => ({ name, slug, value: '', DataEditor: renderEditor(false, slug), DataViewer: ({ cell, onChange }) =>
          <input
            value={ cell?.value || '' }
            onChange={ onChange }
            style={
              {
                width: getInputWidth(slug),
                height: 40,
                fontSize: 14,
                border: 'none',
                cursor: 'cell'
              }
            }
          /> }))
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bulls, breeds, lots, inseminationTypes])

  const handleChange = useCallback(
    debounce((value) => {
      setData(value)
    }, 1000),
    [getItemId, renderEditor, validateValueBySlug]
  )

  const finishSubmit = useCallback(
    () => {
      let hasError = false
      hasError = !!find(flatten(data), item => item.className === 'error')

      if(hasError) {
        snackbar.error('Existem campos com erro.')
        setLoading(false)
        setSubmitted(false)
      } else {
        dispatch(AnimalActions.importAnimals(
          {
            data: map(data, item => map(item, subItem => ({
              name: subItem.name,
              value: subItem.value ? validateDataToSave(subItem.slug, subItem.value) : '',
              slug: subItem.slug,
              id: subItem.id ? subItem.id : ''
            })))
          },
          () => {
            snackbar.success(t('success on importing animals'))
            setLoading(false)
            setSubmitted(false)
            reloadDataTable()
            handleClose()
          },
          (error) => {
            snackbar.error(t(error))
            setLoading(false)
            setSubmitted(false)
          },
          t
        ))
      }
    },
    [data, dispatch, handleClose, reloadDataTable, snackbar, t, validateDataToSave]
  )

  const handleSubmit = useCallback(
    () => {
      setLoading(true)
      setData(
        map(data, item => map(item, subItem => ({
          ...subItem,
          className: validateErrorBySlugOnSend(subItem.slug, subItem.value, item) ? 'error' : '',
          id: getItemId(subItem.slug, subItem.value, item)
        })))
      )
      setTimeout(() => {
        setSubmitted(true)
      }, 2000)
    },
    [data, getItemId, validateErrorBySlugOnSend]
  )

  useEffect(() => {
    if(submitted) {
      finishSubmit()
    }
  }, [finishSubmit, submitted])
  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      disableEsc
      title={
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <I18n>import animals</I18n>
        </div>
      }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
    >
      <div style={ { display: 'flex', flex: 1, flexDirection: 'column', flexWrap: 'wrap', padding: '30px' } }>
        <div style={ { display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: '20px' } }>
          <InputFile
            idInput="contained-button-file-1"
            onChange={ handleAdd }
            inputProps={ {
              accept: '.xlsx,.csv'
            } }
            buttonProps={ {
              id: 'add-file',
              variant: 'outlined',
              style: {
                backgroundColor: 'white'
              },
              fullWidth: true,
              disabled: loading
            } }
            style={ { width: 200 } }
          >
            <I18n>import file</I18n>
          </InputFile>
          <Button
            id="download-sheet"
            color="primary"
            onClick={ exportCSVFile }
            style={ { marginLeft: 20, maxWidth: 200 } }
            disabled={ loading }
          >
            <I18n>download sheet</I18n>
          </Button>
        </div>
        <AlertText>
          *<I18n>when exporting the sheet, keep the column names as the original model</I18n>
        </AlertText>
        {loading ? <Loader /> : (
          <SpreadSheetContainer>
            <Spreadsheet
              data={ data }
              onChange={ handleChange }
              columnLabels={ map(defaultColumns, item => item.name) }
            />
          </SpreadSheetContainer>
        )}
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <ButtonsContainer>
            <Button
              id="add-line"
              color="primary"
              style={ { marginRight: 10, maxWidth: 20 } }
              disabled={ loading }
              onClick={ () => setData([...data, map(defaultColumns, ({ name, slug }) => ({ name, slug, value: '',  DataEditor: renderEditor(false, slug), DataViewer: ({ cell, onChange }) =>
                <input
                  value={ cell?.value || '' }
                  onChange={ onChange }
                  style={
                    {
                      width: getInputWidth(slug),
                      height: 40,
                      fontSize: 14,
                      border: 'none',
                      cursor: 'cell'
                    }
                  }
                /> }))]) }
            >
            +
            </Button>
            <Button
              id="remove-line"
              color="primary"
              onClick={ () => setData(data.slice(0, -1 )) }
              disabled={ data.length <= 1 || loading }
              style={ { maxWidth: 20 } }
            >
            -
            </Button>
          </ButtonsContainer>
          <ButtonsContainer style={ { justifyContent: 'flex-end' } }>
            <Button
              id="save"
              color="secondary"
              onClick={ handleSubmit }
              style={ { maxWidth: 100 } }
              disabled={ loading }
            >
            Salvar
            </Button>
          </ButtonsContainer>
        </div>
      </div>
    </Modal>
  )}

ImportAnimalModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadDataTable: PropTypes.func.isRequired
}

ImportAnimalModal.defaultProps = {
}

export default ImportAnimalModal
