import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import size from 'lodash/size'

import { useT } from '@smartcoop/i18n'
import {
  currency,
  currencySignRounded,
  field,
  organization,
  shoppingPlatform,
  socialNetwork,
  tractor,
  cattle,
  weatherStation,
  smallPlant,
  supplier,
  products3d,
  productGroup3d,
  productWall,
  home,
  dashboard,
  arrowsBackAndForth,
  plantAndHand,
  user,
  invoice,
  cost,
  lamp,
  reports
} from '@smartcoop/icons'
import advertising from '@smartcoop/icons/svgs/advertising'
import logo365 from '@smartcoop/icons/svgs/logo365'
import {
  selectUserIsSupplier,
  selectPermissionsLoaded,
  selectAllModules,
  selectPermissionWeatherStations,
  selectUserProfileByOrganization
} from '@smartcoop/stores/authentication/selectorAuthentication'
import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import { selectCurrentModule, selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwner, selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { PROFILES } from '@smartcoop/utils/constants'
import Icon from '@smartcoop/web-components/Icon'

import AdvertisingScreen from '../screens/authenticated/administration/AdvertisingScreen'
import FiscalRegisterScreen from '../screens/authenticated/administration/FiscalRegisterScreen'
import OperationsScreen from '../screens/authenticated/administration/OperationsScreen'
import ChatScreen from '../screens/authenticated/ChatScreen'
import ChooseOrganizationScreen from '../screens/authenticated/ChooseOrganizationScreen/ChooseOrganizationScreen'
import BarterListScreen from '../screens/authenticated/commercialization/barter/BarterListScreen'
import CreateBarterScreen from '../screens/authenticated/commercialization/barter/CreateBarterScreen'
import ProductQuotationListScreen from '../screens/authenticated/commercialization/productQuotation/ProductQuotationListScreen'
import SecuritiesMovementListScreen from '../screens/authenticated/commercialization/securitiesMovement/SecuritiesMovementListScreen'
import NotificationsScreen from '../screens/authenticated/common/NotificationsScreen'
import ReportsScreen from '../screens/authenticated/common/ReportsScreen'
import SocialScreen from '../screens/authenticated/common/SocialScreen'
import Comments from '../screens/authenticated/common/SocialScreen/Comments/Comments'
import StampScreen from '../screens/authenticated/common/StampScreen'
import WeatherStationMapScreen from '../screens/authenticated/common/wheaterStation/WeatherStationMapScreen'
import DairyFarmDashboardScreen from '../screens/authenticated/digitalProperty/dairyFarm/DairyFarmDashboardScreen'
import ListMilkControlScreen from '../screens/authenticated/digitalProperty/dairyFarm/ListMilkControlScreen'
import ListMilkDeliveryScreen from '../screens/authenticated/digitalProperty/dairyFarm/ListMilkDeliveryScreen'
import ListMilkQualityScreen from '../screens/authenticated/digitalProperty/dairyFarm/ListMilkQualityScreen'
import ListPriceDataScreen from '../screens/authenticated/digitalProperty/dairyFarm/ListPriceDataScreen'
import ListProductionDataScreen from '../screens/authenticated/digitalProperty/dairyFarm/ListProductionDataScreen'
import RegisterAnimalsScreen from '../screens/authenticated/digitalProperty/dairyFarm/RegisterAnimalsScreen'
import FieldAnalysisRegisterScreen from '../screens/authenticated/digitalProperty/field/FieldAnalysisRegisterScreen/FieldAnalysisRegisterScreen'
import FieldDetailsScreen from '../screens/authenticated/digitalProperty/field/FieldDetailsScreen'
import FieldListHistoryScreen from '../screens/authenticated/digitalProperty/field/FieldListHistoryScreen'
import FieldListScreen from '../screens/authenticated/digitalProperty/field/FieldListScreen'
import FieldRegisterScreen from '../screens/authenticated/digitalProperty/field/FieldRegisterScreen'
import GrowingSeasonRegisterScreen from '../screens/authenticated/digitalProperty/growingSeason/GrowingSeasonRegisterScreen'
import MachineryListScreen from '../screens/authenticated/digitalProperty/machinery/MachineryListScreen'
import MachineryRegisterScreen from '../screens/authenticated/digitalProperty/machinery/MachineryRegisterScreen'
import ManagementRegisterScreen from '../screens/authenticated/digitalProperty/management/ManagementRegisterScreen/ManagementScreen'
import PestReportRegisterScreen from '../screens/authenticated/digitalProperty/pestReport/PestReportRegisterScreen'
import CostsScreen from '../screens/authenticated/digitalProperty/property/CostsScreen/CostsScreen'
import PropertyHomeScreen from '../screens/authenticated/digitalProperty/property/PropertyHomeScreen'
import PropertyInformativeScreen from '../screens/authenticated/digitalProperty/property/PropertyInformativeScreen/PropertyInformativeScreen'
import PropertyTechnicalVisitListScreen from '../screens/authenticated/digitalProperty/property/PropertyTechnicalVisitListScreen/PropertyTechnicalVisitListScreen'
import PropertyTechnicalVisitProducerListScreen from '../screens/authenticated/digitalProperty/property/PropertyTechnicalVisitProducerListScreen/PropertyTechnicalVisitProducerListScreen'
import RegisterInformativeScreen from '../screens/authenticated/digitalProperty/property/RegisterInformativeScreen/RegisterInformativeScreen'
import RainRecordListScreen from '../screens/authenticated/digitalProperty/rainRecord/RainRecordList'
import ChooseModuleScreen from '../screens/authenticated/moduleScreen/ChooseModuleScreen'
import EditProfileScreen from '../screens/authenticated/profile/EditProfileScreen'
import UserAccessScreen from '../screens/authenticated/profile/UserAccessScreen'
import ReportDownloadScreen from '../screens/authenticated/ReportDownloadScreen/ReportDownloadScreen'
import CRMReportScreen from '../screens/authenticated/reports/CRMReportScreen'
import DetecMilkReportScreen from '../screens/authenticated/reports/DetecMilkReportScreen/DetecMilkReportScreen'
import DirectionReportScreen from '../screens/authenticated/reports/DirectionReportScreen'
import DirectionReportScreenNoFilters from '../screens/authenticated/reports/DirectionReportScreenNoFilters'
import FieldsRankingReportScreen from '../screens/authenticated/reports/FieldsRankingReportScreen'
import OrganizationReportScreen from '../screens/authenticated/reports/OrganizationReportScreen/OrganizationReportScreen'
import RTCReportScreen from '../screens/authenticated/reports/RTCReportScreen/RTCReportScreen'
import ShoppingReportScreen from '../screens/authenticated/reports/ShoppingReportScreen'
import UserReportScreen from '../screens/authenticated/reports/UserReportScreen'
import WeatherStationDashboardReportScreen from '../screens/authenticated/reports/WeatherStationDashboardReportScreen'
import AcceptProposalScreen from '../screens/authenticated/shoppingPlatform/order/AcceptProposalScreen'
import JoinOrderScreen from '../screens/authenticated/shoppingPlatform/order/JoinOrderScreen'
import NonComplianceScreen from '../screens/authenticated/shoppingPlatform/order/NonComplianceScreen'
import OrderDetailsScreen from '../screens/authenticated/shoppingPlatform/order/OrderDetailsScreen'
import OrderListScreen from '../screens/authenticated/shoppingPlatform/order/OrderListScreen'
import RegisterOrderScreen from '../screens/authenticated/shoppingPlatform/order/RegisterOrderScreen'
import CreateOrganizationScreen from '../screens/authenticated/shoppingPlatform/organization/CreateOrganizationScreen'
import ListOrganizationScreen from '../screens/authenticated/shoppingPlatform/organization/ListOrganizationScreen'
import CreateProductGroupScreen from '../screens/authenticated/shoppingPlatform/productGroup/CreateProductGroupScreen'
import ListProductGroupScreen from '../screens/authenticated/shoppingPlatform/productGroup/ListProductGroupScreen'
import EditProductScreen from '../screens/authenticated/shoppingPlatform/products/EditProductScreen'
import ProductsListScreen from '../screens/authenticated/shoppingPlatform/products/ProductsListScreen'
import RegisterProductScreen from '../screens/authenticated/shoppingPlatform/products/RegisterProductScreen'
import ProductWall from '../screens/authenticated/shoppingPlatform/productWall/ProductWall'
import ProductWallComments from '../screens/authenticated/shoppingPlatform/productWall/ProductWallComments'
import SupplierQuotationDetailsScreen from '../screens/authenticated/shoppingPlatform/quotation/SupplierQuotationDetailsScreen'
import SupplierQuotationListScreen from '../screens/authenticated/shoppingPlatform/quotation/SupplierQuotationListScreen'
import CreateSupplierScreen from '../screens/authenticated/shoppingPlatform/supplier/CreateSupplierScreen'
import EditSupplierScreen from '../screens/authenticated/shoppingPlatform/supplier/EditSupplierScreen'
import ListSupplierScreen from '../screens/authenticated/shoppingPlatform/supplier/ListSupplierScreen'
import AccreditmentListScreen from '../screens/authenticated/technical/AccreditmentListScreen'
import TechnicalAreaScreen from '../screens/authenticated/technical/TechnicalAreaScreen'
import TechnicalCommercializationProductsListScreen from '../screens/authenticated/technical/TechnicalCommercializationProductsListScreen'
import TechnicalVisionReportScreen from '../screens/authenticated/technical/TechnicalVisionReportScreen/TechnicalVisionReportScreen'
import PrivacyTermScreen from '../screens/guest/term/PrivacyTermScreen'
import UseTermScreen from '../screens/guest/term/UseTermScreen'

export const useRoutes = () => {
  const t = useT()

  const currentModule = useSelector(selectCurrentModule)
  const allModules = useSelector(selectAllModules)
  const userIsSupplier = useSelector(selectUserIsSupplier)
  const permissionsLoaded = useSelector(selectPermissionsLoaded)
  const technicalCurrentOwner = useSelector(selectCurrentOwner)
  const permissionWeatherStations = useSelector(selectPermissionWeatherStations)
  const userProfile = useSelector(selectUserProfileByOrganization)
  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const withoutModuleRoutes = useMemo(() => {
    let response = {}
    if (!isEmpty(allModules)) {
      response = {
        ...response,
        chooseModule: {
          path: '/',
          exact: true,
          Component: ChooseModuleScreen,
          disabled: size(allModules) <= 1
        },
        chooseOrganization: {
          path: '/choose-organization',
          exact: true,
          Component: ChooseOrganizationScreen
        }
      }
    }
    return response
  }, [allModules])

  const commonRoutes = useMemo(
    () => {
      const common = {
        // pages like "notifications" or "user profile" can be here
        notifications: {
          path: '/notifications',
          exact: true,
          Component: NotificationsScreen
        },
        editProfile: {
          path: '/profile/edit',
          exact: true,
          Component: EditProfileScreen
        },
        userAccess: {
          path: '/profile/user-access',
          exact: true,
          Component: UserAccessScreen
        },
        privacyTermScreen: {
          path: '/term/privacy',
          exact: true,
          Component: PrivacyTermScreen
        },
        useTermScreen: {
          path: '/term/use',
          exact: true,
          Component: UseTermScreen
        },
        stamps: {
          path: '/selos/:hash/:stampSlug',
          exact: true,
          Component: StampScreen
        },
        reportDownload: {
          path: '/report/:reportId',
          exact: true,
          Component: ReportDownloadScreen
        }
        // chooseOrganization: {
        //   path: '/choose-organization',
        //   exact: true,
        //   Component: ChooseOrganizationScreen
        // }
      }
      return {
        ...common,
        chatList: {
          path: '/chat',
          exact: true,
          Component: ChatScreen
        }
      }
    }, [])

  const socialNetworkRoutes = useMemo(
    () => ({
      social: {
        path: '/social',
        exact: true,
        Component: SocialScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ socialNetwork } { ...props } />,
          title: t('network')
        }
      },
      socialComments: {
        path: '/social/comments/:postId',
        exact: true,
        Component: Comments,
        disabled: userIsSupplier
      }
    }),
    [t, userIsSupplier]
  )

  const validationsWeather = useMemo(
    () =>
      !(
        (includes(userProfile, PROFILES.detec) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, 'rtc') && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.technical) && currentModule === AVAILABLE_MODULES.technical) ||
        (includes(userProfile, PROFILES.commercialGrain) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.commercialInputs) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.detecMilk) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.smartcoop) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.federation) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.admin) && currentModule === AVAILABLE_MODULES.administration) ||
        (includes(userProfile, PROFILES.associatedRuralProducer) && currentModule === AVAILABLE_MODULES.digitalProperty) ||
        (includes(userProfile, PROFILES.familyGroup) && currentModule === AVAILABLE_MODULES.digitalProperty)
      )
    ,
    [currentModule, userProfile]
  )

  const weatherStationsRoutes = useMemo(
    () => ({
      weatherStation: {
        path: '/weather-stations',
        exact: true,
        Component: WeatherStationMapScreen,
        disabled: validationsWeather,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ weatherStation } { ...props } />,
          title: t('weather station', { howMany: 2 })
        }
      },
      weatherStationDashboardReport: {
        path: '/weather-stations/reports/dashboard',
        exact: true,
        Component: WeatherStationDashboardReportScreen
      }
    }),
    [t, validationsWeather]
  )

  const propertyRoutes = useMemo(
    () => {
      let routes = {
        propertyHome: {
          path: '/digital-property',
          exact: true,
          Component: PropertyHomeScreen,
          menu: {
            exact: true,
            Icon: (props) => <Icon icon={ home } size={ 28 } { ...props } />,
            title: t('property', { howMany: 1 })
          }
        },
        fieldList: {
          path: '/digital-property/field',
          exact: true,
          Component: FieldListScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ field } size={ 22 } { ...props } />,
            title: t('field', { howMany: 2 })
          }
        },
        machineryList: {
          path: '/digital-property/machinery',
          exact: true,
          Component: MachineryListScreen,
          menu: {
            exact: true,
            Icon: (props) => <Icon icon={ tractor } { ...props } />,
            title: t('machinery', { howMany: 2 })
          }
        },
        machineryRegister: {
          path: '/digital-property/machinery/register',
          exact: true,
          Component: MachineryRegisterScreen
        },
        machineryEdit: {
          path: '/digital-property/machinery/edit',
          exact: true,
          Component: MachineryRegisterScreen
        },
        fieldRegister: {
          path: '/digital-property/field/register',
          exact: true,
          Component: FieldRegisterScreen
        },
        fieldEdit: {
          path: '/digital-property/field/edit',
          exact: true,
          Component: FieldRegisterScreen
        },
        fieldListHistory: {
          path: '/digital-property/field/history',
          exact: true,
          Component: FieldListHistoryScreen
        },
        fieldDetails: {
          path: '/digital-property/field/:fieldId',
          exact: true,
          Component: FieldDetailsScreen
        },
        growingSeasonRegister: {
          path: '/digital-property/field/growing-season/register',
          exact: true,
          Component: GrowingSeasonRegisterScreen
        },
        pestReportRegister: {
          path: '/digital-property/field/pest-report/register',
          exact: true,
          Component: PestReportRegisterScreen
        },
        pestReportEdit: {
          path: '/digital-property/field/pest-report/edit',
          exact: true,
          Component: PestReportRegisterScreen
        },
        fieldAnalysisRegister: {
          path: '/digital-property/field/soil-analysis/register',
          exact: true,
          Component: FieldAnalysisRegisterScreen
        },
        fieldAnalysisEdit: {
          path: '/digital-property/field/soil-analysis/edit',
          exact: true,
          Component: FieldAnalysisRegisterScreen
        },
        managementRegister: {
          path: '/digital-property/field/management/register',
          exact: true,
          Component: ManagementRegisterScreen
        },
        dairyFarmDashboard: {
          path: '/digital-property/dairy-farm',
          exact: true,
          Component: DairyFarmDashboardScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ cattle } { ...props } />,
            title: t('dairy farm', { howMany: 2 })
          }
        },
        listMilkControl: {
          path: '/digital-property/dairy-farm/milk-control',
          exact: true,
          Component: ListMilkControlScreen
        },
        listMilkDelivery: {
          path: '/digital-property/dairy-farm/milk-delivery',
          exact: true,
          Component: ListMilkDeliveryScreen
        },
        listMilkQuality: {
          path: '/digital-property/dairy-farm/milk-quality',
          exact: true,
          Component: ListMilkQualityScreen
        },
        listPriceData: {
          path: '/digital-property/dairy-farm/price-data',
          exact: true,
          Component: ListPriceDataScreen
        },
        registerAnimals: {
          path: '/digital-property/dairy-farm/animals/register',
          exact: true,
          Component: RegisterAnimalsScreen
        },
        rainRecordList: {
          path: '/digital-property/rain-record',
          exact: true,
          Component: RainRecordListScreen
        },
        fieldsRankingReport: {
          path: '/digital-property/reports/ranking',
          exact: true,
          Component: FieldsRankingReportScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ dashboard } { ...props } />,
            title: t('dashboard')
          }
        },
        listProductionData: {
          path: '/digital-property/dairy-farm/production-data',
          exact: true,
          Component: ListProductionDataScreen
        },
        visitList: {
          path: '/digital-property/visit-list',
          exact: true,
          Component: PropertyTechnicalVisitProducerListScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ plantAndHand } { ...props } />,
            title: t('technical visits')
          }
        },
        reports: {
          path: '/digital-property/reports',
          exact: true,
          Component: ReportsScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ reports } { ...props } />,
            title: t('reports')
          }
        },
        infoList: {
          path: '/digital-property/info-list',
          exact: true,
          Component: PropertyInformativeScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ lamp } { ...props } />,
            title: t('informative')
          }
        }
      }
      if(shouldDisplayCosts) {
        routes = {
          ...routes,
          rubricList: {
            path: '/digital-property/costs-management',
            exact: true,
            Component: CostsScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ cost } { ...props } />,
              title: t('financial management')
            }
          }
        }
      }
      return routes
    },
    [shouldDisplayCosts, t]
  )

  const technicalRoutes = useMemo(() => {
    let routes = {}
    if (isEmpty(technicalCurrentOwner)) {
      routes = {
        fieldList: {
          path: '/technical',
          exact: true,
          Component: TechnicalAreaScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ smallPlant } { ...props } />,
            title: t('technical area')
          }
        },
        technicalVision: {
          path: '/technical-vision',
          exact: true,
          Component: TechnicalVisionReportScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ dashboard } { ...props } />,
            title: t('technical vision')
          }
        },
        infoList: {
          path: '/digital-property/info-list',
          exact: true,
          Component: PropertyInformativeScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ lamp } { ...props } />,
            title: 'Informativos'
          }
        },
        reports: {
          path: '/technical/reports',
          exact: true,
          Component: ReportsScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ reports } { ...props } />,
            title: t('reports')
          }
        },
        accreditmentList: {
          path: '/technical/accreditment',
          exact: true,
          Component: AccreditmentListScreen
        }
      }
    } else {
      routes = {
        propertyHome: {
          path: '/technical/digital-property',
          exact: true,
          Component: PropertyHomeScreen,
          menu: {
            exact: true,
            Icon: (props) => <Icon icon={ home } size={ 28 } { ...props } />,
            title: t('property', { howMany: 1 })
          }
        },
        fieldList: {
          path: '/technical/digital-property/field',
          exact: true,
          Component: FieldListScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ field } size={ 22 } { ...props } />,
            title: t('field', { howMany: 2 })
          }
        },
        rainRecordList: {
          path: '/digital-property/rain-record',
          exact: true,
          Component: RainRecordListScreen
        },
        fieldRegister: {
          path: '/technical/digital-property/field/register',
          exact: true,
          Component: FieldRegisterScreen
        },
        fieldEdit: {
          path: '/technical/digital-property/field/edit',
          exact: true,
          Component: FieldRegisterScreen
        },
        fieldDetails: {
          path: '/technical/digital-property/field/:fieldId',
          exact: true,
          Component: FieldDetailsScreen
        },
        growingSeasonRegister: {
          path: '/technical/digital-property/field/growing-season/register',
          exact: true,
          Component: GrowingSeasonRegisterScreen
        },
        pestReportRegister: {
          path: '/technical/digital-property/field/pest-report/register',
          exact: true,
          Component: PestReportRegisterScreen
        },
        pestReportEdit: {
          path: '/technical/digital-property/field/pest-report/edit',
          exact: true,
          Component: PestReportRegisterScreen
        },
        managementRegister: {
          path: '/technical/digital-property/field/management/register',
          exact: true,
          Component: ManagementRegisterScreen
        },
        fieldAnalysisRegister: {
          path: '/technical/digital-property/field/soil-analysis/register',
          exact: true,
          Component: FieldAnalysisRegisterScreen
        },
        fieldAnalysisEdit: {
          path: '/technical/digital-property/field/soil-analysis/edit',
          exact: true,
          Component: FieldAnalysisRegisterScreen
        },
        machineryList: {
          path: '/digital-property/machinery',
          exact: true,
          Component: MachineryListScreen,
          menu: {
            exact: true,
            Icon: (props) => <Icon icon={ tractor } { ...props } />,
            title: t('machinery', { howMany: 2 })
          }
        },
        machineryRegister: {
          path: '/digital-property/machinery/register',
          exact: true,
          Component: MachineryRegisterScreen
        },
        machineryEdit: {
          path: '/digital-property/machinery/edit',
          exact: true,
          Component: MachineryRegisterScreen
        },
        dairyFarmDashboard: {
          path: '/digital-property/dairy-farm',
          exact: true,
          Component: DairyFarmDashboardScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ cattle } { ...props } />,
            title: t('dairy farm', { howMany: 2 })
          }
        },
        listMilkControl: {
          path: '/digital-property/dairy-farm/milk-control',
          exact: true,
          Component: ListMilkControlScreen
        },
        listMilkDelivery: {
          path: '/digital-property/dairy-farm/milk-delivery',
          exact: true,
          Component: ListMilkDeliveryScreen
        },
        listMilkQuality: {
          path: '/digital-property/dairy-farm/milk-quality',
          exact: true,
          Component: ListMilkQualityScreen
        },
        listPriceData: {
          path: '/digital-property/dairy-farm/price-data',
          exact: true,
          Component: ListPriceDataScreen
        },
        registerAnimals: {
          path: '/digital-property/dairy-farm/animals/register',
          exact: true,
          Component: RegisterAnimalsScreen
        },
        fieldsRankingReport: {
          path: '/digital-property/reports/ranking',
          exact: true,
          Component: FieldsRankingReportScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ dashboard } { ...props } />,
            title: t('dashboard')
          }
        },
        productQuotationList: {
          path: '/technical/commercialization/products-withdraw',
          exact: true,
          Component: TechnicalCommercializationProductsListScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ currencySignRounded } { ...props } />,
            title: t('commercialization')
          }
        },
        visitList: {
          path: '/digital-property/visit-list',
          exact: true,
          Component: PropertyTechnicalVisitListScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ plantAndHand } { ...props } />,
            title: t('technical visits')
          }
        },
        listProductionData: {
          path: '/digital-property/dairy-farm/production-data',
          exact: true,
          Component: ListProductionDataScreen
        },
        reports: {
          path: '/technical/reports',
          exact: true,
          Component: ReportsScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ reports } { ...props } />,
            title: t('reports')
          }
        },
        accreditmentList: {
          path: '/technical/accreditment',
          exact: true,
          Component: AccreditmentListScreen
        }
      }
    }

    if(shouldDisplayCosts) {
      routes = { ...routes, rubricList: {
        path: '/digital-property/costs-management',
        exact: true,
        Component: CostsScreen,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ cost } { ...props } />,
          title: t('costs')
        }
      } }
    }
    return routes
  }, [shouldDisplayCosts, t, technicalCurrentOwner])

  const shoppingRoutes = useMemo(
    () => ({
      orderList: {
        path: '/shopping-platform/order',
        exact: true,
        Component: OrderListScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ shoppingPlatform } { ...props } />,
          title: t('order', { howMany: 2 })
        }
      },
      registerOrder: {
        path: '/shopping-platform/order/register',
        exact: true,
        disabled: userIsSupplier,
        Component: RegisterOrderScreen
      },
      orderDetails: {
        path: '/shopping-platform/order/:orderId',
        exact: true,
        disabled: userIsSupplier,
        Component: OrderDetailsScreen
      },
      nonComplianceOrganizationDetails: {
        path:
          '/shopping-platform/order/:orderId/non-compliance/:deliveryLocationId',
        exact: true,
        disabled: userIsSupplier,
        Component: NonComplianceScreen
      },
      acceptOrder: {
        path: '/shopping-platform/order/:orderId/accept',
        exact: true,
        disabled: userIsSupplier,
        Component: AcceptProposalScreen
      },

      joinOrder: {
        path: '/shopping-platform/order/:orderId/join',
        exact: true,
        disabled: userIsSupplier,
        Component: JoinOrderScreen
      },
      supplierQuotationList: {
        path: '/shopping-platform/supplier-quotation',
        exact: true,
        Component: SupplierQuotationListScreen,
        disabled: !userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ currency } { ...props } />,
          title: t('quotation', { howMany: 2 })
        }
      },
      supplierQuotationDetails: {
        path: '/shopping-platform/supplier-quotation/:supplierQuotationId',
        exact: true,
        Component: SupplierQuotationDetailsScreen,
        disabled: !userIsSupplier
      },
      nonComplianceSupplierDetails: {
        path: '/shopping-platform/supplier-quotation/:orderId/non-compliance',
        exact: true,
        disabled: !userIsSupplier,
        Component: NonComplianceScreen
      },
      listOrganization: {
        path: '/shopping-platform/organization',
        exact: true,
        Component: ListOrganizationScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ organization } size={ 30 } { ...props } />,
          title: t('organization', { howMany: 2 })
        }
      },
      createOrganization: {
        path: '/shopping-platform/organization/create',
        exact: true,
        Component: CreateOrganizationScreen,
        disabled: userIsSupplier
      },
      editOrganization: {
        path: '/shopping-platform/organization/edit',
        exact: true,
        Component: CreateOrganizationScreen,
        disabled: userIsSupplier
      },
      listSupplier: {
        path: '/shopping-platform/supplier',
        exact: true,
        Component: ListSupplierScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ supplier } size={ 30 } { ...props } />,
          title: t('supplier', { howMany: 2 })
        }
      },
      createSupplier: {
        path: '/shopping-platform/supplier/create',
        exact: true,
        Component: CreateSupplierScreen,
        disabled: userIsSupplier
      },
      editSupplier: {
        path: '/shopping-platform/supplier/:organizationId/edit',
        exact: true,
        Component: EditSupplierScreen,
        disabled: userIsSupplier
      },
      productList: {
        path: '/shopping-platform/product',
        exact: true,
        Component: ProductsListScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ products3d } { ...props } />,
          title: t('products')
        }
      },
      registerProduct: {
        path: '/shopping-platform/product/register',
        exact: true,
        disabled: userIsSupplier,
        Component: RegisterProductScreen
      },
      editProduct: {
        path: '/shopping-platform/product/edit',
        exact: true,
        disabled: userIsSupplier,
        Component: EditProductScreen
      },
      listProductGroup: {
        path: '/shopping-platform/product-group',
        exact: true,
        Component: ListProductGroupScreen,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ productGroup3d } { ...props } />,
          title: t('product group')
        }
      },
      createProductGroup: {
        path: '/shopping-platform/product-group/create',
        exact: true,
        Component: CreateProductGroupScreen,
        disabled: userIsSupplier
      },
      editProductGroup: {
        path: '/shopping-platform/product-group/edit',
        exact: true,
        Component: CreateProductGroupScreen,
        disabled: userIsSupplier
      },
      productWall: {
        path: '/product-wall',
        exact: true,
        Component: ProductWall,
        disabled: userIsSupplier,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ productWall } { ...props } />,
          title: t('product wall')
        }
      },
      productWallComments: {
        path: '/product-wall/comments/:postId',
        exact: true,
        Component: ProductWallComments,
        disabled: userIsSupplier
      }
    }),
    [t, userIsSupplier]
  )

  const commercializationRoutes = useMemo(
    () => ({
      productQuotationList: {
        path: '/commercialization',
        exact: true,
        Component: ProductQuotationListScreen,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ currencySignRounded } { ...props } />,
          title: t('commercialization')
        }
      },
      securitiesMovementList: {
        path: '/commercialization/securities-movement',
        exact: true,
        Component: SecuritiesMovementListScreen
      },
      barterList: {
        path: '/barter',
        exact: true,
        Component: BarterListScreen,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ arrowsBackAndForth } { ...props } />,
          title: t('barter')
        }
      },
      createBarter: {
        path: '/barter/new',
        exact: true,
        Component: CreateBarterScreen
      },
      editBarter: {
        path: '/barter/:barterOrderNumber/edit',
        exact: true,
        Component: CreateBarterScreen
      },
      infoList: {
        path: '/commercialization/info-list',
        exact: true,
        Component: PropertyInformativeScreen,
        menu: {
          exact: false,
          Icon: (props) => <Icon icon={ lamp } { ...props } />,
          title: t('informative')
        }
      }
    }),
    [t]
  )

  const administrationRoutes = useMemo(
    () => {
      let routes = {}

      if (includes(userProfile, 'detec')) {
        routes = {
          ...routes,
          crmReport: {
            path: '/reports/crm',
            exact: true,
            Component: CRMReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ dashboard } { ...props } />,
              title: t('technical visits')
            }
          }
        }
      }

      if (includes(userProfile, 'detec-milk')) {
        routes = {
          ...routes,
          crmReport: {
            path: '/reports/detec-milk',
            exact: true,
            Component: DetecMilkReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ dashboard } { ...props } />,
              title: t('milk pecuary')
            }
          }
        }
      }

      // validate permission
      // eslint-disable-next-line no-constant-condition
      if (includes(userProfile, 'cooperative_admin')) {
        routes = {
          ...routes,
          organizationIndicators: {
            path: '/reports/organization',
            exact: true,
            Component: OrganizationReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ dashboard } { ...props } />,
              title: t('organization indicators')
            }
          },
          listOrganization: {
            path: '/organizations',
            exact: true,
            Component: ListOrganizationScreen,
            disabled: userIsSupplier,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ organization } size={ 30 } { ...props } />,
              title: t('organization', { howMany: 2 })
            }
          },
          createOrganization: {
            path: '/organizations/create',
            exact: true,
            Component: CreateOrganizationScreen
          },
          editOrganization: {
            path: '/organizations/edit',
            exact: true,
            Component: CreateOrganizationScreen
          },
          fiscalFigure: {
            path: '/fiscalfigure',
            exact: true,
            Component: FiscalRegisterScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ invoice } style={ { fill: 'none' } } { ...props } />,
              title: t('fiscal register')
            }
          },
          advertising: {
            path: '/advertising',
            exact: true,
            Component: AdvertisingScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ advertising } style={ { fill: 'none' } } { ...props } />,
              title: t('advertising')
            }
          }
        }
      }
      if (includes(userProfile, 'cooperative_management')) {
        routes = {
          ...routes,
          organizationIndicators: {
            path: '/reports/organization',
            exact: true,
            Component: DirectionReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ organization } { ...props } />,
              title: t('direction')
            }
          }
        }
      }

      if (includes(userProfile, 'rtc')) {
        routes = {
          ...routes,
          organizationIndicators: {
            path: '/reports/organization',
            exact: true,
            Component: RTCReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ organization } { ...props } />,
              title: 'RTC'
            }
          },
          operations: {
            path: '/operation-365',
            exact: true,
            Component: OperationsScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ logo365 } { ...props } />,
              title: t('operation 365')
            }
          }
        }
      }

      if (includes(userProfile, 'management_federation')) {
        routes = {
          ...routes,
          organizationIndicators: {
            path: '/reports/organization',
            exact: true,
            Component: DirectionReportScreenNoFilters,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ organization } { ...props } />,
              title: t('direction')
            }
          }
        }
      }

      if (includes(userProfile, 'smartcoop')) {
        routes = {
          ...routes,
          userReport: {
            path: '/reports/user',
            exact: true,
            Component: UserReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ user } { ...props } />,
              title: t('user', { howMany: 2 })
            }
          },
          salesReport: {
            path: '/reports/shopping',
            exact: true,
            Component: ShoppingReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ shoppingPlatform } { ...props } />,
              title: t('shopping')
            }
          },
          advertising: {
            path: '/advertising',
            exact: true,
            Component: AdvertisingScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ advertising } style={ { fill: 'none' } } { ...props } />,
              title: t('advertising')
            }
          }
        }
      }
      if (includes(userProfile, 'smartcoop_buyer')) {
        routes = {
          ...routes,
          salesReport: {
            path: '/reports/shopping',
            exact: true,
            Component: ShoppingReportScreen,
            menu: {
              exact: false,
              Icon: (props) => <Icon icon={ shoppingPlatform } { ...props } />,
              title: t('shopping')
            }
          }
        }
      }

      routes = {
        ...routes,
        infoList: {
          path: '/administration/info-list',
          exact: true,
          Component: PropertyInformativeScreen,
          menu: {
            exact: false,
            Icon: (props) => <Icon icon={ lamp } { ...props } />,
            title: t('informative')
          }
        },
        informativeRegister: {
          path: '/administration/informative-register',
          exact: true,
          Component: RegisterInformativeScreen
        }
      }

      return routes
    },
    [t, userIsSupplier, userProfile]
  )

  const routes = useMemo(() => {
    if (!permissionsLoaded) {
      return {}
    }

    const getRoutesByModule = () => {
      let adminRoutes = {}

      switch (currentModule) {
        case AVAILABLE_MODULES.digitalProperty:
          if (permissionWeatherStations) {
            return {
              ...propertyRoutes,
              ...commonRoutes,
              ...weatherStationsRoutes
            }
          }
          return {
            ...propertyRoutes,
            ...commonRoutes
          }
        // TODO: Recolocar Rede social em HML/PRD eventualmente
        case AVAILABLE_MODULES.shoppingPlatform:
          return process.env.REACT_APP_AMBIENTE === 'development'
            ? {
              ...shoppingRoutes,
              ...commonRoutes,
              ...socialNetworkRoutes
            }
            : {
              ...shoppingRoutes,
              ...commonRoutes
            }
        // TODO: Recolocar Rede social em HML/PRD eventualmente
        case AVAILABLE_MODULES.commercialization:
          return process.env.REACT_APP_AMBIENTE === 'development'
            ? {
              ...commercializationRoutes,
              ...commonRoutes,
              ...socialNetworkRoutes
            }
            : {
              ...commercializationRoutes,
              ...commonRoutes
            }
        case AVAILABLE_MODULES.technical:
          if (permissionWeatherStations) {
            return {
              ...technicalRoutes,
              ...commonRoutes,
              ...weatherStationsRoutes
            }
          }
          return {
            ...technicalRoutes,
            ...commonRoutes
          }
        case AVAILABLE_MODULES.administration:
          adminRoutes = { ...administrationRoutes }

          if (permissionWeatherStations) {
            adminRoutes = { ...adminRoutes, ...weatherStationsRoutes }
          }

          return { ...adminRoutes, ...commonRoutes }
        default:
          return {
            ...withoutModuleRoutes,
            ...commonRoutes
          }
      }
    }
    return omitBy(getRoutesByModule(), (route) => route.disabled)
  }, [permissionsLoaded, currentModule, propertyRoutes, commonRoutes, shoppingRoutes, socialNetworkRoutes, commercializationRoutes, permissionWeatherStations, technicalRoutes, administrationRoutes, weatherStationsRoutes, withoutModuleRoutes])

  return { routes }
}
