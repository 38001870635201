export const TYPE_ANIMALS = {
  animals: 'animals',
  bulls: 'bulls'
}

export const PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS = [
  {
    label: 'Fungicidas e biofungicidas ',
    value: 'fungicida'
  },
  {
    label: 'Herbicidas e bioherbicidas',
    value: 'herbicidas'
  },
  {
    label: 'Inseticidas e bioinseticidas',
    value: 'inseticida'
  },
  {
    label: 'Adjuvante',
    value: 'adjuvante'
  },
  {
    label: 'Óleo',
    value: 'oleo'
  },
  {
    label: 'Fertilizantes/Estimulantes/Bioativadores',
    value: 'fertilizantes-estimulantes-bioativadores'
  },
  {
    label: 'Regulador de crescimento',
    value: 'regulador-de-crescimento'
  }
]


export const PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS_OBJECT = {
  'fungicida': 'Fungicidas e biofungicidas ',
  'herbicidas': 'Herbicidas e bioherbicidas',
  'inseticida': 'Inseticidas e bioinseticidas',
  'adjuvante': 'Adjuvante',
  'oleo': 'Óleo',
  'fertilizantes-estimulantes-bioativadores': 'Fertilizantes/Estimulantes/Bioativadores',
  'regulador-de-crescimento': 'Regulador de crescimento'
}

export const SEEDTREATMENT_PRODUCT_GROUPS = [
  {
    label: 'Fungicidas e biofungicidas ',
    value: 'fungicida'
  },
  {
    label: 'Inseticidas e bioinseticidas',
    value: 'inseticida'
  },
  {
    label: 'Fertilizantes/Estimulantes/Bioativadores',
    value: 'fertilizantes-estimulantes-bioativadores'
  },
  {
    label: 'Inoculantes',
    value: 'inoculantes'
  }
]

export const PROTOCOL_TYPES = [{
  label: 'Protocolo de IA',
  value: 'protocolo_de_ia'
},{
  label: 'Indução de lactação',
  value: 'inducao_de_lactacao'
}]

export const PROTOCOL_TYPES_SLUGS = {
  'protocolo_de_ia': 'Protocolo de IA',
  'inducao_de_lactacao': 'Indução de Lactação'
}

export const SEEDTREATMENT_PRODUCT_GROUPS_OBJECT = {
  'fungicida': 'Fungicidas e biofungicidas ',
  'inseticida': 'Inseticidas e bioinseticidas',
  'fertilizantes-estimulantes-bioativadores': 'Fertilizantes/Estimulantes/Bioativadores',
  'inoculantes': 'Inoculantes'
}

export const OPERATION_365_FIELDS = [
  { 'ID': 'Intensificação da diversificação (meses)' },
  { 'PGV': 'Presença de gramíneas de verão (unidade)' },
  { 'PGI': 'Presença de gramíneas de inverno (unidade)' },
  { 'HCC': 'Histórico do controle de custos (nota)' },
  { 'CRI': 'Culturas com renda no inverno (unidade)' },
  { 'SC': 'Semeadura em contorno (nota)' },
  { 'CS': 'Compactação de solo (nota)' },
  { 'FE': 'Frequência de ocorrência de erosão (nota)' },
  { 'AE': 'Adubação equilibrada (nota)' },
  { 'AS' : 'Acidez do solo (nota)' },
  { 'ECSS': 'Espessura da camada superficial do solo com estrutura em grumos (cm)' }
]
export const FIELD_ANALYSIS_RESULT = [
  {
    name: 'Argila',
    slug: 'argila',
    unit: '%'
  },{
    name: 'Areia',
    slug: 'areia',
    unit: '%'
  },{
    name: 'Silte',
    slug: 'silte',
    unit: '%'
  },{
    name: 'pH H2O',
    slug: 'ph',
    unit: ''
  },{
    name: 'Índice SMP',
    slug: 'indice_smp',
    unit: ''
  },{
    name: 'P (Fósforo)',
    slug: 'fosforo',
    unit: 'mg dm-3'
  },{
    name: 'K (Potássio)',
    slug: 'potassio',
    unit: 'mg dm-3'
  },{
    name: 'M.O. (Matéria Orgânica)',
    slug: 'materia_organica',
    unit: '%'
  },{
    name: 'Al (Alumínio)',
    slug: 'aluminio',
    unit: 'cmolcdm-3'
  },{
    name: 'Ca (Cálcio)',
    slug: 'calcio',
    unit: 'cmolcdm-3'
  },{
    name: 'Mg (Magnésio)',
    slug: 'magnesio',
    unit: 'cmolcdm-3'
  },{
    name: 'H + Al (Hidrogênio + Alumínio)',
    slug: 'hidrogenio_aluminio',
    unit: 'cmolcdm-3'
  },{
    name: 'CTC',
    slug: 'ctc',
    unit: 'cmolcdm-3'
  },{
    name: 'Saturação de Bases',
    slug: 'saturacao_bases',
    unit: '%'
  },{
    name: 'Saturação de Alumínio',
    slug: 'saturacao_aluminio',
    unit: '%'
  },{
    name: 'S (Enxofre)',
    slug: 'enxofre',
    unit: 'mg dm-3'
  },{
    name: 'Zn (Zinco)',
    slug: 'zinco',
    unit: 'mg dm-3'
  },{
    name: 'Cu (Cobre)',
    slug: 'cobre',
    unit: 'mg dm-3'
  },{
    name: 'B (Boro)',
    slug: 'boro',
    unit: 'mg dm-3'
  },{
    name: 'Mn (Manganês)',
    slug: 'manganes',
    unit: 'mg dm-3'
  },{
    name: 'Fe (Ferro)',
    slug: 'ferro',
    unit: 'g dm-3'
  }
]

export const NFE_ORIGIN = [
  {
    label: '0 - Nacional',
    value: '0'
  },{
    label: '1 - Estrangeira - Importação direta',
    value: '1'
  },{
    label: '2 - Estrangeira - Adquirida no mercado interno',
    value: '2'
  },{
    label: '3 - Nacional - Conteúdo de Importação > 40% <= 70%',
    value: '3'
  },{
    label: '4 - Nacional - Produção em conformidade com a Legislação',
    value: '4'
  },{
    label: '5 - Nacional - Conteúdo de Importação <= 40%',
    value: '5'
  },{
    label: '6 - Estrangeira - Importação direta, sem similar nacional',
    value: '6'
  },{
    label: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional',
    value: '7'
  },{
    label: '8 - Nacional - Conteúdo de Importação superior > 70%',
    value: '8'
  }
]

export const SLUG_ANIMALS_STATUS = {
  prenha: 'prenha',
  inseminada: 'inseminada',
  inseminadaAConfirmar: 'inseminada_a_confirmar',
  pev: 'pev',
  aptas: 'aptas',
  vazia: 'vazia',
  nenhum: 'nenhum',
  descarte: 'descarte'
}

export const MEDICATION_CONTROL_COLORS = {
  'calendario-de-vacinacao': '#199cd8',
  'diagnostico-de-doenca': '#e41d1b',
  'secagem': '#289f30'
}

export const ACCREDITMENT_STATUS_COLORS = {
  'AGUARDANDO_APROVACAO': '#199cd8',
  'RECUSADO': '#e41d1b',
  'APROVADO': '#289f30',
  'EXPIRADO': '#1d1d1b',
  'DOC_PENDENTE': '#f57d0d'
}

export const ACCREDITMENT_STATUS_NAMES = {
  'AGUARDANDO_APROVACAO': 'Aguardando Aprovação',
  'RECUSADO': 'Recusado',
  'APROVADO': 'Aprovado',
  'EXPIRADO': 'Expirado',
  'DOC_PENDENTE': 'Pendente de Documentação'
}

export const MEDICATION_CONTROL_NAMES = {
  'calendario-de-vacinacao': 'Calendário de Vacinação',
  'diagnostico-de-doenca': 'Diagnóstico de Doença',
  'secagem': 'Secagem'
}

export const AnimalStatusCode = {
  VAZIA: '1',
  INSEMINADA: '2',
  INSEMINADA_A_CONFIRMAR: '3',
  PRENHA: '4',
  PEV: '5',
  APTAS: '6',
  NENHUM: '7',
  DESCARTE: '8',
  EM_PROTOCOLO: '9'
}

export const ANIMALS_CATEGORY = {
  touro: 'touro',
  boi: 'boi',
  terneira: 'terneira',
  novilha: 'novilha',
  vaca: 'vaca'
}

export const ProposalStatusCode = {
  AGUARDANDO_PROPOSTA: 0,
  PARTICIPANDO: 1,
  REJEITADO: 2,
  AGUARDANDO_RESPOSTA: 3,
  APROVADO: 4,
  RECUSADO: 5,
  ENTREGUE: 6,
  ATRASADO: 7,
  EXPIRADO: 8,
  INVALID: 9,
  AGUARDANDO_COOPERATIVAS: 10
}

export const WEATHER_STATIONS_DESCRIPTIONS = {
  TEMPERATURA_DO_AR: 'at1',
  TEMPERATURA_DO_AR_2: 'at2',
  TEMPERATURA_INTERIOR_DOSSEL: 'atd',
  DIRECAO_DO_VENTO: 'wd1',
  UMIDADE_DO_AR: 'ah1',
  UMIDADE_DO_AR_DOSSEL: 'ahd',
  VELOCIDADE_DO_VENTO: 'ws1',
  RADIACAO_SOLAR_MEDIA: 'asr1',
  RAJADA_DE_VENTO: 'wg1',
  TEMPERATURA_DO_SOLO: 'st1',
  TEMPERATURA_DO_SOLO_2: 'st2',
  ETO: 'eto',
  TENSAO_DA_BATERIA: 'bv1',
  UMIDADE_VOLUMETRICA: 'wc1',
  PONTO_DE_ORVALHO: 'dp1',
  PRECIPITACAO: 'pl1',
  PRECIPITACAO_ACUMULADA: 'pl1Sum'
}

export const DemandStatusCode = {
  ABERTA: 1,
  FINALIZADO: 2,
  CANCELADA: 3,
  AGUARDANDO_RESPOSTA: 4,
  AGUARDANDO_DECISAO: 5,
  PROPOSTA_ACEITA: 6,
  RECUSADO: 7,
  AGUARDANDO_ENTREGA: 8,
  ATRASADO: 9,
  ENTREGUE: 10,
  EXPIRADO: 11
}

export const TechnicalPortfolioStatusCode = {
  AGUARDANDO_RESPOSTA: 1,
  NEGADO: 2,
  REVOGADO: 3,
  SOMENTE_VISUALIZAÇÃO: 4,
  VISUALIZAÇÃO_EDIÇÃO: 5,
  SOMENTE_ASSISTENCIA: 6
}

export const PROFILES = {
  admin: 'admin',
  associatedRuralProducer: 'associated-rural-producer',
  buyer: 'buyer',
  commercialGrain: 'commercial-grain',
  commercialInputs: 'commercial-inputs',
  detec: 'detec',
  rtc: 'rtc',
  detecMilk: 'detec-milk',
  federation: 'federation',
  cooperativeManagement: 'cooperative_management',
  nonAssociatedRuralProducer: 'non-associated-rural-producer',
  smartcoop: 'smartcoop',
  supplier: 'supplier',
  technical: 'technical',
  familyGroup: 'family-group'
}

export const ChatMessageStatus = {
  SENDED: 'SENT',
  RECEIVED: 'RECEIVED',
  VISUALIZED: 'READ'
}

export const STAMP_COLORS = {
  carbon: '#000',
  platinum: '#949392',
  gold: '#FFD700',
  not_qualified: '#e41d1b',
  none: '#e41d1b'
}

export const CALCULATED_STAMP = (value) => {
  if(value >= 7 && value < 8) {
    return {
      color: STAMP_COLORS.gold,
      name: 'gold'
    }
  }
  if(value >= 8 && value < 9) {
    return {
      color: STAMP_COLORS.platinum,
      name: 'platinum'
    }
  }
  if(value >= 9) {
    return {
      color: STAMP_COLORS.carbon,
      name: 'carbon'
    }
  }
  return {
    color: STAMP_COLORS.none,
    name: 'not qualified'
  }
}
export const scaleColors = [
  { strokeColor: '#910000', color: '#D70000', text: '< 26', min: 0, max: 25 },
  { strokeColor: '#C55E00', color: '#FF7A00', text: '26 - 57', min: 26, max: 57 },
  { strokeColor: '#A89800', color: '#FFE600', text: '58 - 96', min: 58, max: 96 },
  { strokeColor: '#097000', color: '#0C9300', text: '97 - 145', min: 97, max: 145 },
  { strokeColor: '#016E86', color: '#00B0D7', text: '146 - 203', min: 146, max: 203 },
  { strokeColor: '#003668', color: '#0070D7', text: '204 - 271', min: 204, max: 271 },
  { strokeColor: '#010F5A', color: '#0022D7', text: '272 - 352', min: 272, max: 352 },
  { strokeColor: '#3F0166', color: '#9E00FF', text: '> 352', min: 352, max: 10000 }
]

export const INSEMINATION_TYPES = {
  'IATF': '1',
  'IA - CIO Visual': '2',
  'IA - CIO monitorado': '3',
  'TE': '4',
  'Monta': '5',
  'IA - PGF': '6'
}

export const PROFILES_IDS = {
  SUPPLIER: '1',
  TECHNICIAN: '6',
  PRODUCTOR: '2',
  FAMILY_MEMBER: '3',
  BUYER: '4',
  ADMIN: '5',
  SMARTCOOP: '11'
}

export const MODULES_IDS = {
  digitalProperty: '1',
  shoppingPlatform: '3',
  commercialization: '2',
  technical: '4',
  administration: '5'
}

export const FORM_INPUT_TYPES = {
  area: 'ARE',
  text: 'INP',
  number: 'NUM',
  calculated: 'CLC',
  select: 'SLT',
  selectAlert: 'SLA',
  selectMultiple: 'MLT',
  month: 'MES',
  year: 'ANO',
  monthYear: 'MEA',
  date: 'DAT',
  hour: 'HOR',
  image: 'IMG',
  signature: 'ASS',
  information: 'INF',
  checkbox: 'CHK',
  radio: 'RDO'
}

export const LOT_SLUG_OPTIONS = [
  {
    label: 'Novilha',
    value: 'novilha'
  },
  {
    label: 'Seca',
    value: 'seca'
  },
  {
    label: 'Em lactação',
    value: 'em-lactacao'
  },
  {
    label: 'Pré Parto',
    value: 'pre-parto'
  },
  {
    label: 'Terneira',
    value: 'terneira'
  },
  {
    label: 'Em aleitamento',
    value: 'em-aleitamento'
  },
  {
    label: 'Terminação',
    value: 'terminacao'
  },
  {
    label: 'Outros',
    value: 'outros'
  }
]

export const LOT_SLUG_OPTIONS_OBJECT = {
  novilha: 'Novilha',
  seca: 'Seca',
  'em-lactacao': 'Em lactação',
  'pre-parto': 'Pré Parto',
  terneira: 'Terneira',
  'em-aleitamento': 'Em aleitamento',
  terminacao: 'Terminação',
  outros: 'Outros'
}


export const RECOMMENDED_ANSWERS = ['Não Atende', 'Não']

export const hourOptions = [
  {
    label: '00:00',
    value: '00:00'
  },{
    label: '01:00',
    value: '01:00'
  },{
    label: '02:00',
    value: '02:00'
  },{
    label: '03:00',
    value: '03:00'
  },{
    label: '04:00',
    value: '04:00'
  },{
    label: '05:00',
    value: '05:00'
  },{
    label: '06:00',
    value: '06:00'
  },{
    label: '07:00',
    value: '07:00'
  },{
    label: '08:00',
    value: '08:00'
  },{
    label: '09:00',
    value: '09:00'
  },{
    label: '10:00',
    value: '10:00'
  },{
    label: '11:00',
    value: '11:00'
  },{
    label: '12:00',
    value: '12:00'
  },{
    label: '13:00',
    value: '13:00'
  },{
    label: '14:00',
    value: '14:00'
  },{
    label: '15:00',
    value: '15:00'
  },{
    label: '16:00',
    value: '16:00'
  },{
    label: '17:00',
    value: '17:00'
  },{
    label: '18:00',
    value: '18:00'
  },{
    label: '19:00',
    value: '19:00'
  },{
    label: '20:00',
    value: '20:00'
  },{
    label: '21:00',
    value: '21:00'
  },{
    label: '22:00',
    value: '22:00'
  },{
    label: '23:00',
    value: '23:00'
  }
]

export const AllowedDocumentTypesEnum = [
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/xls',
  'application/xlsx',
  'application/ppt',
  'application/pptx',
  'application/doc',
  'application/docx',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'text/rtf',
  'text/plain'
]

export const AllowedDocumentTypesExtensions = [
  '.html',
  '.xhtml',
  '.odt',
  '.pdf',
  '.xls',
  '.xlsx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.csv',
  '.rtf',
  '.txt'
]

export const AllowedImageTypesEnum = [
  'image/png',
  'image/tiff',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/bmp'
]

export const AllowedImageTypesExtensions = [
  '.png',
  '.tiff',
  '.jpg',
  '.jpeg',
  '.gif',
  '.bmp'
]

export const AllowedVideoTypesEnum = [
  'video/x-matroska',
  'video/mp4',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/webm'
]

export const AllowedAudioTypesEnum = [
  'audio/mpeg',
  'audio/mp4'
]

export const AllowedAudioTypesExtensions = [
  '.mp3'
]

export const AllowedVideoTypesExtensions = [
  '.avi',
  '.mov',
  '.mp4',
  '.webm',
  '.wmv',
  '.avi'
]

export const FileUploadsTypesEnum = {
  forward: 'forward',
  attach: 'attach',
  record: 'record'
}

export const AllowedSizeFile = 16777216

export const ConversationSidebarEnum = {
  SEARCH: 'SEARCH',
  DETAILS: 'DETAILS'
}

// Total Members -> 200 - Current User
export const MaxSizeOfGroupMembers = 199

export const getPropertyAssetsStatus = (t) => ({
  'ativo': 'Ativo',
  'inativo': 'Inativo',
  'vendido': 'Vendido',
  'baixado': 'Baixado'
}[t])
