import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { getAllAnimalDrying as getAllAnimalDryingService } from '@smartcoop/services/apis/smartcoopApi/resources/animalPregnancyActions'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import {
  momentBackDateFormat,
  momentBackDateTimeFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import FilterPregnancyActionsModal from '@smartcoop/web-containers/modals/dairyFarm/FilterPregnancyActionsModal'
import RegisterPregnancyActionsModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterPregnancyActionsModal'

import { Top } from './styles'

const DryingListFragment = () => {
  const t = useT()
  const { createDialog } = useDialog()
  const tableRef = useRef(null)

  const currentProperty = useSelector(selectCurrentProperty)
  const currentAnimal = useSelector(selectCurrentAnimal)
  const dispatch = useCallback(useDispatch(), [])

  const [filters, setFilters] = useState({})

  const urlParams = useMemo(
    () => ({ propertyId: currentProperty?.id, animalId: currentAnimal.id }),
    [currentAnimal, currentProperty]
  )

  const registerDisabled = useMemo(
    () => currentAnimal?.animalStatus?.name !== 'Prenha',
    [currentAnimal]
  )

  const queryParams = useMemo(() => ({ ...filters }), [filters])

  const columns = useMemo(
    () => [
      {
        title: t('insemination date'),
        field: 'inseminationDate',
        render: (row) =>
          moment(
            row?.insemination?.inseminationDate,
            momentBackDateFormat
          ).format(momentFriendlyDateFormat),
        sorting: true
      },
      {
        title: t('earring'),
        field: 'earringCode',
        render: row => row?.animal?.earring?.earringCode,
        sorting: true
      },
      {
        title: t('animal', { howMany: 1 }),
        field: 'animal.name',
        sorting: true
      },
      {
        title: t('accomplished date'),
        field: 'accomplishedDate',
        sorting: true,
        render: (row) =>
          row.accomplishedDate
            ? moment(row.accomplishedDate, momentBackDateTimeFormat).format(
              momentFriendlyDateFormat
            )
            : t('not accomplished', { gender: 'female' })
      },
      {
        title: t('type'),
        field: 'type',
        sorting: true,
        render: (row) => {
          switch (row.type) {
            case 'secagem':
              return t('drying')
            case 'aborto':
              return t('abortion')
            case 'pre-parto':
            default:
              return t('prepartum')
          }
        }
      }
    ],
    [t]
  )

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const handleSetFilters = useCallback((filterValues) => {
    setFilters({
      ...filterValues,
      q: filterValues?.name ?? null
    })
  }, [])

  const openFilterModal = useCallback(() => {
    createDialog({
      id: 'filter-fields',
      Component: FilterPregnancyActionsModal,
      props: {
        onSubmit: handleSetFilters,
        filters
      }
    })
  }, [createDialog, filters, handleSetFilters])

  const openCreateModal = useCallback(
    (_, data = {}) => {
      createDialog({
        id: 'create-fields',
        Component: RegisterPregnancyActionsModal,
        props: {
          onSubmit: reloadDataTable,
          data
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  return (
    <>
      <Top>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div>
        <div>
          <Button
            id="register-lot"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n>register</I18n>
          </Button>
        </div>
      </Top>
      <DataTable
        data={ getAllAnimalDryingService }
        queryParams={ queryParams }
        columns={ columns }
        urlParams={ urlParams }
        tableRef={ tableRef }
        onEditClick={ openCreateModal }
        id="drying-list-table"
      />
    </>
  )
}

export default DryingListFragment
