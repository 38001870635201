import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


import colors from '@smartcoop/styles/colors'
import fonts from '@smartcoop/styles/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px 30px;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled(Typography)`
  color: ${ colors.text };
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
  font-family: ${ fonts.fontFamilyMontserrat };
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  margin-bottom: 22px;
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
`

export const Text = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const WarningDiv = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  width: 200px;
  background-color: rgba(254,241,182,0.8);
  margin-right: 10px;
  text-align: center;
`

const useStyles = makeStyles(() => ({
  white: {
    color: colors.white,
    backgroundColor: 'transparent',
    border: `1px solid ${ colors.white }`,
    width: 25,
    height: 25
  }
}))

export default useStyles
