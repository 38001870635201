import React, { useState, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { ThemeProvider } from '@material-ui/core'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { selectCurrentOrganization } from '@smartcoop/stores/organization/selectorOrganization'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import InputSearch from '@smartcoop/web-components/InputSearch'
import AccountBalanceListFragment from '@smartcoop/web-containers/fragments/commercialization/AccountBalanceListFragment'
import ProductBalanceListFragment from '@smartcoop/web-containers/fragments/commercialization/ProductBalanceListFragment'
import ProductsQuotationListFragment from '@smartcoop/web-containers/fragments/commercialization/ProductsQuotationListFragment'
import ProductsWitdrawListFragment from '@smartcoop/web-containers/fragments/commercialization/ProductsWitdrawListFragment'
import SalesOrdersListFragment from '@smartcoop/web-containers/fragments/commercialization/SalesOrdersListFragment'
import useTerm from '@smartcoop/web-containers/hooks/useTerm'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import CreateOrderModal from '@smartcoop/web-containers/modals/commercialization/CreateOrderModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  AccountBalanceContainer,
  Row,
  ButtonContainer,
  SearchContainer,
  inputSearchTheme,
  TabButton
} from './styles'


const ProductQuotationListScreen = () => {
  const history = useHistory()
  const t = useT()
  const { routes } = useRoutes()
  const tableRef = useRef(null)
  useTerm('commercialization-term')

  const { createDialog, removeDialog } = useDialog()

  const [listType, setListType] = useState('quotation')
  const [filterText, setFilterText] = useState('')
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [lastUpdatedAt, setLastUpdatedAt] = useState('')

  const currentOrganization = useSelector(selectCurrentOrganization)

  const tableFilters = useMemo(
    () => debouncedFilterText ? ({ q: debouncedFilterText }) : ({}),
    [debouncedFilterText]
  )

  const isSalesOrdersList = useMemo(
    () => listType === 'salesOrders',
    [listType]
  )

  const urlParams = useMemo(
    () => ({ organizationId: currentOrganization.id }),
    [currentOrganization]
  )

  const dataTableList = useMemo(
    () => {
      switch (listType) {
        case 'productBalance':
          return (
            <ProductBalanceListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
              setLastUpdatedAt={ setLastUpdatedAt }
            />
          )
        case 'productsWithdraw':
          return (
            <ProductsWitdrawListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
              setLastUpdatedAt={ setLastUpdatedAt }
            />
          )
        case 'salesOrders':
          return (
            <SalesOrdersListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
              ref={ tableRef }
            />
          )
        case 'quotation':
        default:
          return (
            <ProductsQuotationListFragment
              filters={ tableFilters }
              urlParams={ urlParams }
              setLastUpdatedAt={ setLastUpdatedAt }
            />
          )
      }
    },
    [listType, tableFilters, urlParams]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setLastUpdatedAt('')
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const onAccountClick = useCallback(
    () => {
      history.push(routes.securitiesMovementList.path)
    },
    [history, routes.securitiesMovementList.path]
  )

  const onNewOrderClick = useCallback(
    () => {
      createDialog({
        id: 'create-oreder-modal',
        Component: CreateOrderModal,
        props: {
          handleClose: () => {
            const { query } = tableRef.current.state
            tableRef.current.onQueryChange({
              ...query,
              page: 0
            })
            removeDialog({ id: 'create-oreder-modal' })
          }
        }
      })
    },
    [createDialog, removeDialog]
  )

  return (
    <SplitedScreenLayout
      withoutGoBack
      withoutDivider
      withoutTitle
      divRightStyle={ {
        backgroundColor: colors.white,
        width: '100%',
        height: '100%',
        padding: 0
      } }
      rightChildren={ (
        <Container>
          <Row>
            <ButtonContainer>
              <TabButton
                id="quotation"
                onClick={ () => {
                  setLastUpdatedAt('')
                  setListType('quotation')
                } }
                color={ listType === 'quotation' ? colors.black : colors.white }
              >
                <I18n params={ { howMany: 1 } }>quotation</I18n>
              </TabButton>
              <TabButton
                id="product-balance"
                onClick={ () => {
                  setLastUpdatedAt('')
                  setListType('productBalance')
                } }
                color={ listType === 'productBalance' ? colors.black : colors.white }
              >
                <I18n>products balance</I18n>
              </TabButton>
              <TabButton
                id="quotation"
                onClick={ () => {
                  setLastUpdatedAt('')
                  setListType('productsWithdraw')
                } }
                color={ listType === 'productsWithdraw' ? colors.black : colors.white }
              >
                <I18n>supplies withdrawal</I18n>
              </TabButton>
              <TabButton
                id="sales-orders"
                onClick={ () => {
                  setLastUpdatedAt('')
                  setListType('salesOrders')
                } }
                color={ listType === 'salesOrders' ? colors.black : colors.white }
              >
                <I18n>sales orders</I18n>
              </TabButton>
            </ButtonContainer>
            <SearchContainer>
              <ThemeProvider theme={ inputSearchTheme }>
                <InputSearch
                  detached
                  onChange={ onChangeSearchFilter }
                  value={ filterText }
                  placeholder={ t('search') }
                  fullWidth
                />
              </ThemeProvider>
              {isSalesOrdersList && (
                <Button
                  id="filter-order"
                  onClick={ onNewOrderClick }
                  color={ colors.secondary }
                  style={ { flex: '0 0 auto', marginBottom: 22, marginLeft: 8 } }
                >
                  <I18n>new order</I18n>
                </Button>
              )}
            </SearchContainer>
          </Row>
          <Row style={ { fontStyle: 'italic', justifyContent: 'flex-end', marginBottom: 5 } }>
            {!isEmpty(lastUpdatedAt) && `${ t('data updated the last time at') } ${ lastUpdatedAt }`}
          </Row>
          {!isEmpty(currentOrganization) && (
            dataTableList
          )}
        </Container>
      ) }
      appendChildren={
        <AccountBalanceContainer>
          <AccountBalanceListFragment clickable onClick={ onAccountClick }/>
        </AccountBalanceContainer>
      }
    />
  )
}

export default ProductQuotationListScreen
