import React, { useMemo, useCallback, useState, useEffect } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  trash,
  pencil
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import colors from '@smartcoop/styles/colors'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import PictureModal from '@smartcoop/web-components/Modal/PictureModal'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'


import {
  DetailsContainer,
  Title,
  Item,
  Info,
  Subtitle,
  BlackSubtitle,
  Text,
  ImagesContainer,
  Actions
} from './styles'

const PestReportDetailsFragment = props => {
  const { pestReport, onDelete , onEdit, isFromHistory, readOnly, accessToken } = props
  const t = useT()
  const { createDialog } = useDialog()
  const [imageFiles, setImageFiles] = useState([])

  const values = useMemo(
    () => (
      {
        type: pestReport?.reportTypes?.name || pestReport?.reportType?.name || '',
        name: pestReport[pestReport?.reportTypes?.slug]?.name || pestReport?.disease?.name || '',
        date: moment(pestReport.occurenceDate, momentBackDateFormat).format(momentFriendlyDateFormat),
        damage: (`${ pestReport?.damage  }%`) ?? '',
        observations: pestReport?.observations ?? '',
        description: pestReport?.description ?? '',
        reportImages: !isEmpty(pestReport?.reportImages) ? pestReport.reportImages : [],
        erosionType: pestReport?.details?.type ?? null
      }
    ), [pestReport]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_PLAGUE_REPORT_ID, fileKey: file?.fileKey }, accessToken)

    return downloadFromBase64(data)
  }, [accessToken])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(map(values?.reportImages, async(file) => {
        const fileSrc = await renderFile(file)
        return ({ ...file, fileSrc })
      }))

      setImageFiles(map(receivedElements, (file, index) => (
        <ThumbnailImage
          key={ file?.fileId }
          src={ file?.fileSrc }
          cursor="pointer"
          size={ 80  }
          onClick={ () => createDialog({
            id: `picture-modal-${ file?.fileKey }`,
            Component: PictureModal,
            props: {
              src: receivedElements,
              initialFirstItem: index
            }
          }) }
        />)))
    }

    getRenderedFiles()
  },[createDialog, renderFile, values.reportImages])

  const onDeleteReport = useCallback(
    () => {
      onDelete(pestReport.id)
    }, [onDelete, pestReport.id]
  )

  const onEditReport = useCallback(
    () => {
      onEdit({ ...pestReport })
    }, [onEdit, pestReport]
  )

  const confirmDelete = useCallback(
    () => (
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          textWarning: t('are you sure you want to delete this pest report?'),
          onConfirm: onDeleteReport
        }
      })
    ),
    [createDialog, onDeleteReport, t]
  )

  return (
    <DetailsContainer>
      {!readOnly && (
        <Title>
          <I18n>occurrence</I18n>
        </Title>
      )}
      {!readOnly && (
        <Divider/>
      )}

      <Item>
        <Info>
          <Subtitle>
            <I18n>
              occurrence
            </I18n>
          </Subtitle>
          <Text>
            {values?.type} { values.name && '-'} {values.name} {values?.erosionType && `- ${ capitalize(values?.erosionType) }`}
          </Text>
        </Info>
        {!isFromHistory && !readOnly && (
          <Actions>
            <IconButton
              id="edit-pest-report"
              color={ colors.white }
              style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
              onClick={ onEditReport }
            >
              <Icon icon={ pencil } size={ 17 } style={ { marginRight: 5 } } />
            </IconButton>
            <IconButton
              id="delete-pest-report"
              style={ { padding: '5.5px 10px', fontSize: '0.875rem', marginRight: 10 } }
              color={ colors.white }
              onClick={ confirmDelete }
            >
              <Icon size={ 17 } icon={ trash } color={ colors.red } />
            </IconButton>
          </Actions>
        )}
      </Item>
      <Item>
        <Info>
          <Subtitle>
            <I18n params={ {
              howMany: 1
            } }
            >
              date
            </I18n>
          </Subtitle>
          <Text>
            {values?.date}
          </Text>
        </Info>
        <Info>
          <Subtitle>
            <I18n>
              damage done
            </I18n>
          </Subtitle>
          <Text>
            {values?.damage}
          </Text>
        </Info>
      </Item>
      <Item>
        {values?.description && (
          <Info>
            <Subtitle>
              <I18n>
              description
              </I18n>
            </Subtitle>
            <Text>
              {values?.description}
            </Text>
          </Info>
        )}
        {values?.observations && (
          <Info>
            <Subtitle>
              <I18n>
              observations
              </I18n>
            </Subtitle>
            <Text>
              {values?.observations}
            </Text>
          </Info>
        )}
      </Item>
      {
        !isEmpty(pestReport.reportImages) && (
          <Item>
            <Info>
              <BlackSubtitle>
                <I18n>
                  images
                </I18n>
              </BlackSubtitle>
              <ImagesContainer>
                {imageFiles}
              </ImagesContainer>
            </Info>
          </Item>
        )
      }
    </DetailsContainer>
  )
}

PestReportDetailsFragment.propTypes = {
  pestReport: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  accessToken: PropTypes.string,
  isFromHistory: PropTypes.bool,
  readOnly: PropTypes.bool
}

PestReportDetailsFragment.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  accessToken: null,
  isFromHistory: false,
  readOnly: false
}

export default PestReportDetailsFragment
