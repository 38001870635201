import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n from '@smartcoop/i18n'
import { filter } from '@smartcoop/icons'
import { getSuppliers as getSuppliersService } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { formatCpfCnpj } from '@smartcoop/utils/formatters'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import InputSearchDebounced from '@smartcoop/web-components/InputSearchDebounced'
import FilterSupplierListModal from '@smartcoop/web-containers/modals/shoppingPlatform/FilterSupplierListModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { Container, Title, Row, RightContainer } from './styles'

const ListSupplierScreen = () => {
  const history = useHistory()
  const { routes } = useRoutes()
  const { createDialog } = useDialog()

  const [filters, setFilters] = useState()
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const [activeFilter, setActiveFilter] = useState(false)

  const tableFilters = useMemo(
    () =>
      debouncedFilterText
        ? { ...filters, q: debouncedFilterText }
        : { ...filters },
    [debouncedFilterText, filters]
  )

  const columns = useMemo(
    () => [
      {
        title: 'CNPJ',
        field: 'companyDocument',
        render: (row) => formatCpfCnpj(row.companyDocument)
      },
      {
        title: 'Razão Social',
        field: 'companyName',
        cellStyle: { whiteSpace: 'nowrap' }
      },
      {
        title: 'Nome Fantasia',
        field: 'tradeName'
      },
      {
        title: 'Status',
        field: 'active',
        render: (row) => (
          <div style={ { display: 'flex' } }>
            <Badge
              backgroundColorBadge={ row.active ? '#289F30' : '#E41D1B' }
              colorBadge={ row.active ? 'rgb(40,159,48)' : 'rgb(228,29,27)' }
            >
              {row.active ? 'Ativo' : 'Inativo'}
            </Badge>
          </div>
        )
      }
    ],
    []
  )

  const handleFilter = useCallback(
    (values) => {
      setFilters({ ...values })
      if(!isEmpty(values)) {
        setActiveFilter(true)
      } else {
        setActiveFilter(false)
      }
    },
    []
  )

  const filterData = useCallback(() => {
    createDialog({
      id: 'filter-supplier',
      Component: FilterSupplierListModal,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const onChangeSearchFilter = useCallback((value) => {
    setDebouncedFilterText(value)
  }, [])

  const goToSupplierRegister = useCallback(
    () => history.push(routes.createSupplier.path),
    [history, routes.createSupplier.path]
  )

  const onEditClick = useCallback(
    (event, data) => {
      history.push(routes.editSupplier.path.replace(':organizationId', data.id))
    },
    [history, routes.editSupplier.path]
  )

  return (
    <Container>
      <Title>
        <I18n>supplier list</I18n>
      </Title>
      <Row>
        <RightContainer>
          <InputSearchDebounced onChange={ onChangeSearchFilter } />
          <Button
            id="filter"
            onClick={ filterData }
            color={ !activeFilter ? 'white' : 'secondary' }
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <>
              <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 } />
              <I18n>filtrate</I18n>
            </>
          </Button>
          <Button
            id="web-create-organization-button"
            onClick={ goToSupplierRegister }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginLeft: 16 } }
          >
            <I18n>register</I18n>
          </Button>
        </RightContainer>
      </Row>
      <DataTable
        onEditClick={ onEditClick }
        data={ getSuppliersService }
        queryParams={ tableFilters }
        urlParams={ {} }
        columns={ columns }
        id="list-supplier-table"
      />
    </Container>
  )
}

export default ListSupplierScreen
