import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment'

import { useDialog } from '@smartcoop/dialog'
import { getAnimalProtocols } from '@smartcoop/services/apis/smartcoopApi/resources/protocols'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { AnimalProtocolsActions } from '@smartcoop/stores/animalProtocol'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { PROTOCOL_TYPES_SLUGS } from '@smartcoop/utils/constants'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import RegisterProtocolModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterProtocolModal'

import { Top } from './styles'

const ListProtocol = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)

  const registerDisabled = useMemo(
    () =>
      ((currentAnimal?.category !== 'vaca' &&
      currentAnimal?.category !== 'novilha') ||
      (currentAnimal?.animalStatus?.name !== 'Aptas' &&
      currentAnimal?.animalStatus?.name !== 'Vazia' &&
      currentAnimal?.animalStatus?.name !== 'PEV' &&
      currentAnimal?.animalStatus?.name !== 'Nenhum')
      || !userWrite
      ),[currentAnimal, userWrite]
  )

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'protocolDate',
        render: (row) => moment(row?.protocolDate, momentBackDateFormat).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: t('type'),
        field: 'type',
        sorting: true,
        render: (row) => PROTOCOL_TYPES_SLUGS[row?.type]
      },
      {
        title: t('Days'),
        field: 'numberOfDays',
        sorting: true
      },
      {
        title: t('predicted date'),
        field: 'inseminationPredictedDate',
        render: (row) => moment(row?.protocolDate, momentBackDateFormat).add(row?.numberOfDays, 'days').format(momentFriendlyDateFormat)
      },
      {
        title: t('action'),
        field: 'action',
        render: (row) => row?.type === PROTOCOL_TYPES_SLUGS.protocolo_de_ia ? t('inseminate') : t('start milking')
      }
    ], [t]
  )

  const urlParams = useMemo(
    () => ({
      animalId: currentAnimal?.id
    }), [currentAnimal.id]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  useEffect(
    () => {
      dispatch(AnimalProtocolsActions.loadAnimalProtocols())
    },[dispatch]
  )

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  const openCreateModal = useCallback(
    (_, protocol = {}) => {
      createDialog({
        id: 'create-protocol',
        Component: RegisterProtocolModal,
        props: {
          onSubmit: reloadDataTable,
          protocol,
          animalId: currentAnimal?.id
        }
      })
    },
    [createDialog, currentAnimal.id, reloadDataTable]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-protocol',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              AnimalProtocolsActions.deleteAnimalProtocols(
                row?.id,
                () => {
                  snackbar.success(
                    t('your {this} was removed', { howmany: 1, gender: 'male', this: t('protocol') })
                  )
                  reloadDataTable()
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('protocol')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  return (
    <>
      <Top>
        <div>
          <Button
            id="register-protocol"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n params={ { this: t('protocol') } }>{'register {this}'}</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        <DataTable
          columns={ columns }
          data={ getAnimalProtocols }
          tableRef={ tableRef }
          urlParams={ urlParams }
          onEditClick={ openCreateModal }
          onDeleteClick={ handleDelete }
          disabled={ !userWrite }
          id="list-protocols-table"
        />
      </div>
    </>
  )
}

export default ListProtocol
