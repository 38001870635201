import React, { forwardRef, useCallback, useMemo, useState } from 'react'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toString from 'lodash/toString'

import rainRecordPropertyFieldsSchema from '@smartcoop/forms/schemas/property/fields/rainRecordPropertyFields.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getFieldsByPropertyId as getFieldsByPropertyIdService } from '@smartcoop/services/apis/smartcoopApi/resources/field'
import Button from '@smartcoop/web-components/Button'
import CheckboxGroup from '@smartcoop/web-components/CheckboxGroup'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'

import { Container } from './styles'

const RainRecordForm = forwardRef((props, formRef) => {
  const { onSubmit, handleClose, propertyId, data: rainRecordData } = props

  const t = useT()

  const [rainMm, setRainMm] = useState(rainRecordData?.rainMm)

  const defaultValueCheckbox = useMemo(
    () => [ rainRecordData?.frost && 'frost', rainRecordData?.hail && 'hail'],
    [rainRecordData]
  )

  const [events, setEvents] = useState(defaultValueCheckbox ?? [])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit])

  const checkboxOptions = useMemo(
    () => [
      { label: 'Geada', value: 'frost' },
      { label: 'Granizo', value: 'hail' }
    ],
    []
  )


  const urlParamsFields = useMemo(
    () => ({ propertyId }),
    [propertyId]
  )

  const loadFieldOptions = useCallback(
    async (...params) => {
      const result = await getFieldsByPropertyIdService(...params)
      return {
        data: {
          data: result
        }
      }
    },
    []
  )

  const defaultValueFields = useMemo(
    () => map(rainRecordData.fields, field => field.id),
    [rainRecordData.fields]
  )

  const disabled = useMemo(
    () => isEmpty(rainMm) && isEmpty(events.filter(item => item !== undefined)),
    [events, rainMm]
  )

  const clearForm = useCallback(() => {
    formRef.current.reset()
    handleClose()
  }, [formRef, handleClose])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ rainRecordPropertyFieldsSchema }
        onSubmit={ handleSubmit }
      >
        <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
          <InputDate
            label={ t('register date') }
            name="occurrenceDate"
            pickerProps={
              {
                maxDate: moment().format()
              }
            }
            defaultValue={ rainRecordData.occurrenceDate }
            disabled={ rainRecordData?.occurrenceDate }
          />

          <InputUnit
            style={ { marginLeft: 10 } }
            name="rainMm"
            label={ t('rain quantity') }
            unit="mm"
            onChange={ (e) => setRainMm(e.target.value) }
            defaultValue={ toString(rainRecordData.rainMm) }
            type="integer"
            required
          />
          <CheckboxGroup
            name="events"
            label=""
            handleChange={ (e) => setEvents(e) }
            options={ checkboxOptions }
            defaultValue={ defaultValueCheckbox }
            variant="row"
            style={ { marginBottom: '0px' } }
          />
        </div>
        <div>
          <I18n style={ { fontSize: 18, fontWeight: 600 } }>apply to fields</I18n>
          <InputSelect
            multiple
            label={ t('search and add') }
            name="fieldsId"
            options={ loadFieldOptions }
            urlParams={ urlParamsFields }
            defaultValue={ defaultValueFields }
            style= { { marginBottom: 20 } }
            asyncOptionLabelField="fieldName"
            checkBoxSelectAll
          />
        </div>
        <ButtonsContainer>
          <Button
            id="clear"
            onClick={ () => clearForm() }
            variant="outlined"
            style={ { marginRight: 7 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="filtrate"
            onClick={ () => formRef.current.submit() }
            color="black"
            style={ { marginLeft: 7 } }
            disabled={ disabled }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

RainRecordForm.propTypes = {
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  propertyId: PropTypes.string,
  data: PropTypes.object
}

RainRecordForm.defaultProps = {
  onSubmit: () => {},
  handleClose: () => {},
  propertyId: '',
  data: {}
}

export default RainRecordForm
