import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment'

import { capitalize, isEmpty, values } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import { getAnimalBirthsById as getAnimalBirthsByIdService } from '@smartcoop/services/apis/smartcoopApi/resources/animalBirth'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import {
  momentBackDateFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import FilterAnimalBirthModal from '@smartcoop/web-containers/modals/dairyFarm/FilterAnimalBirthModal'
import RegisterAnimalBirthModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterAnimalBirthModal'

import { Top } from './styles'

const ListAnimalBirth = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const lots = useSelector(selectLots)

  const currentAnimal = useSelector(selectCurrentAnimal)

  const [filters, setFilters] = useState({})
  const { id: propertyId } = useSelector(selectCurrentProperty)
  const userWrite = useSelector(selectUserCanWrite)

  const registerDisabled = useMemo(
    () =>
      (currentAnimal?.animalStatus?.name !== 'PEV' &&
      currentAnimal?.animalStatus?.name !== 'Prenha')
      || !userWrite,
    [currentAnimal, userWrite]
  )

  const columns = useMemo(
    () => [
      {
        title: t('insemination date'),
        field: 'inseminationDate',
        render: (row) =>
          moment(
            row?.insemination?.inseminationDate,
            momentBackDateFormat
          ).format(momentFriendlyDateFormat),
        sorting: true
      },
      {
        title: t('earring'),
        field: 'earringCode',
        render: (row) => row?.mother?.earring?.earringCode,
        sorting: true
      },
      {
        title: t('expected date'),
        field: 'expectedDate',
        render: (row) =>
          moment(row.expectedDate, momentBackDateFormat).format(
            momentFriendlyDateFormat
          ),
        sorting: true
      },
      {
        title: t('occurrence date'),
        field: 'occurrenceDate',
        render: (row) =>
          moment(row.occurrenceDate, momentBackDateFormat).format(
            momentFriendlyDateFormat
          ),
        defaultSort: 'desc',
        sorting: true
      },
      {
        title: t('gender'),
        field: 'gender',
        render: (row) => capitalize(row.gender),
        sorting: true
      },
      {
        title: t('situation'),
        field: 'isAlive',
        render: (row) => (row.isAlive ? 'Vivo' : 'Morto'),
        sorting: true
      },
      {
        title: t('weight'),
        field: 'weight',
        render: (row) => `${ row.weight } Kg`,
        sorting: true
      },
      {
        title: t('type'),
        field: 'birthType',
        render: (row) => capitalize(row.birthType),
        sorting: true
      }
    ],
    [t]
  )

  const urlParams = useMemo(
    () => ({
      propertyId,
      animalId: currentAnimal.id
    }),
    [currentAnimal.id, propertyId]
  )

  const reloadDataTable = useCallback(() => {
    tableRef.current.onQueryChange()
  }, [])

  const handleSetFilters = useCallback((filterValues) => {
    setFilters({
      ...filterValues,
      q: filterValues?.name ?? null
    })
  }, [])

  const openFilterModal = useCallback(() => {
    createDialog({
      id: 'filter-fields',
      Component: FilterAnimalBirthModal,
      props: {
        onSubmit: handleSetFilters,
        filters
      }
    })
  }, [createDialog, filters, handleSetFilters])

  const openCreateModal = useCallback(
    (_, animalBirth = {}) => {
      createDialog({
        id: 'create-fields',
        Component: RegisterAnimalBirthModal,
        props: {
          onSubmit: reloadDataTable,
          animalBirth: {
            ...animalBirth
          }
        }
      })
    },
    [createDialog, reloadDataTable]
  )


  const openEditModal = useCallback(
    (_, animalBirth = {}) => {
      createDialog({
        id: 'create-fields',
        Component: RegisterAnimalBirthModal,
        props: {
          onSubmit: reloadDataTable,
          animalBirth: {
            ...animalBirth,
            occurrenceDate: moment(
              animalBirth.occurrenceDate,
              momentBackDateFormat
            ),
            expectedDate: moment(
              animalBirth.expectedDate,
              momentBackDateFormat
            ).format(momentFriendlyDateFormat)
          }
        }
      })
    },
    [createDialog, reloadDataTable]
  )

  useEffect(() => {
    if (isEmpty(lots)) {
      dispatch(LotActions.loadLots({ propertyId }))
    }
  }, [dispatch, lots, propertyId])


  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  return (
    <>
      <Top>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div>
        <div>
          <Button
            id="register-animalBirth"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n params={ { this: t('calving') } }>{'register {this}'}</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        <DataTable
          columns={ columns }
          data={ getAnimalBirthsByIdService }
          queryParams={ filters }
          tableRef={ tableRef }
          urlParams={ urlParams }
          onEditClick={ openEditModal }
          disabled={ !userWrite }
          id="list-animal-birth-table"
        />
      </div>
    </>
  )
}

export default ListAnimalBirth
