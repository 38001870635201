import React from 'react'
import Lottie from 'react-lottie'

import PropTypes from 'prop-types'

import Spinner from '@smartcoop/styles/assets/animations/spinner.json'

import { Container, Message } from './styles'

const lottieStyle = {
  height: '100%',
  alignSelf: 'center'
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Spinner
}

function AnimatedLoader({ message, width, style }) {
  return (
    <Container style={ style }>
      <Lottie options={ defaultOptions } isClickToPauseDisabled style={ lottieStyle } width={ width }/>
      {message && <Message>{message}</Message>}
    </Container>
  )
}

AnimatedLoader.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.number
}

AnimatedLoader.defaultProps = {
  message: null,
  style: {},
  width: 200
}


export default AnimatedLoader
