import { REACT_APP_FAKE_PAGINATION_SIZE } from 'react-native-dotenv'

import { select, call, put, takeLatest } from 'redux-saga/effects'

import { isEmpty } from 'lodash'

import {
  getLots as getLotsService,
  createLot as createLotService,
  updateLot as updateLotService,
  deleteLot as deleteLotService
} from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'

import { LotActions, LotTypes } from './duckLot'

function* loadLots({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id || params?.propertyId
    const {
      data: { data, ...pagination }
    } = yield call(
      getLotsService,
      {
        limit:
          process.env.REACT_APP_FAKE_PAGINATION_SIZE ||
          REACT_APP_FAKE_PAGINATION_SIZE,
        ...params
      },
      { propertyId }
    )

    const lotsOptions = data.map((item) => ({
      label: item.name,
      value: item.id
    }))

    yield put(
      LotActions.loadLotsSuccess(
        data,
        pagination.page,
        lotsOptions,
        onSuccess(data)
      )
    )
  } catch (err) {
    const error = err.message
    yield put(LotActions.lotError(error))
    yield call(onError, error)
  }
}

function* saveLot({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id
    const service = isEmpty(params.id) ? createLotService : updateLotService
    const { data } = yield call(service, params, {
      propertyId,
      lotId: params.id ?? null
    })
    yield call(onSuccess, data)
  } catch (error) {
    yield put(LotActions.lotError(error))
    yield call(onError, error)
  }
}

function* deleteLot({ lotId, onSuccess = () => {}, onError = () => {} }) {
  try {
    const currentProperty = yield select(selectCurrentProperty)
    const propertyId = currentProperty?.id

    yield call(deleteLotService, { propertyId, lotId })
    yield call(onSuccess)
  } catch (err) {
    const error = err.message
    yield put(LotActions.lotError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(LotTypes.LOAD_LOTS, loadLots),

  takeLatest(LotTypes.SAVE_LOT, saveLot),
  takeLatest(LotTypes.DELETE_LOT, deleteLot)
]
