import React, { useState, useCallback, forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import pestReportSchema from '@smartcoop/forms/schemas/pestReport/pestReport.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getPestReportTypes, getDiseases, getWeeds, getPests } from '@smartcoop/services/apis/smartcoopApi/resources/pestReport'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'
import Slider from '@smartcoop/web-components/Slider'

import { Container, ButtonContainer } from './styles'

const PestReportForm = forwardRef((props, formRef) => {
  const {
    withoutSubmitButton,
    isEditing,
    loading,
    onSubmit,
    defaultValues,
    markerCoordinate
  } = props

  const t = useT()
  const [sliderValue, setSliderValue] = useState(defaultValues.damage || 50)
  const [ocurrenceType, setOcurrenceType] = useState(defaultValues.reportTypes.slug || '')
  const [erosionType, setErosionType] = useState(defaultValues?.details?.type || '')

  const handleSubmit = useCallback(
    (data) => {
      let dataToSubmit = {
        ...data,
        pinLocation: markerCoordinate,
        damage: sliderValue
      }
      if(!isEmpty(erosionType)) {
        dataToSubmit = {
          ...dataToSubmit,
          details: {
            type: erosionType
          }
        }
      }
      onSubmit(dataToSubmit)
    },
    [erosionType, markerCoordinate, onSubmit, sliderValue]
  )

  const handleOcorrenceTypeChange = useCallback(
    (selectedItem) => {
      setOcurrenceType(selectedItem.target.value)
    },
    []
  )

  const specificationService = useMemo(
    () => ({
      'pest': getPests,
      'disease': getDiseases,
      'weed': getWeeds
    }[ocurrenceType]),
    [ocurrenceType]
  )

  const erosionOptions = useMemo(
    () => [
      {
        label: 'Laminar',
        value: 'laminar'
      },
      {
        label: 'Sulco',
        value: 'sulco'
      },
      {
        label: 'Vossoroca',
        value: 'vossoroca'
      }
    ],
    []
  )

  const handleOcorrenceType = useMemo(
    () => {
      if(ocurrenceType === 'other' || ocurrenceType === 'emergency') {
        return (
          <InputText
            placeholder={ t('description') }
            name="description"
            fullWidth
            multiline
            rows={ 4 }
            defaultValue={ defaultValues.description }
          />)
      }

      if(ocurrenceType !== 'emergency' && ocurrenceType !== 'erosion') {
        return (
          <InputSelect
            key={ ocurrenceType }
            label={ t('specification') }
            name='plagueId'
            options={ specificationService }
            asyncOptionLabelField="name"
            defaultValue={ defaultValues.specification }
          />
        )
      }

      if(ocurrenceType === 'erosion') {
        return (
          <RadioGroup
            detached
            label={ t('erosion type') }
            placeholder={ t('erosion type') }
            variant="row"
            options={ erosionOptions }
            value={ erosionType }
            onChange={ ({ value }) => {
              setErosionType(value)
            } }
            fullWidth
            style={ { marginBottom: '20px' } }
          />
        )
      }
      return <></>
    },
    [defaultValues.description, defaultValues.specification, erosionOptions, erosionType, ocurrenceType, specificationService, t]
  )

  const matches = useMediaQuery('(max-height:900px)')

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ pestReportSchema }
        onSubmit={ handleSubmit }
      >
        <InputDate
          label={ t('select one date') }
          name="occurenceDate"
          placeholder={ t('date', { howMany: 1 }) }
          fullWidth
          disabled={ loading || isEditing }
          defaultValue={ defaultValues.occurenceDate }
        />
        <InputSelect
          label={ t('occurrence type') }
          name="typeSlug"
          options={ getPestReportTypes }
          onChange={ handleOcorrenceTypeChange }
          asyncOptionLabelField="name"
          asyncOptionValueField="slug"
          defaultValue={ defaultValues.reportTypes.slug }
        />

        {handleOcorrenceType}

        <InputText
          placeholder={ t('observations') }
          name="observations"
          fullWidth
          multiline
          rows={ matches ? 2 : 4 }
          defaultValue={ defaultValues.observations }
        />

        <Slider
          onChange={ (x, y) => setSliderValue(y) }
          label={ t('damage done') }
          value={ sliderValue }
          sliderProps={ {
            step: 1,
            valueLabelDisplay: 'on',
            thumb: { color: 'red' },
            marks: [
              {
                value: 0,
                label: '0%'
              },
              {
                value: 100,
                label: '100%'
              }
            ]
          } }
        />

        {!withoutSubmitButton && (
          <ButtonContainer>
            <Button
              id="web-field-form-button"
              style={ { width: '48%' } }
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
            >
              <I18n>next</I18n>
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Container>
  )
})

PestReportForm.propTypes = {
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  markerCoordinate: PropTypes.object,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object
}

PestReportForm.defaultProps = {
  markerCoordinate: {},
  isEditing: false,
  loading: false,
  onSubmit: () => {},
  withoutSubmitButton: false,
  defaultValues: {}
}

export default PestReportForm
