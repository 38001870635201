import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'

import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import sizeLodash from 'lodash/size'
import toLower from 'lodash/toLower'
import uniqBy from 'lodash/uniqBy'

import { ThemeProvider } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Loader from '@smartcoop/web-components/Loader'
import TextField from '@smartcoop/web-components/TextField'

import useStyles from './styles'

const InputSelectStyled = (props) => {
  const {
    options: externalOptions,
    multiple,
    creatable,
    size,
    defaultValue: externalDefaultValue,
    // eslint-disable-next-line react/prop-types
    zIndex,
    asyncOptionLabelField,
    asyncOptionValueField,
    loaderEnable,
    urlParams,
    queryParams,
    disableClearable,
    disabledWhenLoading,
    disabled,
    deps,
    value,
    onChange,
    onBlur,
    error,
    checkBoxSelectAll,
    transformAsyncResult,
    formatOrderByLabel,
    selectAllOptionsByDefault,
    orderDirection,
    style,
    order,
    selectIfUniq,
    groupBy,
    getOptionDisabled,
    createdSameLabel,
    ...otherProps
  } = props

  const classes = useStyles()
  const t = useT()

  const [valueInput, setValueInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [asyncData, setAsyncData] = useState([])
  const [createdOptions, setCreatedOptions] = useState([])
  const [selectedValues, setSelectedValues] = useState()
  const mounted = useRef(false)
  const mountedSelectAllOptionsByDefault = useRef(false)

  useEffect(() => {
    setSelectedValues(value)
  },[value])

  const asyncOptions = useMemo(
    () => flow(
      mapFP(transformAsyncResult),
      mapFP((option) => ({
        value: get(option, asyncOptionValueField),
        label: get(option, asyncOptionLabelField)
      }))
    )(asyncData),
    [asyncData, asyncOptionLabelField, asyncOptionValueField, transformAsyncResult]
  )

  const asyncMode = useMemo(
    () => isFunction(externalOptions),
    [externalOptions]
  )

  const options = useMemo(
    () => {
      let newOptions = [
        // eslint-disable-next-line no-nested-ternary
        ...(asyncMode ? (
          orderBy(asyncOptions, [option => formatOrderByLabel(option.label.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ''))], [orderDirection])
        ) : order ? (
          orderBy(externalOptions, [option => formatOrderByLabel(option.label.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ''))], [orderDirection])
        ) : externalOptions),
        ...createdOptions
      ]
      if (creatable && !find(newOptions, { selectedValues }) && !isEmpty(selectedValues)) {
        newOptions = [
          ...newOptions
        ]
      }
      const response = uniqBy(newOptions, 'value')
      if(selectIfUniq && (sizeLodash(response) === 1)) {
        onChange(multiple ? [response[0]?.value] : response[0]?.value)
      }
      return response
    },
    [asyncMode, asyncOptions, creatable, createdOptions, externalOptions, formatOrderByLabel, multiple, onChange, order, orderDirection, selectIfUniq, selectedValues]
  )

  // const {
  //   fieldName,
  //   fieldRef,
  //   formRef,
  //   handleChangeNative,
  //   handleBlurNative,
  //   setTouched,
  //   resetField,
  //   externalOnChange,
  //   validateField,
  //   error
  // } = useField()

  const optionSelected = useMemo(
    () => {
      const defaultReturn = multiple ? [] : null
      if (multiple) {
        return map(
          selectedValues,
          defVal => find(options, option => option.value === defVal)
        )
      }
      if (selectedValues) {
        return find(options, option => option.value === selectedValues) || defaultReturn
      }
      return defaultReturn
    },
    [multiple, selectedValues, options]
  )

  const allSelected = useMemo(
    () => multiple && !loading && sizeLodash(options) === sizeLodash(value),
    [loading, multiple, options, value]
  )

  const handleChange = useCallback(
    (_, newValue) => {
      let val
      let activeAsyncItem
      let activeitem

      if (multiple) {
        val = map(newValue, item => item.value)
        activeAsyncItem = filter(asyncData, (item) => indexOf(val, get(item, asyncOptionValueField)) > -1)
        activeitem = filter(options, (item) => indexOf(val, item.value) > -1)
      } else {
        val = get(newValue, 'value', '')
        activeAsyncItem = find(asyncData, (item) => get(item, asyncOptionValueField) === val)
        activeitem = find(options, (item) => item.value === val)
      }

      onChange(val, isEmpty(activeAsyncItem) ? activeitem : activeAsyncItem)
    },
    [asyncData, asyncOptionValueField, multiple, onChange, options]
  )

  const onSelectAllChange = useCallback(
    () => {
      if(allSelected){
        handleChange(null, externalDefaultValue || [])
      } else {
        handleChange(null, options)
      }
    },
    [allSelected, externalDefaultValue, handleChange, options])

  const handleCreatedOption = useCallback(
    (inputValue) => {
      const newOption = { value: createdSameLabel ? inputValue : kebabCase(inputValue), label: inputValue, created: true }
      const hasItem = find(createdOptions, option => option.label === newOption.label)

      if (!hasItem) {
        setCreatedOptions([...createdOptions, newOption])
      }

      if(multiple){
        const selected =  map(
          selectedValues,
          defVal => find(options, option => option.value === defVal)
        )
        handleChange(null, [...selected, { value: kebabCase(inputValue), label: inputValue, created: true }])
      }else{
        handleChange(null, inputValue)
      }
    },
    [createdOptions, createdSameLabel, handleChange, multiple, options, selectedValues]
  )

  const handleKeyDown = useCallback(
    ({ keyCode, target: { value: inputValue } }) => {
      const ENTER_KEY = 13
      if (creatable && keyCode === ENTER_KEY && inputValue !== '') {
        handleCreatedOption(inputValue)
      }
    },
    [handleCreatedOption, creatable]
  )
  const noOptionsText = useMemo(() => creatable ? `${ t('press enter to include')  } "${ valueInput }"` : t('no options'), [creatable, t, valueInput])

  const loadAsyncOptions = useCallback(
    async () => {
      try {
        setLoading(true)
        const { data: { data } } = await externalOptions({
          page: 1,
          limit: 99999,
          ...queryParams
        }, urlParams)

        if(mounted.current) {
          setAsyncData(data)
        }

      } finally {
        if(mounted.current) {
          setLoading(false)
        }
      }
    },
    [externalOptions, queryParams, urlParams]
  )

  useEffect(() => {
    if (asyncMode && loaderEnable) {
      loadAsyncOptions()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncMode, queryParams, urlParams, deps])

  useEffect(() => {
    if (
      selectAllOptionsByDefault
      && !mountedSelectAllOptionsByDefault.current
      && !isEmpty(options)
    ) {
      onSelectAllChange()
      mountedSelectAllOptionsByDefault.current = true
    }
  }, [onSelectAllChange, options, selectAllOptionsByDefault])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return (
    <>
      { !disabled && checkBoxSelectAll && multiple && (
        <CheckboxButton
          value={ allSelected }
          checked={ allSelected }
          onChange={ onSelectAllChange }
          label={ t('select all') }
          style={ { marginBottom: 5, marginTop: 5 } }
        />
      )}
      <ThemeProvider
        theme={
          (theme) => ({
            ...theme,
            overrides: {
              ...theme.overrides,
              MuiAutocomplete:{
                hasClearIcon:{
                  '& *': {
                    paddingRight: !multiple ? '0 !important': null
                  }
                }
              }
            }
          })
        }
      >
        <Autocomplete
          groupBy={ groupBy }
          getOptionDisabled={ getOptionDisabled }
          filterOptions={ (opts, { inputValue }) => filter(opts, item => includes(toLower(item?.label), toLower(inputValue))) }
          style={ style }
          disabled={ disabled || (disabledWhenLoading && loading) }
          options={ options }
          classes={ {
            option: classes.option
          } }
          loading={ loading }
          value={ optionSelected }
          onChange={ handleChange }
          noOptionsText={ noOptionsText }
          multiple={ multiple }
          fullWidth
          autoHighlight
          disableClearable={ disableClearable }
          getOptionLabel={ (option) => option?.label }
          renderOption={ (option) => <span style={ { color: option?.notFound ? colors.secondary : colors.text } }>{option?.label}</span> }
          renderInput={ (params) =>
            (disabledWhenLoading && loading)
          || (isEmpty(options) && disabled && loading)
              ?
              (
                <Loader width={ 20 }/>
              )
              : (
                <TextField
                  { ...otherProps }
                  { ...params }
                  detached
                  error={ error }
                  onBlur={ onBlur }
                  onKeyDown={ handleKeyDown }
                  onChange={ (e) => setValueInput(e.target.value) }
                  size={ size }
                  fullWidth
                  inputProps={ {
                    ...(otherProps.inputProps || {}),
                    ...params.inputProps,
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  } }
                />
              ) }
        />
      </ThemeProvider>
    </>
  )
}

InputSelectStyled.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })),
    PropTypes.func
  ]),
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  deps: PropTypes.array,
  loaderEnable: PropTypes.bool,
  multiple: PropTypes.bool,
  checkBoxSelectAll: PropTypes.bool,
  selectIfUniq: PropTypes.bool,
  size: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  asyncOptionLabelField: PropTypes.string,
  asyncOptionValueField: PropTypes.string,
  transformAsyncResult: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  urlParams: PropTypes.object,
  queryParams: PropTypes.object,
  style: PropTypes.object,
  disableClearable: PropTypes.bool,
  disabledWhenLoading: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  groupBy: PropTypes.func,
  error: PropTypes.string,
  formatOrderByLabel: PropTypes.func,
  selectAllOptionsByDefault: PropTypes.bool,
  createdSameLabel: PropTypes.bool,
  order: PropTypes.bool,
  orderDirection: PropTypes.string
}

InputSelectStyled.defaultProps = {
  options: [],
  creatable: false,
  disabled: false,
  deps: [],
  multiple: false,
  checkBoxSelectAll: false,
  loaderEnable: true,
  order: true,
  defaultValue: undefined,
  asyncOptionLabelField: 'name',
  asyncOptionValueField: 'id',
  createdSameLabel: false,
  size: 'small',
  urlParams: {},
  style: {},
  queryParams: {},
  disableClearable: false,
  disabledWhenLoading: false,
  selectIfUniq: false,
  onChange: () => {},
  onBlur: () => {},
  groupBy: () => {},
  getOptionDisabled: () => {},
  error: null,
  transformAsyncResult: item => item,
  formatOrderByLabel: item => item,
  selectAllOptionsByDefault: false,
  orderDirection: 'asc'
}

export default InputSelectStyled
