import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { InseminationActions } from '@smartcoop/stores/insemination'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterInseminationForm from '@smartcoop/web-containers/forms/digitalProperty/dairyFarm/RegisterInseminationForm'

import useStyles from './styles'

const RegisterInseminationModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    animalId,
    insemination
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const currentAnimal = useSelector(selectCurrentAnimal)

  const [isLoading, setIsLoading] = useState(false)

  const isEditing = useMemo(
    () => (!isEmpty(insemination?.id)),[insemination.id]
  )
  const defaultValues = useMemo(
    () => (
      {
        code: '',
        name: '',
        property: {
          id: currentProperty?.id
        },
        animal: {
          id: animalId || currentAnimal?.id
        },
        ...insemination
      }
    ), [animalId, currentAnimal, currentProperty, insemination]
  )

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
      snackbar.success(
        t(`your {this} was ${  isEditing ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('insemination')
        })
      )
      onSubmit()
      closeModal()

    }, [closeModal, dispatch, isEditing, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(InseminationActions.saveOfflineInsemination(
        {
          ...insemination,
          ...data
        },
        onSuccess,
        () => setIsLoading(false),
        true
      ))
    },
    [dispatch, insemination, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('insemination') } }>{'register {this}'}</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterInseminationForm
              ref={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              loading={ isLoading }
            />
        }
      </>
    </Modal>
  )}

RegisterInseminationModal.propTypes = {
  id: PropTypes.string.isRequired,
  animalId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  insemination: PropTypes.object
}

RegisterInseminationModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  animalId: null,
  insemination: {}
}

export default RegisterInseminationModal
