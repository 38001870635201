import React, {
  useCallback,
  forwardRef,
  useState,
  useEffect
} from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import Grid from '@material-ui/core/Grid'

import registerObservationsSchema from '@smartcoop/forms/schemas/dairyFarm/registerObservations.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimals as getAnimalsService } from '@smartcoop/services/apis/smartcoopApi/resources/animal'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'

import {
  Container,
  ButtonsContainer,
  FormContainer,
  Item
} from './styles'

const RegisterObservationsForm = forwardRef((props, formRef) => {
  const { defaultValues, onSubmit, onCancel, loading } = props

  const currentProperty = useSelector(selectCurrentProperty)

  const [animals, setAnimals] = useState([])

  const t = useT()

  useEffect(() => {
    const loadAnimals = async() => {
      const { data: { data: response } } = await getAnimalsService({ limit: 99999 }, { propertyId: currentProperty?.id })

      setAnimals(map(response, item => ({ label: item?.earring?.earringCode, value: item.id })))
    }

    loadAnimals()
  },[currentProperty])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...defaultValues,
        ...data
      })
    },
    [defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerObservationsSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                label={ t('earrings') }
                name="animalsIds"
                options={ animals }
                multiple
                defaultValue={ [defaultValues?.animal?.id] }
                disabled={ !!defaultValues?.id }
              />
            </Item>
            <Item>
              <InputText
                label={ t('observation', { howMany: 1 }) }
                name="observation"
                defaultValue={ defaultValues.observation }
                fullWidth
                multiline
                rows={ 6 }
              />
            </Item>
          </Grid>
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            style={ { marginRight: 10 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            style={ { marginLeft: 10 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
})

RegisterObservationsForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired
}

RegisterObservationsForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {}
}

export default RegisterObservationsForm
